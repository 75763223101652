import { Col, Row, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ReceiveIcon } from "../../assets/receive-icon.svg";
import { ReactComponent as TransferIcon } from "../../assets/transfer-icon.svg";
import ErrorBanner from "../../components/ErrorBanner";
import SearchBar from "../../components/SearchBar";
import { InventoryContext } from "../../contexts/InventoryContext";
import { ScreenContext } from "../../contexts/ScreenContext";
import ConfigService from "../../services/ConfigService";
import RoleCheckService from "../../services/RoleCheckService";
import Stock from "../Stock/Stock";
import InventoryIconMenu from "./InventoryIconMenu";
import styles from "./InventoryItems.module.css";
import InventoryList from "./InventoryList/InventoryList";
import { getNewDeviceName } from "../../functions/utilities";

const InventoryItems = ({ user, onLogOut }) => {
  const { recordedStockItemId } = useContext(InventoryContext);

  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("items");
  const [filterText, setFilterText] = useState("");
  const [sites, setSites] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [menuChoice, setMenuChoice] = useState("Home");
  const [siteList, setSiteList] = useState([]);
  const [error, setError] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [devices, setDevices] = useState([]);
  const [automationFilter, setAutomationFilter] = useState(null);

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  // If mobile view, show mobile header (navbar hamburger, alarm bell, user profile photo)
  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
  }, [smScreen]);

  // GET THE PHARMACY SITES THIS USER IS ALLOWED TO ACCESS
  useEffect(() => {
    RoleCheckService.getAccessibleSites(user)
      .then((sites) => {
        for (let i = 0; i < sites.length; i++) {
          sites[i].description = sites[i].name; // for dropdown conformance
        }
        setSiteList(
          sites.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (b.name.toUpperCase() < a.name.toUpperCase()) {
              return 1;
            }
            return 0;
          })
        );
      })
      .catch((error) => {
        setError(error);
      });
  }, [user]);

  useEffect(() => {
    ConfigService.stockLocations()
      .then((stockLocations) => {
        setDeviceList(
          stockLocations
            .filter((sl) => {
              return sl.canDispense;
            })
            .map((sl) => ({
              ...sl,
              displayName: t(sl.description, {ns: "stockLocationTypes"})
            }))
        );
      })
      .catch((error) => {
        setError(error);
      });
  }, [user]);

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  useEffect(() => {
    if (location.pathname === "/inventory/receive") {
      setMenuChoice("Receive");
      setShowModal(true);
    } else if (location.pathname === "/inventory/transfer") {
      setMenuChoice("Transfer");
      setShowModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/inventory/items") {
      setFilterText("");
      setSites([]);
      setShowModal(false);
      setMenuChoice("Home");
      setDevices([]);
      setAutomationFilter(null);
    }
  }, [location.pathname]);

  const receiveOnClickHandler = () => {
    navigate("/inventory/receive");
  };

  const transferOnClickHandler = () => {
    navigate("/inventory/transfer");
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const menuChoiceHandler = (choice) => {
    setMenuChoice(choice);
  };

  const automationList = [
    { type: "No Automation", description: t("noAutomationLabel") },
    { type: "All Automation", description: t("allAutomationLabel") },
    { type: "Clear", description: t("clearFilterLabel") },
  ];

  const Dropdowns = (
    <Row gutter={5}>
      <Col span={smScreen ? 24 : 8}>
        <Select
          style={{ width: "100%" }}
          mode="multiple"
          placeholder={t("selectPharmaciesLabel")}
          onSelect={(value) => {
            const item = siteList.find((s) => s._id === value);
            setSites((prev) => {
              return [...prev, item];
            });
          }}
          onDeselect={(value) => {
            const temp = [...sites];
            const index = temp.findIndex((s) => s._id === value);
            temp.splice(index, 1);
            setSites(temp);
          }}
          options={siteList.map((s) => ({
            label: s.name.toUpperCase(),
            value: s._id,
          }))}
        />
      </Col>
      <Col span={smScreen ? 24 : 8}>
        <Select
          style={{ width: "100%" }}
          mode="multiple"
          placeholder={t("deviceFilterLabel")}
          onSelect={(value) => {
            const item = deviceList.find((d) => d.type === value);
            setDevices((prev) => {
              return [...prev, item];
            });
          }}
          onDeselect={(value) => {
            const temp = [...devices];
            const index = temp.findIndex((d) => d.type === value);
            temp.splice(index, 1);
            setDevices(temp);
          }}
          selectedItems={devices}
          listElements={deviceList}
          validationText={""}
          display={"displayName"}
          options={deviceList.map((s) => ({
            label: getNewDeviceName(s.description),
            value: s.type,
          }))}
        />
      </Col>
      <Col span={smScreen ? 24 : 8}>
        <Select
          style={{ width: "100%" }}
          placeholder={t("otherFiltersLabel")}
          value={automationFilter?.description}
          allowClear={true}
          onChange={(value) => {
            if (value === "Clear") {
              setAutomationFilter(null);
            } else {
              const item = automationList.find((a) => a.type === value);
              setAutomationFilter(item);
            }
          }}
          options={automationList.map((a) => ({
            value: a.type,
            label: a.description,
          }))}
        />
      </Col>
    </Row>
  );

  const InventoryListGroup = (
    <div className={styles.inventory__body}>
      <InventoryList
        user={user}
        selectedSites={sites}
        selectedDevices={devices}
        searchText={filterText}
        automationFilter={automationFilter}
        onError={() => setError(error)}
      />
    </div>
  );

  const hasSite = user.sites.length > 0 || user.isEnterprise === true;

  if (recordedStockItemId === -1) {
    return (
      <>
        {smScreen && (
          <>
            {!showModal && (
              <>
                {!hasSite && (
                  <>
                    <h1 class={styles.inventory__pageTitleForNoSites}>
                      {t("title")}
                    </h1>
                    <div className={styles.inventory__noSitesMessage}>
                      <p>{t("noSiteAssigned")}</p>
                    </div>
                  </>
                )}

                {hasSite && (
                  <>
                    <Row gutter={5}>
                      <Col span={24}>
                        <SearchBar
                          placeholder={
                            smScreen
                              ? t("searchPlaceholderMobile")
                              : t("searchPlaceholder")
                          }
                          onSearch={handleSearch}
                          focus={true}
                        />
                      </Col>
                      <Col span={24}>
                        {error && (
                          <>
                            <ErrorBanner
                              message={error}
                              onClose={() => setError("")}
                            />
                            <br />
                          </>
                        )}
                      </Col>
                      <Col span={24}>{Dropdowns}</Col>
                      <Col span={24}>{InventoryListGroup}</Col>
                    </Row>
                    <InventoryIconMenu
                      onReceiveOnClickHandler={receiveOnClickHandler}
                      onTransferOnClickHandler={transferOnClickHandler}
                    />
                  </>
                )}
              </>
            )}

            {showModal && (
              <Stock
                user={user}
                onHideModal={hideModalHandler}
                menuChoice={menuChoice}
                onMenuChoiceHandler={menuChoiceHandler}
              />
            )}
          </>
        )}

        {!smScreen && (
          <>
            {!showModal && (
              <main className={styles.inventory}>
                <section className={styles.inventory__headerRow}>
                  <h1 className={styles.inventory__title}>{t("title")}</h1>
                  {/*<AlarmDateTime />*/}
                </section>
                <br />

                {!hasSite && (
                  <div className={styles.inventory__noSitesMessage}>
                    <p>{t("noSiteAssigned")}</p>
                  </div>
                )}

                {hasSite && (
                  <Row gutter={[0, 5]} align="middle">
                    <Col span={12}>
                      <SearchBar
                        placeholder={
                          smScreen
                            ? t("searchPlaceholderMobile")
                            : t("searchPlaceholder")
                        }
                        onSearch={handleSearch}
                        focus={true}
                      />
                    </Col>
                    <Col span={12}>
                      <Row justify="end">
                        <Col>
                          <button
                            className={styles.inventory__iconBox}
                            onClick={receiveOnClickHandler}
                          >
                            <ReceiveIcon className={styles.inventory__icon} />

                            <p
                              className={[
                                styles["inventory__iconText"],
                                styles["inventory__iconText--extraMargin"],
                              ].join(" ")}
                            >
                              {t("receiveButtonLabel")}
                            </p>
                          </button>
                          <button
                            className={styles.inventory__iconBox}
                            onClick={transferOnClickHandler}
                          >
                            <TransferIcon className={styles.inventory__icon} />
                            <p className={styles.inventory__iconText}>
                              {t("transferButtonLabel")}
                            </p>
                          </button>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>{Dropdowns}</Col>
                    <Col span={24} style={{ height: "calc(100vh - 300px)" }}>
                      {InventoryListGroup}
                    </Col>
                  </Row>
                )}
              </main>
            )}

            {showModal && (
              <div className={styles.inventory__overlay}>
                <div className={styles.inventory__view}>
                  <Stock
                    user={user}
                    onHideModal={hideModalHandler}
                    menuChoice={menuChoice}
                    onMenuChoiceHandler={menuChoiceHandler}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
};

export default InventoryItems;

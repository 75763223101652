export const getCanisterDataMapping = () => {
  return [
    {
      mappingDisplayName: 'Default Value',
      mappingKey: '',
    },
    {
      mappingDisplayName: 'Control Level',
      mappingKey: 'CONTROL_LEVEL',
    },
    {
      mappingDisplayName: 'Description',
      mappingKey: 'DESCRIPTION',
    },
    {
      mappingDisplayName: 'Dosage Form',
      mappingKey: 'DOSAGE_FORM',
    },
    {
      mappingDisplayName: 'Legend',
      mappingKey: 'LEGEND',
    },
    {
      mappingDisplayName: 'Manufacturer Abbreviation',
      mappingKey: 'MANUFACTURER_ABBR',
    },
    {
      mappingDisplayName: 'Manufacturer Name',
      mappingKey: 'MANUFACTURER_NAME',
    },
    {
      mappingDisplayName: 'Motor Base',
      mappingKey: 'MOTOR_BASE',
    },
    {
      mappingDisplayName: 'Name',
      mappingKey: 'NAME',
    },
    {
      mappingDisplayName: 'Package Id',
      mappingKey: 'PACKAGE_ID',
    },
    {
      mappingDisplayName: 'Primary Name',
      mappingKey: 'PRIMARY_NAME',
    },
    {
      mappingDisplayName: 'Product Id',
      mappingKey: 'PRODUCT_ID',
    },
    {
      mappingDisplayName: 'Secondary Name',
      mappingKey: 'SECONDARY_NAME',
    },
    {
      mappingDisplayName: 'Strength',
      mappingKey: 'STRENGTH',
    },
    {
      mappingDisplayName: 'TallMan Name',
      mappingKey: 'TALLMAN_NAME',
    },
    {
      mappingDisplayName: 'Tertiary Name',
      mappingKey: 'TERTIARY_NAME',
    }
  ];
}
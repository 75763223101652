import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useMemo
} from "react";
import { useTranslation } from "react-i18next";
import PrintLabels from "./PrintLabels";
import TextInput from "../../components/TextInput";
import AntSelect from "../../components/Ant/AntSelect/AntSelect";
import CreateButton from "../../components/CreateButton";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import SearchBar from "../../components/SearchBar";
import LabelController from "./LabelController";
import LabelTile from "../../components/LabelTile";
import ModalBackdrop from "../../components/Modal";
import styles from "./CreateLabel.module.css";
import { Rings } from "react-loader-spinner";
import { LabelEditorComponent } from "@holycitysoftware/jfcrx-label-editor";
import { getDataMapping } from "./Properties/getDataMapping";
import FacilityService from "../../services/FacilityService";
import i18next from "i18next";

const CreateLabel = ({ user, onSelectedTab, onShowTabBar }) => {
  const [createLabel, setCreateLabel] = useState(false);
  const [showLabelCanvas, setShowLabelCanvas] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [machineType, setMachineType] = useState("TruCard");
  const [labelType, setLabelType] = useState("Canister");
  const [pouchType, setPouchType] = useState("batchHeader");
  const [pouchFacility, setPouchFacility] = useState("");
  const [dropdownListPouchFacilities, setDropdownListPouchFacilities] =
    useState(null);
  const [customizedLabel, setCustomizedLabel] = useState("");
  const [labels, setLabels] = useState(null);
  const [labelsNameAndAccount, setLabelsNameAndAccount] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [clickedLabel, setClickedLabel] = useState(null);
  const [nodesArray, setNodesArray] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [nameError, setNameError] = useState("");
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [showMigrateModal, setShowMigrateModal] = useState(false);
  const [pouchSize, setPouchSize] = useState("atc55");

  const preloaded = useRef(false);

  const [objects, setObjects] = useState([]);
  const [history, setHistory] = useState([[]]);

  const [presetId, setPresetId] = useState(null);
  const [labelPresets, setLabelPresets] = useState(null);

  const _controllerRef = useRef(null);
  const { t } = useTranslation("labels");

  const dropdownListPouchSize = useMemo(() => [
    { value: "atcNarrow", label: "ATC Narrow", labelWidth: 102, labelHeight: 92 },
    { value: "atc55", label: "ATC 55", labelWidth: 92, labelHeight: 92 },  
    { value: "atc80", label: "ATC 80", labelWidth: 92, labelHeight: 92 },
    { value: "atc85", label: "ATC 85", labelWidth: 92, labelHeight: 92 },
    { value: "atc90", label: "ATC 90", labelWidth: 92, labelHeight: 92 },
    { value: "atc110", label: "ATC 110", labelWidth: 92, labelHeight: 92 },
    { value: "proud60", label: "Proud 60", labelWidth: 92, labelHeight: 92 },
    { value: "proud70", label: "Proud 70", labelWidth: 92, labelHeight: 92 },
    { value: "proud76", label: "Proud 76", labelWidth: 92, labelHeight: 92 },
    { value: "proud80", label: "Proud 80", labelWidth: 92, labelHeight: 92 },
    { value: "proud90", label: "Proud 90", labelWidth: 92, labelHeight: 92 },
  ], []);

  useEffect(() => {
    if (selectedLabel) {
      setLabelName(selectedLabel?.name || "");
      setMachineType(selectedLabel?.machineType || "TruCard");
      setLabelType(selectedLabel?.labelType || "Canister");
      setPouchType(selectedLabel?.pouchType || "batchHeader");
      setPouchFacility(selectedLabel?.facility || "");
      setPouchSize(selectedLabel?.pouchSize || "atc55");
      setCustomizedLabel(selectedLabel?.customizedLabel || "");
      setPresetId(selectedLabel?.presetId || null);
    }
  }, [selectedLabel]);

  useEffect(() => {
    FacilityService.list(user, false).then((facilities) => {
      const pouchFacilities = facilities.filter(
        (facility) => facility.packagingMethod.type === "POUCH"
      ); // facilityId is deprecated
      const dropDownListPouchFacilities = pouchFacilities.map((facility) => ({
        label: facility.name,
        value: facility._id,
      }));
      setDropdownListPouchFacilities(dropDownListPouchFacilities);
      setPouchFacility(dropDownListPouchFacilities[0]?.value || "");
    });
  }, [user]);

  useEffect(() => {
    // Reset labelType quando machineType mudar
    if (machineType !== "TruPak" && labelType === "Pouch") {
      setLabelType("Canister");
    }
  }, [labelType, machineType]);

  useEffect(() => {
    if (!showLabelCanvas && !presetId && labelPresets) {
      setPresetId(labelPresets.find((preset) => preset.name === "Default")._id);
    } else if (
      presetId &&
      labelPresets &&
      !labelPresets.find((preset) => preset._id === presetId)
    ) {
      // Re-assign default if DB lost preset
      const defaultPreset = labelPresets.find(
        (preset) => preset.name === "Default"
      );
      if (defaultPreset) {
        setPresetId(defaultPreset._id);
      } else {
        createDefaultLabelPreset().then((preset) => {
          if (preset) {
            setPresetId(preset._id);
          } else {
            console.log("error: failed to create default");
          }
        });
      }
    }
  }, [showLabelCanvas, presetId, labelPresets]);

  useEffect(() => {
    if (labelType === "Pouch" && labelPresets) {
      const defaultPreset = labelPresets.find((preset) => preset.name === "Default");
      const selectedPouchSize = dropdownListPouchSize.find((pouch) => pouch.value === pouchSize);
      
      if (defaultPreset && selectedPouchSize) {
        const pouchPresetId = `${defaultPreset._id}-${pouchSize}`;
        const existingPouchPreset = labelPresets.find(preset => preset._id === pouchPresetId);
        
        if (!existingPouchPreset) {
          const newPouchPreset = {
            ...defaultPreset,
            _id: pouchPresetId,
            name: `${defaultPreset.name} - ${selectedPouchSize.label}`,
            labelHeight: selectedPouchSize.labelHeight,
            labelWidth: selectedPouchSize.labelWidth
          };

          setLabelPresets(prev => {
            const filtered = prev.filter(p => p._id !== pouchPresetId);
            return [...filtered, newPouchPreset];
          });
          
          setPresetId(pouchPresetId);
        } else {
          setPresetId(pouchPresetId);
        }
      }
    } else if (labelPresets) {
      const defaultPreset = labelPresets.find((preset) => preset.name === "Default");
      setPresetId(defaultPreset?._id);
    }
  }, [labelType, pouchSize, dropdownListPouchSize, labelPresets]);

  useEffect(() => {
    if (createLabel) {
      onShowTabBar(false);
    } else {
      onShowTabBar(true);
    }
  }, [createLabel, onShowTabBar]);

  useEffect(() => {
    getLabels();
    getLabelPresets();
  }, [refreshToggle]);

  useEffect(() => {
    if (selectedLabel && nodesArray?.length) {
      setCreateLabel(true);
    }
  }, [selectedLabel, nodesArray]);

  const handleRefreshToggle = (prevState) => setRefreshToggle(!prevState);

  const actionListItems = [
    {
      type: "modify",
      description: t("action0"),
    },
    {
      type: "copy",
      description: t("action2"),
    },
    {
      type: "delete",
      description: t("action1"),
    },
    // {
    //   type: "standardize",
    //   description: "standardize",
    // },
  ];

  const getActionMenu = () => {
    let listItems = [...actionListItems];
    return listItems;
  };

  // const getStandardActionMenu = (reportObj) => {
  //   let listItems = [];

  //   if (RoleCheckService.canCreateReports(user)) {
  //     listItems = [...actionListItems];
  //     // remove modify & delete
  //     let index = listItems.findIndex((o) => o.type === "modify");
  //     listItems.splice(index, 1);
  //     index = listItems.findIndex((o) => o.type === "delete");
  //     listItems.splice(index, 1);
  //   }
  //   return listItems;
  // };

  const handleAction = async (action, label) => {
    switch (action.type) {
      case "modify":
        handleSelectedLabel(label);
        handleNodesArray(label);
        break;
      case "delete":
        handleDeleteLabel(label);
        break;
      case "copy":
        handleCopyLabel(label);
        break;
      default:
        break;
    }
  };

  const arrayContainsObject = (obj, array) => {
    return array.some((elem) => elem === obj);
  };

  const buildNodesArray = (node, parent, array = []) => {
    // Grandfathered objects builder from label template

    for (let i = 0; i < node["childNodes"]?.length; i++) {
      buildNodesArray(node["childNodes"][i], node, array); // Traverse child nodes
    }
    if (!arrayContainsObject(node, array)) {
      if (parent) {
        node.parent = parent;
      }
      array.push(node);

      // sort nodesArrayTemp by nodeId, ascending order
      array.sort((a, b) => {
        return a.nodeId - b.nodeId;
      });
    }
    // BASE CASE
    return array;
  };

  const MigrateModal = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.CreateLabel__deleteWarningModal}>
        <p className={styles.CreateLabel__deleteWarningModalMessage}>
          {t("migrateWarning")}
        </p>
        <div className={styles.CreateLabel__ModalButtonsGroup}>
          <Button
            labelName={t("cancelButtonLabel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => {
              handleResetCreateLabelStatesToDefaults();
              setShowMigrateModal(false);
            }}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={t("continueButtonLabel")}
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => {
              setShowMigrateModal(false);
            }}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  useEffect(() => {
    if (user) {
      _controllerRef.current = new LabelController(user);
      getLabels();
      getLabelPresets();
    }
  }, [user]);

  const getLabels = () => {
    if (!_controllerRef.current) return Promise.resolve();

    return _controllerRef.current.getLabels().then((results) => {
      if (results) {
        let tempLabels = [];
        results.labels.forEach((label) => {
          if (label?.template && label?.name) {
            tempLabels.push(label);
          }
        });
        setLabels(tempLabels);

        const tempLabelsNameAndAccount = tempLabels.map(
          (label) => `${label.name}/${label.account}`
        );

        setLabelsNameAndAccount(tempLabelsNameAndAccount);
      } else {
        console.log("error: ", results);
      }
    });
  };

  const getLabelPresets = () => {
    if (!_controllerRef.current) return Promise.resolve();

    return _controllerRef.current.getLabelPresets().then((results) => {
      if (results) {
        if (!results.some((preset) => preset.name === "Default")) {
          createDefaultLabelPreset().then((defaultPreset) => {
            if (defaultPreset) {
              setLabelPresets(
                [...results, defaultPreset].map((preset) => ({
                  ...preset,
                  value: preset._id,
                  label: preset.name,
                }))
              );
            } else {
              console.log("error: failed to create default");
            }
          });
        } else {
          setLabelPresets(
            results.map((preset) => ({
              ...preset,
              value: preset._id,
              label: preset.name,
            }))
          );
        }
      } else {
        console.log("error: ", results);
      }
    });
  };

  const createDefaultLabelPreset = () => {
    if (!_controllerRef.current) return Promise.resolve();

    return _controllerRef.current
      .createLabelPreset(
        "Default",
        101.6,
        50.8,
        215.9,
        279.4,
        5,
        2,
        false,
        4.7625,
        12.7,
        0,
        3.175
      )
      .then((result) => {
        return result;
      });
  };

  const handleCopyLabel = (label) => {
    setLabels((prev) => [
      ...prev,
      {
        action: "updating",
        name: t("copyLabel", { name: label.name }),
      },
    ]);

    const newLabelName = label.name + "-copy";
    const machineType = label?.machineType || "TruCard";
    const labelType = label?.labelType || "Canister";
    const pouchType = label?.pouchType || "batchHeader";
    const facility = label?.facility || "";
    const customizedLabel = label?.customizedLabel || "";

    _controllerRef.current
      .createLabel(
        newLabelName,
        labelType,
        machineType,
        pouchType,
        facility,
        label.sheetWidth,
        label.sheetHeight,
        label.labelWidth,
        label.labelHeight,
        label.rowCount,
        label.colCount,
        label.rowGap,
        label.colGap,
        label.leftMargin,
        label.topMargin,
        label.presetId,
        customizedLabel,
        label.unit,
        label.template
      )
      .then((result) => {
        if (result) {
          getLabels();
          getLabelPresets();
        } else {
          console.log("error: ", result);
        }
      });
  };

  const handleDeleteLabel = (label) => {
    setLabels((prev) =>
      prev.map((_label) =>
        _label._id === label._id
          ? {
              ..._label,
              action: "updating",
              name: t("deleteLabel", { name: label.name }),
            }
          : _label
      )
    );
    _controllerRef.current.deleteLabel(label).then((result) => {
      if (result) {
        getLabels();
        getLabelPresets();
      } else {
        console.log("error: ", result);
      }
    });
  };

  const handleNodesArray = (label) => {
    if (Array.isArray(label.template)) {
      setNodesArray(label.template);
    } else {
      setNodesArray(buildNodesArray(label.template, null));
    }
  };

  const handleSelectedLabel = (label) => {
    setSelectedLabel(label);
    handleNodesArray(label);
  };

  const handleResetCreateLabelStatesToDefaults = () => {
    preloaded.current = false;
    setCreateLabel(false);
    setShowLabelCanvas(false);
    setLabelName("");
    setLabelType("Canister");
    setMachineType("TruCard");
    setCustomizedLabel("");
    setLabels(null);
    setSelectedLabel(null);
    setNodesArray([]);
    setPresetId(null);
    setLabelPresets(null);
    setObjects([]);
    setHistory([[]]);
    getLabels();
    getLabelPresets();
  };

  const handleLabelName = (name) => {
    const labelNameAccountString = `${name}/${user.account._id}`;
    if (labelsNameAndAccount.includes(labelNameAccountString)) {
      setNameError(t("existingLabelError"));
    } else if (nameError) {
      setNameError("");
    }
    setLabelName(name);
  };

  const dropdownListCanisterMachineType = [
    { value: "TruCard", label: "TruCard" },
    { value: "TruPak", label: "TruPak" },
    { value: "TruScript", label: "TruScript" },
  ];

  const getDropdownListLabelType = () => {
    if (machineType === "TruPak") {
      return [
        { value: t("canisterLabel"), label: t("canisterLabel") },
        { value: t("pouchLabel"), label: t("pouchLabel") },
        { value: t("otherLabel"), label: t("otherLabel") },
      ];
    }
    return [
      { value: t("canisterLabel"), label: t("canisterLabel") },
      { value: t("otherLabel"), label: t("otherLabel") },
    ];
  };

  const dropdownListPouchType = [
    {
      label: "Batch Header",
      value: "batchHeader",
    },
    {
      label: "Batch Footer",
      value: "batchFooter",
    },
    {
      label: "Patient Header",
      value: "patientHeader",
    },
    {
      label: "Patient Footer",
      value: "patientFooter",
    },
    {
      label: "Medication Bag",
      value: "medBag",
    },
    {
      label: "Leading Summary",
      value: "leadingSummary",
    },
    {
      label: "Trailing Summary",
      value: "trailingSummary",
    },
    {
      label: "Replenishment",
      value: "replenishment",
    },
    {
      label: "Attention",
      value: "attention",
    },
    {
      label: "Pre-Pack",
      value: "prePack",
    },
  ];

  const handleSelectedLabelType = (selectedLabelType) => {
    setLabelType(selectedLabelType);
  };

  const handleSelectedMachineType = (selectedMachineType) => {
    if (selectedMachineType === "TruPak" && labelType === "Pouch") {
      setLabelType(t("canisterLabel"));
    }
    setMachineType(selectedMachineType);
  };

  const handleSelectedPouchType = (selectedPouchType) => {
    const pouchType = dropdownListPouchType.find(
      (pouch) => pouch.value === selectedPouchType
    );
    setPouchType(pouchType.value);
  };

  const handleSelectedPouchSize = (selectedPouchSize) => {
    setPouchSize(selectedPouchSize);
  };

  const handleSelectedLabelPreset = (id) => {
    setPresetId(id);
  };

  const handleHideLabelCanvas = () => {
    setShowLabelCanvas(false);
  };

  const handleCreateLabel = () => {
    setSelectedLabel(null);
    setNodesArray([]);
    setCreateLabel(true);
    setLabelName("");
  };

  const handleEditLabel = () => {
    setShowLabelCanvas(true);
  };

  const handlePrevious = () => {
    handleResetCreateLabelStatesToDefaults();
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleClickedLabel = (label) => {
    if (!label.labelWidth) {
      setShowMigrateModal(true);
      handleSelectedLabel(label);
    } else {
      setCreateLabel(false);
      setSelectedLabel(false);
      handleNodesArray(label);
      setClickedLabel(label);
      onShowTabBar(false);
    }
  };

  const handleUnsetClickedLabel = () => {
    setClickedLabel(null);
  };

  const handleSaveLabel = async ({
    name,
    labelType,
    machineType,
    pouchType,
    facility,
    sheetWidth,
    sheetHeight,
    labelWidth,
    labelHeight,
    rowCount,
    colCount,
    rowGap,
    colGap,
    leftMargin,
    topMargin,
    presetId,
    customizedLabel,
    unit,
    template,
  }) => {
    _controllerRef.current
      .createLabel(
        name,
        labelType,
        machineType,
        pouchType,
        facility,
        sheetWidth,
        sheetHeight,
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        rowGap,
        colGap,
        leftMargin,
        topMargin,
        presetId,
        customizedLabel,
        unit,
        template
      )
      .then((result) => {
        if (result) {
          handleResetCreateLabelStatesToDefaults();
        } else {
          console.log("error: ", result);
        }
      });
  };

  const handleUpdateLabel = async (
    label,
    {
      name,
      labelType,
      machineType,
      pouchType,
      facility,
      sheetWidth,
      sheetHeight,
      labelWidth,
      labelHeight,
      rowCount,
      colCount,
      rowGap,
      colGap,
      leftMargin,
      topMargin,
      presetId,
      customizedLabel,
      unit,
      template,
    }
  ) => {
    _controllerRef.current
      .updateLabel(
        label,
        name,
        labelType,
        machineType,
        pouchType,
        facility,
        sheetWidth,
        sheetHeight,
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        rowGap,
        colGap,
        leftMargin,
        topMargin,
        presetId,
        customizedLabel,
        unit,
        template
      )
      .then((result) => {
        if (result) {
          handleResetCreateLabelStatesToDefaults();
        } else {
          console.log("error: ", result);
        }
      });
  };
  if (!createLabel && !clickedLabel) {
    return (
      <div className={styles.CreateLabel__container}>
        <Fragment>
          <div className={styles.CreateLabel__header}>
            <SearchBar
              placeholder={t("searchPlaceholder")}
              onSearch={handleSearch}
              focus={true}
            />
            {labelPresets !== null && (
              <div
                className={styles.CreateLabel__createButtonContainer}
                onClick={handleCreateLabel}
              >
                <CreateButton
                  labelName={t("newButtonLabel")}
                  isEnabled={
                    labelName && labelName.trim().length ? true : false
                  }
                />
              </div>
            )}
          </div>

          <div
            className={`${styles.CreateLabel__labelsPage} ${styles["CreateLabel__labelsPage--custom"]}`}
          >
            <Spacer space={20} unit={"px"} />

            <div className={styles.CreateLabel__labelsRow}>
              <div className={styles.CreateLabel__labelsTitle}>
                {t("customLabelTitle")}
              </div>
            </div>

            <div className={styles.CreateLabel__labelsContainer}>
              {labels === null ? (
                <div>
                  <Rings color="#089BAB" height={110} width={110} />
                  <p className={styles.CreateLabel__labelsLoader}>
                    {t("fetching")}
                  </p>
                </div>
              ) : (
                labels
                  .filter((filter) => {
                    return filter.name.toUpperCase().includes(filterText) ||
                      filterText === ""
                      ? true
                      : false;
                  })
                  .map((label) =>
                    label.action === "updating" ? (
                      <LabelTile
                        key={label.name}
                        imageName={"fetching"}
                        imageFillColor={"#089BAB"}
                        title={label.name}
                        isTemplate={true}
                      />
                    ) : (
                      <LabelTile
                        key={label.name}
                        imageName={"barChart"}
                        imageFillColor={"#089BAB"}
                        title={label.name}
                        isTemplate={false}
                        actions={getActionMenu(label)}
                        onAction={(action) => {
                          handleAction(action, label);
                        }}
                        onSelectLabel={() => {
                          handleSelectedLabel(label);
                        }}
                        onClickedLabel={() => {
                          handleClickedLabel(label);
                        }}
                      />
                    )
                  )
              )}
            </div>
          </div>
        </Fragment>
      </div>
    );
  } else if (!createLabel && clickedLabel) {
    return (
      <PrintLabels
        user={user}
        label={clickedLabel}
        onPrevious={handleUnsetClickedLabel}
        onClickedLabel={handleClickedLabel}
        onRefreshToggle={handleRefreshToggle}
      />
    );
  } else if (createLabel) {
      return (
      <Fragment>
        {showMigrateModal && MigrateModal}

        {!showLabelCanvas && (
          <div
            className={`${styles.CreateLabel__container} ${styles["CreateLabel__container--center"]}`}
          >
            <header>
              <p className={styles.CreateLabel__heading}>
                {selectedLabel ? t("updateTitle") : t("createTitle")}
              </p>
            </header>
            <div className={styles.CreateLabel__inputContainers}>
              <div className={styles.CreateLabel__labelNameContainer}>
                <div className={styles.CreateLabel__inputContainer}>
                  <TextInput
                    value={labelName}
                    labelName={t("labelNameLabel")}
                    focus={true}
                    isEnabled={true}
                    onChange={(val) => handleLabelName(val)}
                    placeholder={""}
                    type={"text"}
                  />
                  {nameError ? (
                    <p className={styles.CreateLabel__nameError}>{nameError}</p>
                  ) : null}
                </div>
              </div>
              <div className={styles.CreateLabel__machineTypeContainer}>
                <AntSelect
                  label={t("machineTypeLabel")}
                  value={machineType}
                  selectStyle="ant-selectGroup-002"
                  minWidth={336}
                  width={336}
                  placeholder={t("machineTypePlaceholder")}
                  options={dropdownListCanisterMachineType}
                  onChange={handleSelectedMachineType}
                />
              </div>
              <div className={styles.CreateLabel__labelTypeContainer}>
                <AntSelect
                  label={t("labelTypeLabel")}
                  value={labelType}
                  selectStyle="ant-selectGroup-002"
                  minWidth={336}
                  width={336}
                  placeholder={t("labelTypePlaceholder")}
                  options={getDropdownListLabelType()}
                  onChange={handleSelectedLabelType}
                />
              </div>
              {labelType === "Pouch" && (
                <>
                  <div className={styles.CreateLabel__machineTypeContainer}>
                    <AntSelect
                      label={t("facilityLabel")}
                      value={pouchFacility}
                      selectStyle="ant-selectGroup-002"
                      minWidth={336}
                      width={336}
                      placeHolder={t("facilityPlaceholder")}
                      options={dropdownListPouchFacilities}
                      onChange={(facility) => setPouchFacility(facility)}
                    />
                  </div>

                  <div className={styles.CreateLabel__machineTypeContainer}>
                    <AntSelect
                      label={t("pouchTypeLabel")}
                      value={pouchType}
                      selectStyle="ant-selectGroup-002"
                      minWidth={336}
                      width={336}
                      placeHolder={t("pouchTypePlaceholder")}
                      options={dropdownListPouchType}
                      onChange={handleSelectedPouchType}
                    />
                  </div>

                  <div className={styles.CreateLabel__machineTypeContainer}>
                    <AntSelect
                      label={t("pouchSizeLabel")}
                      value={pouchSize}
                      placeHolder={t("pouchSizePlaceholder")}
                      selectStyle="ant-selectGroup-002"
                      minWidth={336}
                      width={336}
                      options={dropdownListPouchSize}
                      onChange={handleSelectedPouchSize}
                    />
                  </div>

                </>
              )}

              {!selectedLabel && labelType !== "Pouch" ? (
                <div className={styles.CreateLabel__machineTypeContainer}>
                  <AntSelect
                    label={t("loadPresetLabel")}
                    value={presetId}
                    selectStyle="ant-selectGroup-002"
                    minWidth={336}
                    width={336}
                    placeHolder=""
                    options={labelPresets.sort((a, b) => {
                      if (a.name === "Default") return -1;
                      if (b.name === "Default") return 1;
                      return a.name.localeCompare(b.name);
                    })}
                    onChange={handleSelectedLabelPreset}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.CreateLabel__buttonsContainer}>
              <Button
                labelName={t("cancelButtonLabel")}
                minWidth={"123px"}
                isPrimary={false}
                onClick={() => handlePrevious()}
              />
              <Spacer space={20} unit={"px"} />
              <Button
                labelName={t("continueButtonLabel")}
                isPrimary={true}
                minWidth={"213px"}
                onClick={handleEditLabel}
                isDisabled={
                  !nameError && labelName && labelName.trim().length
                    ? false
                    : true
                }
              />
            </div>
          </div>
        )}

        <div
          style={{ display: showLabelCanvas ? "block" : "none" }}
          className={`${styles.CreateLabel__container} ${styles["CreateLabel__container--center"]}`}
        >
          <LabelEditorComponent
            i18n={i18next}
            label={selectedLabel}
            objects={objects}
            setObjects={setObjects}
            history={history}
            setHistory={setHistory}
            labelPresets={labelPresets}
            setLabelPresets={setLabelPresets}
            presetId={presetId}
            setPresetId={setPresetId}
            labelName={labelName.trim()}
            labelType={labelType}
            machineType={machineType}
            pouchType={pouchType}
            facility={pouchFacility}
            customizedLabel={customizedLabel}
            onHideLabelCanvas={handleHideLabelCanvas}
            _controllerRef={_controllerRef}
            nodesArrayForUpdate={nodesArray}
            preloaded={preloaded}
            dataMappingOptions={getDataMapping(labelType)}
            saveLabel={handleSaveLabel}
            updateLabel={handleUpdateLabel}
          />
          {/* <LabelEditor
            key={selectedLabel?._id}
            user={user}
            label={selectedLabel}
            objects={objects}
            setObjects={setObjects}
            history={history}
            setHistory={setHistory}
            labelPresets={labelPresets}
            setLabelPresets={setLabelPresets}
            presetId={presetId}
            setPresetId={setPresetId}
            onResetCreateLabelStatesToDefaults={
              handleResetCreateLabelStatesToDefaults
            }
            labelName={labelName.trim()}
            labelType={labelType}
            machineType={machineType}
            customizedLabel={customizedLabel}
            onHideLabelCanvas={handleHideLabelCanvas}
            _controllerRef={_controllerRef}
            nodesArrayForUpdate={nodesArray}
            preloaded={preloaded}
          /> */}
        </div>
      </Fragment>
    );
  } else if (showLabelCanvas) {
    return null;
  }
};

export default CreateLabel;

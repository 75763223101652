import React, { useEffect, useContext, useRef } from "react";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const SearchBar = ({
  placeholder,
  searchText,
  onSearch,
  focus,
  smFullWidth = false,
  additionalInputStyle,
}) => {
  const inputRef = useRef(null);
  const { smallScreen, mediumScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);

  const containerStyle = smScreen
    ? {
        display: "flex",
        flexDirection: "row",
        marginLeft: smFullWidth ? "0px" : "20px",
        marginTop: smFullWidth ? "0px" : "13px",
        width: smFullWidth ? "100%" : "inherit",
      }
    : { display: "flex", flexDirection: "row" };

  const inputStyle = smScreen
    ? {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22.5px",
        color: "var(--black)",
        backgroundColor: "#FAFCFE",
        outline: "none",
        flexDirection: "row",
        border: "1px solid",
        borderColor: "#D2D5E1",
        borderRadius: "10px",
        height: "37px",
        width: "100%",
        paddingLeft: "10px",
      }
    : mdScreen
    ? {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22.5px",
        color: "var(--black)",
        border: "none",
        borderColor: "#9CD7DD",
        outline: "none",
        flexDirection: "row",
        maxHeight: "30px",
        flexGrow: 1,
        maxWidth: "160px",
        marginLeft: "10px",
      }
    : {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22.5px",
        color: "var(--black)",
        border: "none",
        outline: "none",
        flexDirection: "row",
        maxHeight: "36px",
        width: "160px",
        marginLeft: "2px",
      };

  useEffect(() => {
    inputRef.current.focus();
  }, [searchText]);

  return (
    <div style={containerStyle}>
      <input
        ref={inputRef}
        style={{ ...additionalInputStyle, ...inputStyle }}
        type={"text"}
        placeholder={placeholder}
        value={searchText}
        onChange={(e) => {
          if (onSearch) {
            onSearch(e.currentTarget.value);
          }
        }}
      />
    </div>
  );
};

export default SearchBar;

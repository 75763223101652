import { Col, Dropdown, Input, Modal, Row, Table } from "antd";
import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MoreActionsIcon } from "../../assets/more-actions.svg";
import CreateButton from "../../components/CreateButton";
import ErrorBanner from "../../components/ErrorBanner";
import { ScreenContext } from "../../contexts/ScreenContext";
import ProductController from "../../controllers/ProductController";
import ImageService from "../../services/ImageService";
import ProductService from "../../services/ProductService";
import RoleCheckService from "../../services/RoleCheckService";
import styles from "./Products.module.css";
import ProductsCheckForUpdatesModal from "./ProductsCheckForUpdatesModal";
import ProductsModalForOrderGenerator from "./ProductsModalForOrderGenerator";
import "./ProductsModalForOrderGenerator.css";

const { Search } = Input;

const Products = ({ user, alarm, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("products");
  const productController = useRef(new ProductController(user));
  const isInitialMount = useRef(true);

  const actionListItems = [
    {
      type: "enable",
      description: t("action0"),
    },
    {
      type: "disable",
      description: t("action1"),
    },
    {
      type: "edit",
      description: t("action2"),
    },
    {
      type: "approve",
      description: t("action4"),
    },
    {
      type: "reject",
      description: t("action3"),
    },
    {
      type: "copy",
      description: t("action5"),
    },
  ];

  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCheckForProductUpdate, setShowCheckForProductUpdate] =
    useState(false);
  const productApprovalRequired = user.account.productApprovalRequired;
  const [showConfirmRejectProduct, setShowConfirmRejectProduct] =
    useState(false);
  const [rejectedProduct, setRejectedProduct] = useState();
  const [locationPathname, setLocationPathname] = useState("");
  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smallScreenQuery = useMediaQuery(smallScreen);
  const [modalInfoForOrderGenerator, setModalInfoForOrderGenerator] = useState({
    show: false,
    packageId: null,
    productPrimaryName: null,
  });

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      defaultPageSize: 10,
    },
  });

  useEffect(() => {
    smallScreenQuery && handleShowSmScreenHeader(true);
  }, [smallScreenQuery]);

  useEffect(() => {
    setLocationPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    productController.current = new ProductController(user);
  }, [user]);

  useEffect(() => {
    async function fetchData() {
      if (
        (alarm && !isInitialMount.current) ||
        location?.state?.refresh === true
      ) {
        let text = "";
        if (alarm) {
          text = alarm.callToActionRef;
        } else if (location?.state?.refresh === true) {
          setFilterText("");
        }
        await debouncedSearch(text, tableParams.pagination);
      }
    }
    fetchData();
  }, [alarm, location?.state?.refresh]);

  useEffect(() => {
    async function fetchData() {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        await debouncedSearch(filterText, tableParams.pagination);
      }
    }
    fetchData();
  }, []);

  const debouncedSearch = useCallback(
    //500ms before search (for fast typing)
    debounce(
      async (value, pagination) =>
        await handleSearch(value, pagination, productApprovalRequired),
      500
    ),
    []
  );

  const handlePaginationChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setProducts([]);
    }

    debouncedSearch(filterText, pagination);
  };

  const handleProductsResult = (error, data, pagination) => {
    setLoading(false);
    if (error) {
      setError(error);
    } else {
      setProducts(data.products);
      const temp = tableParams;
      temp.pagination = {
        ...pagination,
        total: data?.count ?? pagination.pageSize,
      };
      setTableParams(temp);
    }
  };

  const handleSearch = async (searchText, pagination, isPendingFirst) => {
    setLoading(true);
    searchText = (searchText ?? "").trim();
    setFilterText(searchText);
    await productController.current.searchForProductsPaginated(
      searchText,
      pagination.current,
      pagination.pageSize,
      isPendingFirst,
      (error, data) => handleProductsResult(error, data, pagination)
    );
  };

  const handleCreate = () => {
    if (productApprovalRequired) {
      const pendingProduct = { isPending: true, images: [] };
      navigate("create", { state: { product: pendingProduct, isNew: true } });
    } else {
      const activeProduct = { isPending: false, images: [] };
      navigate("create", { state: { product: activeProduct, isNew: true } });
    }
  };

  const getActionMenu = (product) => {
    let listItems = [...actionListItems];

    if (!RoleCheckService.canCreateProduct(user)) {
      let index = listItems.findIndex((o) => o.type === "edit");
      listItems.splice(index, 1);
    }
    if (
      !RoleCheckService.canCreateProduct(user) ||
      (product.isEnabled && !product.isPending) ||
      product.isPending
    ) {
      let index = listItems.findIndex((o) => o.type === "enable");
      listItems.splice(index, 1);
    }
    if (
      !RoleCheckService.canCreateProduct(user) ||
      !product.isEnabled ||
      (product.isEnabled && product.isPending)
    ) {
      let index = listItems.findIndex((o) => o.type === "disable");
      listItems.splice(index, 1);
    }
    if (
      !RoleCheckService.canApproveRejectProducts(user) ||
      !product.isPending ||
      !productApprovalRequired
    ) {
      const index = listItems.findIndex((o) => o.type === "approve");
      listItems.splice(index, 1);
    }
    if (
      !RoleCheckService.canApproveRejectProducts(user) ||
      !product.isPending ||
      !productApprovalRequired
    ) {
      let index = listItems.findIndex((o) => o.type === "reject");
      listItems.splice(index, 1);
    }
    // if (!RoleCheckService.addEquivalencies(user)) {
    //   let index = listItems.findIndex((o) => o.type === "equivalencies");
    //   listItems.splice(index, 1);
    // }
    return listItems;
  };

  const handleAction = (action, product) => {
    let newProduct = { ...product };
    switch (action.type) {
      case "enable":
        newProduct.isEnabled = true;
        handleEnable(newProduct);
        break;
      case "disable":
        newProduct.isEnabled = false;
        handleEnable(newProduct);
        break;
      case "edit": {
        const tempProduct = { ...product };
        if (productApprovalRequired) {
          tempProduct.isPending = true;
        }
        navigate("create", { state: { product: tempProduct } });
        break;
      }
      case "approve":
        newProduct.isPending = false;
        handleUpdate(newProduct);
        break;
      case "reject":
        setRejectedProduct(newProduct);
        setShowConfirmRejectProduct(true);
        break;
      case "copy":
        navigate("create", {
          state: { isCopy: true, isNew: true, product: product },
        });
        break;
      // case "equivalencies":
      //   navigate("equivalencies", { state: { product: product } });
      //   break;
      default:
        break;
    }
  };

  const handlePullAllImagesFromFdb = () => {
    ImageService.pullAllFdbImages(user)
      .then(() => {})
      .catch((error) => {
        setError(error);
      });
  };

  const handleEnable = async (product) => {
    if (product.allowVBM) {
      product.updateVBM = true;
    }
    ProductService.enable(user, product)
      .then((productDoc) => {
        let index = products.findIndex((o) => o._id === productDoc._id);
        let list = [...products];
        list[index] = productDoc;
        setProducts(list);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleUpdate = (product) => {
    setLoading(true);
    ProductService.update(user, product)
      .then((productDoc) => {
        let index = products.findIndex((o) => o._id === productDoc._id);
        let list = [...products];
        list[index] = productDoc;
        setProducts(list);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (product) => {
    setLoading(true);
    ProductService.delete(user, product)
      .then((productDoc) => {
        if (product.revision === 1) {
          let index = products.findIndex((o) => o._id === productDoc._id);
          let list = [...products];
          list.splice(index, 1);
          setProducts(list);
        } else {
          let index = products.findIndex((o) => o._id === productDoc._id);
          let list = [...products];
          list[index] = productDoc;
          setProducts(list);
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRowClassName = (product) => {
    let className = ``;

    if (!product.isEnabled) {
      className += `${styles["Products__listitem--disabled"]}`;
    } else if (product.isPending) {
      className += `${styles["Products__listitem--pending"]}`;
    }

    return className;
  };

  const handleCloseModalForOrderGenerator = () => {
    setModalInfoForOrderGenerator((prevState) => {
      return {
        ...prevState,
        show: false,
      };
    });
  };

  return (
    <>
      {showConfirmRejectProduct && (
        <Modal
          title={t("rejectTitle")}
          open={showConfirmRejectProduct}
          onCancel={() => {
            setRejectedProduct(null);
            setShowConfirmRejectProduct(false);
          }}
          onOk={() => {
            handleDelete(rejectedProduct);
            setRejectedProduct(null);
            setShowConfirmRejectProduct(false);
          }}
        >
          {t("rejectConfirm")}
        </Modal>
      )}

      <ProductsCheckForUpdatesModal
        user={user}
        open={showCheckForProductUpdate}
        onCancel={() => {
          setShowCheckForProductUpdate(false);
        }}
        onOk={() => {
          setShowCheckForProductUpdate(false);
        }}
        onError={(error) => {
          setError(error);
        }}
      />

      <Outlet />
      {error && (
        <div className={styles.Products__errorBannerContainer}>
          <ErrorBanner message={error} onClose={() => setError(null)} />
        </div>
      )}

      {(locationPathname === "/settings/products" ||
        locationPathname === "/inventory/medications" ||
        locationPathname === "/dispensing-orders/order-generation") && (
        <Row gutter={5} align={"middle"}>
          {!smallScreenQuery && (
            <Col span={24} style={{ height: "70px" }}></Col>
          )}
          <Col span={smallScreenQuery ? 24 : 12}>
            <Search
              placeholder={
                smallScreenQuery
                  ? t("searchPlaceholderMobile")
                  : t("searchPlaceholder")
              }
              value={filterText}
              allowClear={true}
              onChange={async (e) => {
                const value = e.target.value;
                setFilterText(value);
                const p = {
                  ...tableParams.pagination,
                  current: 1,
                };
                await debouncedSearch(value, p);
              }}
            />
          </Col>

          <Col span={smallScreenQuery ? 24 : 12}>
            <Row justify={"end"}>
              <Col span={smallScreenQuery ? 24 : undefined}>
                {RoleCheckService.isSuperAdmin(user) &&
                  locationPathname !==
                    "/dispensing-orders/order-generation" && (
                    <CreateButton
                      onClick={handlePullAllImagesFromFdb}
                      isEnabled={true}
                      labelName={t("pullImagesLabel")}
                    />
                  )}
              </Col>

              <Col span={smallScreenQuery ? 24 : undefined}>
                {RoleCheckService.canCreateProduct(user) &&
                  locationPathname !==
                    "/dispensing-orders/order-generation" && (
                    <CreateButton
                      onClick={handleCreate}
                      isEnabled={true}
                      labelName={t("createButtonLabel")}
                    />
                  )}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            {modalInfoForOrderGenerator.show && (
              <div className="ProductsModalForOrderGenerator__container">
                <ProductsModalForOrderGenerator
                  onCloseModalForOrderGenerator={
                    handleCloseModalForOrderGenerator
                  }
                  onPackageId={rest.onPackageId}
                  packageId={modalInfoForOrderGenerator.packageId}
                  productPrimaryName={
                    modalInfoForOrderGenerator.productPrimaryName
                  }
                  onHideProductsTable={rest.onHideProductsTable}
                />
              </div>
            )}
            <Table
              onRow={
                rest?.onPackageId && rest?.onHideProductsTable
                  ? (record, rowIndex) => {
                      return {
                        onClick: () => {
                          setModalInfoForOrderGenerator((prevState) => {
                            return {
                              packageId: record?.packageId,
                              productPrimaryName: record?.primaryName,
                              show: true,
                            };
                          });
                        },
                      };
                    }
                  : null
              }
              loading={loading}
              columns={[
                {
                  title: t("col0"), //Id
                  render: (p) => p.packageId,
                  width: 130,
                  ellipsis: true,
                },
                {
                  title: t("col1"), //Description
                  render: (p) =>
                    `${productController?.current?.getDefaultName(p) ?? ""}`,
                  ellipsis: true,
                },
                {
                  title: t("col6"), //Form
                  render: (p) =>
                    `${p.dosageForm?.type ?? p.dosageForm?.description ?? ""}`,
                  ellipsis: true,
                  responsive: ["xxl", "xl"],
                },
                {
                  title: t("col3"), //Manufacturer
                  render: (p) =>
                    `${p.manufacturer?.fullName || p.manufacturer?.abbrName}`,
                  ellipsis: true,
                },
                {
                  title: t("col5"), //Label ID
                  render: (p) => `${p.humanReadableId}`,
                  ellipsis: true,
                  responsive: ["xxl", "xl"],
                  width: 130,
                },
                {
                  title: t("col4"), //Actions
                  width: 100,
                  align: "center",
                  render: (product) =>
                    RoleCheckService.canCreateProduct(user) ? (
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: getActionMenu(product).map((item) => ({
                            key: item.action,
                            label: (
                              <div onClick={() => handleAction(item, product)}>
                                {item.description}
                              </div>
                            ),
                          })),
                        }}
                      >
                        <div style={{ cursor: "pointer", margin: "4px" }}>
                          <MoreActionsIcon />
                        </div>
                      </Dropdown>
                    ) : null,
                },
              ]}
              rowKey={(record) => record._id}
              dataSource={products}
              pagination={tableParams.pagination}
              onChange={handlePaginationChange}
              scroll={
                !products || products.length === 0
                  ? undefined
                  : { y: window.innerHeight - 320 }
              }
              rowClassName={getRowClassName}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Products;

import { VictoryPie, VictoryTheme, VictoryLegend } from "victory";
import { useTranslation } from "react-i18next";
import styles from "./ScriptTypeGraph.module.css";

const ScriptTypeGraph = ({
  user,
  fetchingData,
  graphData,
  graphTitle,
  palette,
}) => {
  // For Testing
  // const graphData = [
  //   { x: "High", y: 11 },
  //   { x: "Low", y: 12 },
  //   { x: "Stat", y: 5 },
  //   { x: "Waiter", y: 8 },
  // ];

  const { t } = useTranslation("graphsGeneral");

  let noDataFoundClass = `${styles.ScriptTypeGraph__noDataFoundMsg} ${styles["ScriptTypeGraph__noDataFoundMsg--English"]}`;

  if (user?.language?.type === "es") {
    noDataFoundClass = `${styles.ScriptTypeGraph__noDataFoundMsg} ${styles["ScriptTypeGraph__noDataFoundMsg--Spanish"]}`;
  } else if (user?.language?.type === "fr") {
    noDataFoundClass = `${styles.ScriptTypeGraph__noDataFoundMsg} ${styles["ScriptTypeGraph__noDataFoundMsg--French"]}`;
  }

  return (
    <div className={styles.ScriptTypeGraph__container}>
      <h2>{graphTitle}</h2>

      {!fetchingData && !graphData.length && (
        <p className={noDataFoundClass}>{t("graphsGeneral:noData")}</p>
      )}
      <VictoryPie
        theme={VictoryTheme.material}
        width={450}
        height={266}
        data={graphData}
        labels={({ datum }) => (datum.y > 0 ? datum.y : "")}
        labelRadius={50}
        radius={105}
        style={{
          data: {
            fill: ({ datum }) => {
              if (datum.x === graphData[0]?.x) {
                return palette[0];
              } else if (datum.x === graphData[1]?.x) {
                return palette[1];
              } else if (datum.x === graphData[2]?.x) {
                return palette[2];
              } else {
                return palette[3];
              }
            },
          },
          labels: {
            fontSize: 14,
            fontWeight: "normal",
            fill: "#000000",
          },
        }}
      />

      <div className={styles.ScriptTypeGraph_VictoryLegendContainer}>
        <VictoryLegend
          style={{
            labels: {
              fontSize: 12,
              fontWeight: "400",
              fill: "#000000",
              fontFamily: "Segoe UI",
              fontStyle: "normal",
            },
          }}
          orientation="vertical"
          symbolSpacer={8}
          gutter={20}
          width={300}
          height={90}
          data={[
            {
              name: graphData[0]?.x || "High",
              symbol: { fill: palette[0] },
              labels: { fill: "#000000" },
            },
            {
              name: graphData[1]?.x || "Low",
              symbol: { fill: palette[1] },
              labels: { fill: "#000000" },
            },
            {
              name: graphData[2]?.x || "Stat",
              symbol: { fill: palette[2] },
              labels: { fill: "#000000" },
            },
            {
              name: graphData[3]?.x || "Waiter",
              symbol: { fill: palette[3] },
              labels: { fill: "#000000" },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ScriptTypeGraph;

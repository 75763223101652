import React from "react";

import { VictoryPie, VictoryTheme, VictoryLegend, VictoryLabel } from "victory";
import styles from "./CanisterTrayRatioGraph.module.css";

import { ReactComponent as Square } from "../../../../assets/square.svg";
import { useTranslation } from "react-i18next";

function CanisterTrayRatioGraph({
  user,
  data,
  fetchingData,
  palette,
  timeRange,
  onTimeRange,
  canisterLabel,
  trayLabel,
  onShowMedicationsDispensed,
  onShowCanisterMedicationsDispensed,
  onShowTrayMedicationsDispensed,
  onShowDropdowns,
}) {
  // For Testing
  // const data = [
  //   { text: "Canister", x: "Canister", y: 1317 },
  //   { text: "Tray", x: "Tray", y: 1177 },
  // ];

  const { t } = useTranslation(["trupakDashboard", "graphsGeneral"]);

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const onChartMouseClick = (deviceType) => {
    onShowDropdowns(false);
    onShowMedicationsDispensed(true);
    if (deviceType === "tray") {
      onShowTrayMedicationsDispensed(true);
    } else {
      onShowCanisterMedicationsDispensed(true);
    }
  };

  let noDataFoundClass = `${styles.CanisterTrayRatio__noDataFoundMsg} ${styles["CanisterTrayRatio__noDataFoundMsg--English"]}`;

  if (user?.language?.type === "es") {
    noDataFoundClass = `${styles.CanisterTrayRatio__noDataFoundMsg} ${styles["CanisterTrayRatio__noDataFoundMsg--Spanish"]}`;
  } else if (user?.language?.type === "fr") {
    noDataFoundClass = `${styles.CanisterTrayRatio__noDataFoundMsg} ${styles["CanisterTrayRatio__noDataFoundMsg--French"]}`;
  }

  const Chart = (
    <div className={styles.CanisterTrayRatioGraph__PieChartContainer}>
      <div className={styles.CanisterTrayRatioGraph__VictoryPieContainer}>
        <h2>{t("canisterTrayTitle")}</h2>
        <VictoryPie
          data={data}
          width={450}
          height={288}
          startAngle={90} // Rotates the chart by 90 degrees
          endAngle={360 + 90}
          labelRadius={50}
          radius={104}
          style={{
            data: {
              fill: ({ datum }) => {
                if (datum.x === trayLabel) {
                  return palette[1];
                } else if (datum.x === canisterLabel) {
                  return palette[0];
                }
              },
            },
            labels: {
              fontSize: 12,
              fontWeight: "bold",
              fill: "#ffffff",
            },
          }}
          theme={VictoryTheme.clean}
          events={[
            {
              childName: ["pie"],
              target: "data",
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: "labels",
                      mutation: ({ text }) => {
                        if (
                          text.toLowerCase() === canisterLabel.toLowerCase()
                        ) {
                          onChartMouseClick("canister");
                        } else {
                          onChartMouseClick("tray");
                        }
                      },
                    },
                  ];
                },
              },
            },
          ]}
        />
        {!fetchingData && !data.length && (
          <p className={noDataFoundClass}>{t("graphsGeneral:noData")}</p>
        )}
      </div>

      <div className={styles.CanisterTrayRatioGraph__VictoryLegendContainer}>
        <VictoryLegend
          style={{
            labels: {
              fontSize: 12,
              fontWeight: "400",
              fill: "#000000",
              fontFamily: "Segoe UI",
              fontStyle: "normal",
            },
          }}
          events={[
            {
              target: "data",
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: "labels",
                      mutation: (props) => {
                        if (
                          props?.datum?.name?.toLowerCase() ===
                          canisterLabel.toLowerCase()
                        ) {
                          onChartMouseClick("canister");
                        } else {
                          onChartMouseClick("tray");
                        }
                      },
                    },
                  ];
                },
              },
            },
          ]}
          orientation="vertical"
          symbolSpacer={8}
          gutter={20}
          width={300}
          height={90}
          data={
            data.length
              ? [
                  {
                    name: data[1].x,
                    symbol: { fill: palette[1] },
                    labels: { fill: "#000000" },
                  },
                  {
                    name: data[0].x,
                    symbol: { fill: palette[0] },
                    labels: { fill: "#000000" },
                  },
                ]
              : []
          }
        />
      </div>
    </div>
  );

  return (
    <div>
      {Chart}
      <div
        onClick={handleStopPropagation}
        className={styles.CanisterTrayRatioGraph__timeRangeContainer}
      >
        <div
          className={styles.CanisterTrayRatioGraph__timeRangeOption}
          onClick={fetchingData ? () => {} : () => onTimeRange("year")}
        >
          <div
            className={
              timeRange === "year"
                ? `${styles.CanisterTrayRatioGraph__squareContainer} ${styles["CanisterTrayRatioGraph__squareContainer--active"]}`
                : styles.CanisterTrayRatioGraph__squareContainer
            }
          >
            <Square />
          </div>
          <p>{t("trupakDashboard:lastYearLabel")}</p>
        </div>
        <div
          className={styles.CanisterTrayRatioGraph__timeRangeOption}
          onClick={fetchingData ? () => {} : () => onTimeRange("month")}
        >
          <div
            className={
              timeRange === "month"
                ? `${styles.CanisterTrayRatioGraph__squareContainer} ${styles["CanisterTrayRatioGraph__squareContainer--active"]}`
                : styles.CanisterTrayRatioGraph__squareContainer
            }
          >
            <Square />
          </div>
          <p>{t("trupakDashboard:lastMonthLabel")}</p>
        </div>
        <div
          className={styles.CanisterTrayRatioGraph__timeRangeOption}
          onClick={fetchingData ? () => {} : () => onTimeRange("week")}
        >
          <div
            className={
              timeRange === "week"
                ? `${styles.CanisterTrayRatioGraph__squareContainer} ${styles["CanisterTrayRatioGraph__squareContainer--active"]}`
                : styles.CanisterTrayRatioGraph__squareContainer
            }
          >
            <Square />
          </div>
          <p>{t("trupakDashboard:lastWeekLabel")}</p>
        </div>
        <div
          className={styles.CanisterTrayRatioGraph__timeRangeOption}
          onClick={fetchingData ? () => {} : () => onTimeRange("day")}
        >
          <div
            className={
              timeRange === "day"
                ? `${styles.CanisterTrayRatioGraph__squareContainer} ${styles["CanisterTrayRatioGraph__squareContainer--active"]}`
                : styles.CanisterTrayRatioGraph__squareContainer
            }
          >
            <Square />
          </div>
          <p>{t("trupakDashboard:lastDayLabel")}</p>
        </div>
        <div
          className={styles.CanisterTrayRatioGraph__timeRangeOption}
          onClick={fetchingData ? () => {} : () => onTimeRange("12 hours")}
        >
          <div
            className={
              timeRange === "12 hours"
                ? `${styles.CanisterTrayRatioGraph__squareContainer} ${styles["CanisterTrayRatioGraph__squareContainer--active"]}`
                : styles.CanisterTrayRatioGraph__squareContainer
            }
          >
            <Square />
          </div>
          <p>{t("trupakDashboard:last12HoursLabel")}</p>
        </div>
      </div>
    </div>
  );
}

export default CanisterTrayRatioGraph;

import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ReactComponent as Square } from "../../../../../assets/square.svg";
import AntButton from "../../../../../components/Ant/AntButton/AntButton";
import styles from "./DispensedMedicationsTable.module.css";
import "./DispensedMedicationsTableAntStyles.css";

const DispensedMedicationsTableForCanisters = ({
  user,
  selectedDateRange,
  selectedDayCanister,
  canisterProductSelectionLookup,
  onCanisterProductSelectionLookup,
  canisterProductsSelectedDay,
  canisterProducts,
}) => {
  const LIMIT_NUM_PRODUCTS_HIGHEST_QTY = 25;
  const { t } = useTranslation("trupakDashboard");
  const [dataCanisterTable, setDataCanisterTable] = useState([]);
  const [allCanisterDrugsSelected, setAllCanisterDrugsSelected] =
    useState(false);
  const [atleastOneCanisterDrugSelected, setAtleastOneCanisterDrugSelected] =
    useState(false);
  const [tableTitle, setTableTitle] = useState("");
  const [canisterProductsFiltered, setCanisterProductsFiltered] = useState([]);
  const [
    canisterProductsSelectedDayFiltered,
    setCanisterProductsSelectedDayFiltered,
  ] = useState([]);

  const handleRowSelection = useCallback(
    (packageId) => {
      let tempRowStatus = { ...canisterProductSelectionLookup };
      if (tempRowStatus[packageId].selected === true) {
        tempRowStatus[packageId].selected = false;
        setAllCanisterDrugsSelected(false);
      } else {
        if (tempRowStatus[packageId].selected === false) {
          tempRowStatus[packageId].selected = true;
        }
      }
      onCanisterProductSelectionLookup(tempRowStatus);
    },
    [onCanisterProductSelectionLookup, canisterProductSelectionLookup]
  );

  useEffect(() => {
    setCanisterProductsFiltered(
      canisterProducts.filter((product, index) => {
        if (index < LIMIT_NUM_PRODUCTS_HIGHEST_QTY) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [canisterProducts]);

  useEffect(() => {
    setCanisterProductsSelectedDayFiltered(
      canisterProductsSelectedDay.filter((product, index) => {
        if (index < LIMIT_NUM_PRODUCTS_HIGHEST_QTY) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [canisterProductsSelectedDay]);

  useEffect(() => {
    setAllCanisterDrugsSelected(false);
    setAtleastOneCanisterDrugSelected(false);
  }, [selectedDayCanister]);

  useEffect(() => {
    if (selectedDayCanister !== null) {
    }
  }, []);

  useEffect(() => {
    const hasKey = (obj) => {
      for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
          return true;
        }
      }
      return false;
    };
    if (hasKey(canisterProductSelectionLookup)) {
      const createIDSquare = (id) => {
        const IDSquare = (
          <div
            className={
              canisterProductSelectionLookup[id]?.selected === true
                ? `${styles.DispensedMedicationsTable__idSquareContainer} ${styles["DispensedMedicationsTable__idSquareContainer--active"]}`
                : styles.DispensedMedicationsTable__idSquareContainer
            }
            onClick={() => handleRowSelection(id)}
          >
            <Square width="24px" height="24px" />
          </div>
        );
        return IDSquare;
      };

      let canisterProds;
      if (selectedDayCanister !== null) {
        canisterProds = canisterProductsSelectedDayFiltered;
      } else {
        canisterProds = canisterProductsFiltered;
      }

      setDataCanisterTable(
        canisterProds.map((product) => {
          return {
            key: `${product.packageId}_canister`,
            idSquare: createIDSquare(product.packageId),
            packageId_canister: product.packageId,
            medication_canister: product.productName,
            quantity_canister: product.qty,
            refills: "--",
            canisterNumber: "--",
          };
        })
      );
    } else {
      setDataCanisterTable([]);
    }
  }, [
    canisterProductsFiltered,
    canisterProductsSelectedDayFiltered,
    canisterProductSelectionLookup,
    selectedDayCanister,
    handleRowSelection,
  ]);

  useEffect(() => {
    let drugSelected = false;
    for (const [key, value] of Object.entries(canisterProductSelectionLookup)) {
      if (value?.selected) {
        drugSelected = true;
        break;
      }
    }
    setAtleastOneCanisterDrugSelected(drugSelected);
  }, [canisterProductSelectionLookup]);

  useEffect(() => {
    let tempTableTitle = "";
    if (selectedDayCanister || selectedDayCanister === 0) {
      const selectedDate = new Date(selectedDateRange.startDate);
      selectedDate.setDate(selectedDate.getDate() + selectedDayCanister);
    }

    tempTableTitle = (
      <div className={styles.DispensedMedicationsTable__tableTitle}>
        <h2 className={styles.DispensedMedicationsTable__tableTitleMain}>
          {t("Most Dispensed")}
        </h2>
        <p className={styles.DispensedMedicationsTable__limitMessage}>
          ({`Top ${LIMIT_NUM_PRODUCTS_HIGHEST_QTY} By Qty Filled`})
        </p>
      </div>
    );

    setTableTitle(tempTableTitle);
  }, [selectedDayCanister, selectedDateRange, user, t]);

  const handleChange = (pagination, filters, sorter) => {
    // console.log("Various parameters", pagination, filters, sorter);
  };

  const canisterColumnsInfo = [
    {
      title: t("Drug ID"),
      dataIndex: "packageId_canister",
      key: "packageId_canister",
      sorter: (a, b) => {
        return a.packageId_canister.localeCompare(b.packageId_canister);
      },
      sortDirections: ["ascend", "descend"],
      width: "180px",
    },
    {
      title: t("Description"),
      dataIndex: "medication_canister",
      key: "medication_canister",
      sorter: (a, b) => {
        return a.medication_canister.localeCompare(b.medication_canister);
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      align: "right",
      title: t("QTY Filled"),
      dataIndex: "quantity_canister",
      key: "quantity_canister",
      sorter: (a, b) => a.quantity_canister - b.quantity_canister,
      sortDirections: ["ascend", "descend"],
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            console.log("row & cell info: ", record, rowIndex, ev);
          },
          style: { cursor: "pointer" },
        };
      },
      width: "175px",
    },
    {
      align: "right",
      title: t("Total Refills"),
      dataIndex: "refills",
      key: "refills",
      sorter: (a, b) => a.refills - b.refills,
      sortDirections: ["ascend", "descend"],
      width: "175px",
    },
    {
      align: "right",
      title: "Canister Number",
      dataIndex: "canisterNumber",
      key: "canisterNummber",
      sorter: (a, b) => a.canisterNumber - b.canisterNumber,
      sortDirections: ["ascend", "descend"],
      width: "195px",
    },
  ];

  const data = dataCanisterTable;
  const columnsInfo = canisterColumnsInfo;

  const columns = columnsInfo.map((column) => {
    return {
      title: column.title,
      dataIndex: column.dataIndex,
      key: column.key,
      sortColumnKey: column.key,
      width: column.width ? column.width : null,
      sorter: column.sorter ? column.sorter : null,
      ellipsis: true,
      onCell: column.onCell ? column.onCell : null,
    };
  });

  return (
    <div
      className={`${styles.DispensedMedicationsTable__tableContainer} DispensedMedicationsTable__AntStyle`}
    >
      {tableTitle}
      <div className="DispensedMedicationsTable__tableTypeCanisters">
        <Table
          columns={columns}
          dataSource={data}
          onChange={handleChange}
          scroll={{ y: `calc(70vh - 460px)` }}
          pagination={{ pageSize: 10 }}
        />
      </div>
      <div
        className={styles.DispensedMedicationsTable__calculationButtoncontainer}
      >
        <AntButton
          onClick={() => console.log("clicked")}
          text={t("Calculate")}
          buttonstyle="ant-button-004"
          size="large"
          disabled={!atleastOneCanisterDrugSelected}
        />
      </div>
    </div>
  );
};

export default DispensedMedicationsTableForCanisters;

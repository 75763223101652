import {
  VictoryBar,
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryScatter,
  VictoryLabel,
} from "victory";

import { useTranslation } from "react-i18next";
import { ReactComponent as BarChart } from "./svgs/barchart.svg";

import styles from "./BarLineGraph.module.css";
import "./BarLineGraph.css";

function BarLineGraph({
  user,
  fetchingData,
  graphData,
  graphTitle,
  palette,
  primarySeriesLabel,
  secondarySeriesLabel,
}) {
  // For Testing
  // const graphData = [
  //   { x: "1/23/25", y: 11, y1: 16.5 },
  //   { x: "1/24/25", y: 5, y1: 2.75 },
  //   { x: "1/25/25", y: 5, y1: 7.5 },
  // ];

  const { t } = useTranslation("graphsGeneral");

  const ticks = 5;

  // when ticks = 5, returns [0, 1, 2, 3, 4, 5]
  // or, using the npm package lodash, the same as const tickValues = _.range(6);
  const tickValues = Array.from({ length: ticks + 1 }, (_, i) => i);

  function getMaxValueInDataSet(dataSet) {
    let maxValue = -1;
    dataSet.forEach((num) => {
      if (num > maxValue) {
        maxValue = num;
      }
    });
    return Math.ceil(maxValue / ticks) * ticks;
  }

  const maxYValueForBarAxis = getMaxValueInDataSet(
    graphData.map((dataObj) => dataObj.y)
  );
  const maxYValueForLineAxis = getMaxValueInDataSet(
    graphData.map((dataObj) => dataObj.y1)
  );

  const yRange = [0, maxYValueForBarAxis];
  const y1Range = [0, maxYValueForLineAxis];

  const domain = { y: [0, ticks] };

  const tickFormatBar = (range) => (t) =>
    ((t * (range[1] - range[0])) / ticks).toFixed(1);

  const tickFormatLine = (range) => (t) =>
    `$${((t * (range[1] - range[0])) / ticks).toFixed(2)}`;

  const normalize = (range, props) => (datum) =>
    datum[props] / ((range[1] - range[0]) / ticks);

  const Legend = (
    <div className={styles.BarLineGraph__legendContainer}>
      <div className={styles.BarLineGraph__legendGroup}>
        <BarChart />
        <p>{primarySeriesLabel}</p>
      </div>
      <div className={styles.BarLineGraph__legendGroup}>
        <div className={styles.BarLineGraph__line}></div>
        <p>{secondarySeriesLabel}</p>
      </div>
    </div>
  );

  let xValueNoGraphDataFound = 176;
  let yValueNoGraphDataFound = 124;

  if (user?.language?.type === "es") {
    xValueNoGraphDataFound = 138;
  } else if (user?.language?.type === "fr") {
    xValueNoGraphDataFound = 140;
  }

  return (
    <div className={styles.BarLineGraph__container}>
      <h2>{graphTitle}</h2>
      <VictoryChart
        theme={VictoryTheme.material}
        width={450}
        height={266}
        domain={domain}
        domainPadding={{ x: 60, y: 0 }}
        padding={{
          top: 30,
          bottom: 50,
          left: 46,
          right: 68,
        }}
      >
        {!graphData.length && (
          <VictoryLabel
            x={xValueNoGraphDataFound}
            y={yValueNoGraphDataFound}
            style={{
              fontFamily: "inherit",
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: "normal",
              fill: "	#909090",
            }}
            text={fetchingData ? "" : t("noData")}
          />
        )}
        {!!graphData.length && (
          <VictoryAxis
            crossAxis={false}
            style={{
              axis: { stroke: "#909090", strokeWidth: 1 },
              ticks: {
                stroke: "#909090",
                strokeWidth: 1,
              },
              grid: {
                stroke: "transparent",
              },
              tickLabels: {
                fill: "#909090",
                fontFamily: "inherit",
                fontSize: 12,
              },
            }}
          />
        )}

        <VictoryAxis
          dependentAxis
          orientation="left"
          tickValues={tickValues}
          tickFormat={tickFormatBar(yRange)}
          style={{
            axis: {
              stroke: graphData.length ? "#909090" : "transparent",
              strokeWidth: 1,
            },
            // grid: {
            //   stroke: "#CFD8DC",
            //   strokeDasharray: "0, 0",
            // },
            ticks: {
              stroke: graphData.length ? "#909090" : "transparent",
            },
            tickLabels: {
              fill: graphData.length ? "#909090" : "transparent",
              fontFamily: "inherit",
              fontSize: 12,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          // tickFormat={(tick) => `$${tick}`}
          orientation="right"
          tickValues={tickValues}
          tickFormat={tickFormatLine(y1Range)}
          style={{
            axis: {
              stroke: graphData.length ? "#909090" : "transparent",
              strokeWidth: 1,
            },
            ticks: {
              stroke: graphData.length ? "#909090" : "transparent",
            },
            tickLabels: {
              fill: graphData.length ? "#909090" : "transparent",
              fontFamily: "inherit",
              fontSize: 12,
            },
          }}
        />
        <VictoryBar
          data={graphData}
          barRatio={graphData.length > 1 ? 0.8 : 1}
          barWidth={graphData.length === 1 ? 80 : null}
          y={normalize(yRange, "y")}
          style={{
            data: {
              stroke: palette[0],
              fill: palette[0],
            },
          }}
        />
        <VictoryLine
          data={graphData}
          y={normalize(y1Range, "y1")}
          style={{
            data: {
              stroke: palette[1],
            },
          }}
        />

        <VictoryScatter
          size={2}
          data={graphData}
          style={{
            data: {
              fill: "#318ce7",
              stroke: "black",
              strokeOpacity: 0.5,
              strokeWidth: 1,
            },
          }}
          y={normalize(y1Range, "y1")}
          //   domain={{
          //     y: [0.0, maxYValueInGetDataSet2],
          //   }}
          dosize={5}
          standalone={false}
        />
      </VictoryChart>
      {Legend}
    </div>
  );
}
export default BarLineGraph;

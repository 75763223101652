import { Col, Input, Modal, Row, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClientFDBService from "../../services/ClientFDBService";

const { Search } = Input;

const ProductsCheckForUpdatesModal = ({
  user,
  open,
  onCancel,
  onOk,
  onError,
}) => {
  const { t } = useTranslation("products");

  const [fdbModifications, setFdbModifications] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [selectedFdbModificationsKey, setSelectedFdbModificationsKey] =
    useState([]);
  const [fdbModificationFilterText, setFdbModificationFilterText] =
    useState("");
  const [fdbModificationsLoading, setFdbModificationsLoading] = useState(false);

  const handleFdbModificationFilterTextChange = (value) => {
    setFdbModificationFilterText(value);
    if (!value) {
      setTableDataSource(fdbModifications);
    } else {
      const temp = fdbModifications.filter(
        (x) =>
          x.packagedDrugID === value ||
          x.packagedDrugDesc.toLowerCase().includes(value.toLowerCase())
      );
      setTableDataSource(temp);
    }
  };

  const getFdbModifications = async (token) => {
    try {
      setFdbModificationsLoading(true);
      setFdbModifications([]);
      const result = await ClientFDBService.getFdbModifications(user, token);
      setFdbModifications(result.modifications);
      setTableDataSource(result.modifications);
    } catch (error) {
      if (token.reason.code !== "ERR_CANCELED") {
        onError(error);
      }
    } finally {
      setFdbModificationsLoading(false);
    }
  };

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();

    if (open === true) {
      const fetchData = async () => {
        if (open) {
          await getFdbModifications(tokenSource.token);
        } else {
          setTableDataSource([]);
        }
      };
      fetchData();
    }

    return () => {
      tokenSource.cancel();
    };
  }, [open]);

  const handleUpdateProducts = async (e) => {
    try {
      setFdbModificationsLoading(true);
      const packageIds = selectedFdbModificationsKey;
      await ClientFDBService.updateProductsByNdc(user, packageIds);
      onOk(e);
    } catch (error) {
      onError(error);
    } finally {
      setFdbModificationsLoading(false);
    }
  };

  return (
    <Modal
      width={1024}
      height={768}
      maxwidth={1024}
      maxheight={768}
      title={"Check for updates"}
      open={open}
      onCancel={onCancel}
      onOk={handleUpdateProducts}
      okText={"Update"}
      okButtonProps={{ disabled: selectedFdbModificationsKey.length === 0 }}
    >
      <Row>
        <Col span={24}>
          <Search
            placeholder={"Search"}
            value={fdbModificationFilterText}
            allowClear={true}
            disabled={fdbModificationsLoading}
            onChange={async (e) => {
              const value = e.target.value;
              handleFdbModificationFilterTextChange(value);
            }}
          />
        </Col>
        <Col span={24}>
          <Table
            columns={[
              {
                title: "Drug ID",
                render: (p) => p.packagedDrugID,
                ellipsis: true,
                width: 120,
              },
              {
                title: "Drug name",
                render: (p) => p.packagedDrugDesc,
                ellipsis: true,
              },
            ]}
            rowKey={(record) => record.packagedDrugID}
            dataSource={[...tableDataSource].sort((a, b) => {
              return a.packagedDrugDesc.localeCompare(b.packagedDrugDesc);
            })}
            pagination={{ defaultPageSize: 50 }}
            loading={fdbModificationsLoading}
            rowSelection={{
              selectedRowKeys: selectedFdbModificationsKey,
              onChange: (newSelectedRowKeys) => {
                setSelectedFdbModificationsKey(newSelectedRowKeys);
              },
            }}
            scroll={{ y: 500 }}
            expandable={{
              expandedRowRender: (record) => (
                <Table
                  columns={[
                    {
                      title: "Field Name",
                      dataIndex: "fieldName",
                      key: "fieldName",
                    },
                    {
                      title: "From",
                      dataIndex: "from",
                      key: "from",
                    },
                    {
                      title: "To",
                      dataIndex: "to",
                      key: "to",
                    },
                  ]}
                  dataSource={record.modifications}
                  pagination={false}
                  size="small"
                  rowKey="from"
                />
              ),
              rowExpandable: (record) => record.modifications.length > 0,
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ProductsCheckForUpdatesModal;

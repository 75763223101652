import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { CSVLink } from "react-csv";
import { message, Upload, Tooltip } from "antd";
import { deepCopy } from "../../../functions/utilities.js";
import AntButton from "../../../components/Ant/AntButton/AntButton";
import TableContent from "./TableContent";
import ApiService from "../../../services/ApiService";
import { ReactComponent as CreateEmptyRowIcon } from "../../../assets/add-circle-outline.svg";
import OrderGeneratorController from "./OrderGeneratorController";
import styles from "./ResizableTable.module.css";
import "./ResizableTableAntStyles.css";

const ResizableTable = ({
  site,
  user,
  selectedDevice,
  headers,
  inputTypes,
  startingRelativeColumnWidths,
  minColumnWidths,
  dataValidationChecks,
  onUploadCSVFileError,
  onBatchOrderSuccessfullyCreated,
  onGeneratingBatchOrder,
  productSelectionObject,
  onProductSelectionObject,
  selectedPackageId,
  resetProductSelectionObjectToDefault,
}) => {
  const WIDTH_UTILITIES_COLUMN = 68; // last column
  const TABLE_WIDTH_ADJUST_VALUE = 330;

  const _controllerRef = useRef(OrderGeneratorController);

  const refsById = useMemo(() => {
    const refs = {};
    headers.forEach((header, index) => {
      refs[index] = React.createRef();
    });
    return refs;
  }, [headers]);

  const createHeaders = (headers) => {
    return headers.map((item, index) => ({
      text: item,
      ref: refsById[index],
    }));
  };

  const [tableHeight, setTableHeight] = useState("auto");
  const [activeIndex, setActiveIndex] = useState(null);
  const tableElement = useRef(null);
  const [columnWidths, setColumnWidths] = useState([...minColumnWidths, 60]);

  //initial value - auto adjusted based on width of window
  const [tableMaxWidth, setTableMaxWidth] = useState(1000);

  const [resetColumnWidthsToggle, setResetColumnWidthsToggle] = useState(false);
  const [nameOfCsvFileUploaded, setNameOfCsvFileUploaded] = useState("");

  const createInitialCSSGridTemplateColumns = useCallback(() => {
    return columnWidths.map((columnWidth) => `${columnWidth}px`).join(" ");
  }, [columnWidths]);

  const [gridTemplateColumns_CSS, setGridTemplateColumns_CSS] = useState(
    createInitialCSSGridTemplateColumns
  );

  useEffect(() => {
    setGridTemplateColumns_CSS(createInitialCSSGridTemplateColumns());
  }, [columnWidths, createInitialCSSGridTemplateColumns]);

  let emptyOrderDataRow = {};
  const [orderData, setOrderData] = useState([emptyOrderDataRow]);
  const [errorData, setErrorData] = useState([]);
  const [dataValidated, setDataValidated] = useState(false);
  const [activeField, setActiveField] = useState({ row: 0, column: 0 });
  const [viewWidth, setViewWidth] = useState(0);
  const [multiFacilitySelectionViolation, setMultiFacilitySelectionViolation] =
    useState(false);

  const columns = createHeaders(headers);

  useEffect(() => {
    if (
      productSelectionObject.column === 3 &&
      !productSelectionObject.show &&
      selectedPackageId
    ) {
      let tempOrderData = [...orderData];
      tempOrderData[productSelectionObject.row]["packageId"] =
        selectedPackageId;
      setOrderData(tempOrderData);
      resetProductSelectionObjectToDefault({
        row: -1,
        column: -1,
        show: false,
      });
    }
  }, [
    productSelectionObject,
    selectedPackageId,
    orderData,
    resetProductSelectionObjectToDefault,
  ]);

  useEffect(() => {
    // Data validity checks
    let tempErrorData = [];
    let tempDataValidated = true;
    if (orderData.length) {
      orderData.forEach((dataObject, orderDataRowIndex) => {
        let tempDataErrorObject = {};
        for (let [key, value] of Object.entries(dataObject)) {
          if (key !== "delete" && !(key === "rxNum")) {
            // A valid check (true) assigns false to error, and vice versa
            tempDataErrorObject[key] = !dataValidationChecks(key, value);
            if (tempDataErrorObject[key] === true) {
              tempDataValidated = false;
            }
          } else if (
            key !== "delete" &&
            key === "rxNum" &&
            !tempDataErrorObject[key]
          ) {
            tempDataErrorObject[key] = !dataValidationChecks(key, value);
            if (tempDataErrorObject[key] === true) {
              tempDataValidated = false;
            }
          }
        }

        tempErrorData.push(tempDataErrorObject);
      });
    }

    if (orderData.length) {
      orderData.forEach((dataObject, orderDataRowIndex) => {
        tempErrorData.forEach(() => {
          if (orderData && orderData.length && "rxNum" in orderData[0]) {
            for (let j = 0; j < orderData.length; j++) {
              if (
                dataObject["rxNum"] &&
                dataObject["rxNum"] === orderData[j]["rxNum"] &&
                dataObject["packageId"] !== orderData[j]["packageId"]
              ) {
                if (orderDataRowIndex !== j) {
                  tempErrorData[orderDataRowIndex]["rxNum"] = true;
                  tempDataValidated = false;
                  break;
                }
              } else {
                if (dataObject["rxNum"] && orderDataRowIndex !== j) {
                  tempErrorData[orderDataRowIndex]["rxNum"] = false;
                }
              }
            }
          }
        });
      });
    }

    if (tempErrorData.length) {
      setErrorData(tempErrorData);
      setDataValidated(tempDataValidated);
    }
  }, [orderData, headers, dataValidationChecks]);

  useEffect(() => {
    let tempMultiFacilitySelectionViolation = false;
    if (
      !site?.multiFacilityBatchSplit &&
      orderData.length &&
      orderData[0] &&
      orderData[0]["facility"]
    ) {
      for (let i = 1; i < orderData.length; i++) {
        if (orderData[i]["facility"] !== orderData[0]["facility"]) {
          tempMultiFacilitySelectionViolation = true;
          setDataValidated(true);
          // break;
        }
      }
      setMultiFacilitySelectionViolation(tempMultiFacilitySelectionViolation);
    }
  }, [user, orderData, site?.multiFacilityBatchSplit]);

  useEffect(() => {
    if (!viewWidth) {
      setViewWidth(window.innerWidth);
    }

    window.addEventListener("resize", () => {
      setViewWidth(window.innerWidth);
    });
    return () =>
      window.removeEventListener("resize", () => {
        setViewWidth(window.innerWidth);
      });
  }, []);

  useEffect(() => {
    if (viewWidth) {
      const availableWidth =
        viewWidth - WIDTH_UTILITIES_COLUMN - TABLE_WIDTH_ADJUST_VALUE;
      setTableMaxWidth(viewWidth - 290);
      // map over headers instead of columnWidths to prevent infinite trigger loop
      let tempColumnWidths = [...headers].map((header, columnWidthsIndex) => {
        if (columnWidthsIndex !== headers.length - 1) {
          const columnWidth =
            (availableWidth * startingRelativeColumnWidths[columnWidthsIndex]) /
            100;
          if (columnWidth >= minColumnWidths[columnWidthsIndex]) {
            return columnWidth;
          } else {
            return minColumnWidths[columnWidthsIndex];
          }
        } else {
          return WIDTH_UTILITIES_COLUMN;
        }
      });
      setColumnWidths(tempColumnWidths);
    }
  }, [
    viewWidth,
    headers,
    startingRelativeColumnWidths,
    minColumnWidths,
    resetColumnWidthsToggle,
  ]);

  for (const value of headers) {
    emptyOrderDataRow[value] = "";
  }

  const handleColumnWidths = (arr) => {
    setColumnWidths(arr);
  };

  const stripAllWhitespace = (value) => {
    if (typeof value === "string") {
      return value.replace(/\s+/g, "");
    }
    return value;
  };

  const handleFieldValueChange = (valueObj, rowIndex, columnKey) => {
    const tempRow = {
      ...orderData[rowIndex],
    };
    if (valueObj?.target && columnKey === "packageId") {
      // for inputs
      tempRow[columnKey] = stripAllWhitespace(valueObj.target.value);
    } else if (valueObj?.target) {
      tempRow[columnKey] = valueObj.target.value;
    } else {
      // for dropdown selection
      tempRow[columnKey] = valueObj;
    }

    const tempOrderData = [...orderData];
    tempOrderData[rowIndex] = tempRow;
    setOrderData(tempOrderData);
  };

  const handleFieldClick = (rowIndex, columnIndex, e) => {
    if (activeField.row !== rowIndex || activeField.column !== columnIndex) {
      setActiveField({ row: rowIndex, column: columnIndex });
    }
  };

  const handleCreateEmptyRowAtBottom = () => {
    setOrderData((prevState) => {
      return [...prevState, emptyOrderDataRow];
    });
  };

  const onKeyDown = (e) => {
    if (e.key === "Tab" || e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
    }

    if (e.shiftKey && e.key === "Tab") {
      if (activeField.column > 0) {
        setActiveField((prevState) => {
          return { row: prevState.row, column: prevState.column - 1 };
        });
      } else {
        setActiveField((prevState) => {
          return { row: prevState.row, column: columnWidths.length - 2 };
        });
      }
    } else if (
      !e.shiftKey &&
      activeField.column < Object.keys(orderData[0]).length - 1 &&
      e.key === "Tab"
    ) {
      if (activeField.column < columnWidths.length - 2) {
        setActiveField((prevState) => {
          return { row: prevState.row, column: prevState.column + 1 };
        });
      } else {
        setActiveField((prevState) => {
          return { row: prevState.row, column: 0 };
        });
      }
    } else if (e.shiftKey && e.key === "Enter") {
      handleCreateEmptyRowAtBottom();
      setActiveField({ row: orderData.length, column: 0 });
    } else if (
      activeField.row < orderData.length - 1 &&
      e.key === "ArrowDown"
    ) {
      setActiveField((prevState) => {
        return { row: prevState.row + 1, column: prevState.column };
      });
    } else if (activeField.row > 0 && e.key === "ArrowUp") {
      setActiveField((prevState) => {
        return { row: prevState.row - 1, column: prevState.column };
      });
    }
  };

  const handleCreateAdjacentEmptyRow = (rowIndex) => {
    const tempOrderData = [
      ...orderData.slice(0, rowIndex + 1),
      emptyOrderDataRow,
      ...orderData.slice(rowIndex + 1),
    ];
    setOrderData(tempOrderData);
  };

  const handleCreateAdjacentFilledRow = (rowIndex) => {
    const tempOrderData = [
      ...orderData.slice(0, rowIndex + 1),
      { ...orderData[rowIndex] },
      ...orderData.slice(rowIndex + 1),
    ];
    setOrderData(tempOrderData);
  };

  const handleDeleteRow = (rowIndex) => {
    const tempOrderData = [...orderData];
    if (rowIndex > 0) {
      tempOrderData.splice(rowIndex, 1);
    } else if (orderData.length > 1 && rowIndex === 0) {
      tempOrderData.shift();
    }
    setOrderData(tempOrderData);
  };

  // const handleStoreOrderDataToLocalStorage = () => {
  //   localStorage.orderData = JSON.stringify(orderData);
  // };

  // const handleGetOrderDataFromLocalStorage = () => {
  //   if (localStorage?.orderData) {
  //     const storedOrderData = JSON.parse(localStorage.orderData);
  //     handleOrderData(storedOrderData);
  //     setNameOfCsvFileUploaded("");
  //   } else {
  //     // console.log("No data found");
  //   }
  // };

  // const handleRemoveOrderDataFromLocalStorage = () => {
  //   if (localStorage?.orderData) {
  //     localStorage.removeItem("orderData");
  //   }
  // };

  const handleResetTable = () => {
    setNameOfCsvFileUploaded("");
    setOrderData([emptyOrderDataRow]);
    onUploadCSVFileError("");
    setMultiFacilitySelectionViolation(false);
  };

  const handleResetColumnWidthsToggle = () => {
    setResetColumnWidthsToggle((prevState) => {
      return !prevState;
    });
  };

  // Antd Design Upload
  let url = "";
  url = `${ApiService.BASE_URL}/accounts/${
    user.account._id || user.account
  }/sites/${site._id}/stock-locations/${
    selectedDevice._id
  }/order-generator/csv-to-json`;

  const props = {
    name: "file",
    multiple: false,
    action: url,

    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setNameOfCsvFileUploaded("");
      }
      if (status === "done") {
        // console.log("info.file.response: ", info.file.response);
        message.success(`${info.file.name} file uploaded successfully.`);

        const uploadedData = info.file.response;
        let fileStructureError = false;
        let headersIndex = 0;

        try {
          const keysArray = Object.keys(uploadedData[0]);
          const keysCount = keysArray.length;

          if (keysCount !== headers.length - 1) {
            fileStructureError = true;
            onUploadCSVFileError(
              "Number of CSV file columns do not match number of batch table columns"
            );
            throw new Error("Number of file columns are incorrect");
          }

          for (const [key] of Object.entries(uploadedData[0])) {
            if (key !== headers[headersIndex]) {
              fileStructureError = true;
              onUploadCSVFileError(
                "At least one header mismatch between CSV file and batch table"
              );
              throw new Error("File header mismatch error");
            } else {
              headersIndex++;
            }
          }
          if (!fileStructureError) {
            const uploadedDataWithDeleteColumnAdded = uploadedData.map(
              (dataObject) => {
                return { ...dataObject, delete: "" };
              }
            );
            onUploadCSVFileError("");
            setOrderData(uploadedDataWithDeleteColumnAdded);
            setNameOfCsvFileUploaded(info.file.name);
          }
        } catch (err) {
          setOrderData([emptyOrderDataRow]);
          setErrorData([]);
          console.log("err: ", err);
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleProcessOrder = async () => {
    onGeneratingBatchOrder(true);
    const unixTimeStampSeconds = Math.floor(Date.now() / 1000);
    const batchOrder = orderData.map((dataObject, index) => {
      const {
        lastName,
        firstName,
        patientId,
        packageId,
        rxNum,
        qty,
        date,
        time,
        facility,
        location,
        instructions,
        room,
        bed,
        physician,
        pharmacist,
        ...rest
      } = dataObject;

      let batchOrderObject = {
        PatientFirstName: firstName,
        PatientLastName: lastName,
        PatientID: patientId,
        facilityId: facility,
        NDC: packageId,
        AdminDate: date,
        AdminTime: time,
        PatientUnit: location,
        PatientRoom: room,
        PatientBed: bed,
        Quantity: qty,
        DoctorName: physician,
        RxNumber: rxNum,
        Instructions: instructions,
        PharmacistName: pharmacist,
        ...rest,
      };

      let quantity = dataObject.qty;

      if (!quantity.includes(".")) {
        quantity = quantity + ".0";
      }

      delete batchOrderObject.delete;

      batchOrderObject.Quantity = parseFloat(quantity);
      batchOrderObject.ProductDescription = `Product Description ${index + 1}`;
      batchOrderObject.batchId = `${facility}_${unixTimeStampSeconds}`;
      batchOrderObject.fileName = `filename ${index + 1}`;
      batchOrderObject.companyName = user.account.companyName;
      batchOrderObject.siteName = site.name;

      return batchOrderObject;
    });

    await _controllerRef.current
      .generateOrders(user, site, selectedDevice, batchOrder, "orderGenerator")
      .then((message) => {
        if (message === "Batch order created") {
          onBatchOrderSuccessfullyCreated(true);
          onGeneratingBatchOrder(false);
        }
      });
  };

  useEffect(() => {
    setTableHeight(tableElement?.current?.offsetHeight);
  }, []);

  const mouseDown = (index) => {
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
    (e) => {
      const gridColumns = columns.map((col, i) => {
        if (i === activeIndex) {
          const width = e.clientX - refsById[i].current.offsetLeft - 120;

          if (width >= minColumnWidths[i]) {
            return `${width}px`;
          }
        }
        return `${refsById[i].current.offsetWidth}px`;
      });

      const inputFieldWidths = columns.map((col, i) => {
        if (i === activeIndex) {
          const width = e.clientX - refsById[i].current.offsetLeft - 120;

          if (width >= minColumnWidths[i]) {
            return width;
          }
        }
        return refsById[i].current.offsetWidth;
      });

      handleColumnWidths(inputFieldWidths);

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(
        " "
      )}`;
    },
    [activeIndex, columns, minColumnWidths, refsById]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const Content = (
    <TableContent
      user={user}
      orderData={orderData}
      errorData={errorData}
      headers={headers}
      onFieldValueChange={handleFieldValueChange}
      onFieldClick={handleFieldClick}
      onKeyDown={onKeyDown}
      activeField={activeField}
      onDeleteRow={handleDeleteRow}
      onCreateAdjacentEmptyRow={handleCreateAdjacentEmptyRow}
      onCreateAdjacentFilledRow={handleCreateAdjacentFilledRow}
      columnWidths={columnWidths}
      inputTypes={inputTypes}
      multiFacilitySelectionViolation={multiFacilitySelectionViolation}
      onProductSelectionObject={onProductSelectionObject}
    />
  );

  const orderDataForCSV = deepCopy(orderData).map((dataObject) => {
    delete dataObject.delete;
    return dataObject;
  });

  return (
    <div
      className={styles.ResizableTable__tableContainer}
      // style={{ width: `${totalColumnsWidth}px` }}
    >
      <div className={styles.ResizableTable__utilities}>
        <div className={styles.ResizableTable__utilitiesButtonsGroup}>
          <header className={styles.ResizableTable__buttonsHeader}>
            <h2>Utilities</h2>
          </header>
          <div className={styles.ResizableTable__localStorageButtonsContainer}>
            <AntButton
              onClick={handleResetTable}
              text="Clear Table"
              buttonstyle="ant-button-000"
            />
            <AntButton
              onClick={handleResetColumnWidthsToggle}
              text="Reset Widths"
              buttonstyle="ant-button-000"
            />
          </div>
        </div>

        {/* <div className={styles.ResizableTable__localStorageButtonsGroup}>
          <header className={styles.ResizableTable__buttonsHeader}>
            <h2>Local Storage</h2>
          </header>
          <div className={styles.ResizableTable__localStorageButtonsContainer}>
            <AntButton
              onClick={handleGetOrderDataFromLocalStorage}
              text="Retrieve Data"
              buttonstyle="ant-button-000"
            />
            <AntButton
              onClick={handleStoreOrderDataToLocalStorage}
              text="Save Data"
              buttonstyle="ant-button-000"
            />
            <AntButton
              onClick={handleRemoveOrderDataFromLocalStorage}
              text="Clear Data"
              buttonstyle="ant-button-000"
            />
          </div>
        </div> */}

        <div className={styles.ResizableTable__ExcelButtonsGroup}>
          <header
            className={`${styles.ResizableTable__buttonsHeader} ${styles["ResizableTable__buttonsHeader--excel"]}`}
          >
            <h2>CSV</h2>
          </header>
          <div className={styles.ResizableTable__ExcelButtonsContainer}>
            <div className={styles.ResizableTable__uploadCsvFileGroup}>
              <div className="ResizableTable__antUploadContainer">
                <Upload {...props}>
                  <AntButton text="Upload CSV" buttonstyle="ant-button-000" />
                </Upload>
              </div>
              <p className={styles.ResizableTable__NameOfCsvFileUploaded}>
                {nameOfCsvFileUploaded}
              </p>
            </div>
            <div className={styles.ResizableTable__ExcelExportButtonContainer}>
              <CSVLink data={orderDataForCSV} filename={"orderData.csv"}>
                <AntButton text="Export CSV" buttonstyle="ant-button-000" />
              </CSVLink>
            </div>
          </div>
        </div>
        <div
          className={styles.ResizableTable__createEmptyRowIconContainer}
          onClick={handleCreateEmptyRowAtBottom}
        >
          <div className={styles.ResizableTable__createEmptyRowIconContainer}>
            <Tooltip title="Add empty row to bottom of table">
              <CreateEmptyRowIcon />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.ResizableTable__wrapper}>
        {multiFacilitySelectionViolation && (
          <p className={styles.ResizableTable__multiSiteError}>
            This site is not designated for multi-facility batch splitting. Only
            one facility can be selected.
          </p>
        )}
        <table
          className={styles.ResizableTable__table}
          ref={tableElement}
          style={{
            gridTemplateColumns: gridTemplateColumns_CSS,
            maxWidth: tableMaxWidth,
            overflowY: orderData.length === 1 ? "hidden" : "auto",
            maxHeight: orderData.length === 1 ? "83px" : "100%",
          }}
        >
          <thead>
            <tr>
              {columns.map(({ ref, text }, i) => (
                <th ref={refsById[i]} key={text}>
                  <div>{text}</div>
                  <div
                    style={{ height: tableHeight }}
                    onMouseDown={() => mouseDown(i)}
                    className={
                      activeIndex === i
                        ? `${styles.ResizableTable__resizeHandle} ${styles["ResizableTable__resizeHandle--active"]}`
                        : styles.ResizableTable__resizeHandle
                    }
                  />
                </th>
              ))}
            </tr>
          </thead>
          {Content}
        </table>
      </div>
      <div className={` ${styles["ResizableTable__utilities--bottom"]}`}>
        <AntButton
          onClick={handleProcessOrder}
          text="Process Order"
          buttonstyle="ant-button-001"
          disabled={!dataValidated || multiFacilitySelectionViolation}
        />
      </div>
    </div>
  );
};

export default ResizableTable;

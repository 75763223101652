export const getPouchDataMapping = () => {
  return [
    {
      mappingDisplayName: 'Default Value',
      mappingKey: '',
    },
    {
      mappingDisplayName: 'Pouch Barcode',
      mappingKey: 'POUCH_BARCODE',
    },
    {
      mappingDisplayName: 'Pouch Type',
      mappingKey: 'POUCH_TYPE',
    },
    {
      mappingDisplayName: 'Batch Id',
      mappingKey: 'BATCH_ID',
    },
    {
      mappingDisplayName: 'Bag Number',
      mappingKey: 'BAG_NUMBER',
    },
    {
      mappingDisplayName: 'Reverse Bag Number',
      mappingKey: 'REVERSE_BAG_NUMBER',
    },
    {
      mappingDisplayName: 'Patient Id',
      mappingKey: 'PATIENT_ID',
    },
    {
      mappingDisplayName: 'Patient First Name',
      mappingKey: 'PATIENT_FIRST_NAME',
    },
    {
      mappingDisplayName: 'Patient Last Name',
      mappingKey: 'PATIENT_LAST_NAME',
    },
    {
      mappingDisplayName: 'Pouch Lot',
      mappingKey: 'POUCH_LOT',
    },
    {
      mappingDisplayName: 'Pouch Exp',
      mappingKey: 'POUCH_EXP',
    },
    {
      mappingDisplayName: 'Product Package Id',
      mappingKey: 'PRODUCT_PACKAGE_ID',
    },
    {
      mappingDisplayName: 'Product Id',
      mappingKey: 'PRODUCT_ID',
    },
    {
      mappingDisplayName: 'Product Name',
      mappingKey: 'PRODUCT_NAME',
    },
    {
      mappingDisplayName: 'Product Description',
      mappingKey: 'PRODUCT_DESCRIPTION',
    },
    {
      mappingDisplayName: 'Qty Requested',
      mappingKey: 'QTY_REQUESTED',
    },
    {
      mappingDisplayName: 'Qty Filled',
      mappingKey: 'QTY_FILLED',
    },
    {
      mappingDisplayName: 'Admin Date',
      mappingKey: 'ADMIN_DATE',
    },
    {
      mappingDisplayName: 'Admin Time',
      mappingKey: 'ADMIN_TIME',
    },
    {
      mappingDisplayName: 'Current Date',
      mappingKey: 'CURRENT_DATE',
    },
    {
      mappingDisplayName: 'Current Time',
      mappingKey: 'CURRENT_TIME',
    },
    {
      mappingDisplayName: 'Facility Name',
      mappingKey: 'FACILITY_NAME',
    },
    {
      mappingDisplayName: 'Facility Id',
      mappingKey: 'FACILITY_ID',
    },
    {
      mappingDisplayName: 'Patient Room',
      mappingKey: 'PATIENT_ROOM',
    },
    {
      mappingDisplayName: 'Unique Bag Id',
      mappingKey: 'UNIQUE_BAG_ID',
    },
    {
      mappingDisplayName: 'Time Code Label',
      mappingKey: 'TIME_CODE_LABEL',
    },
    {
      mappingDisplayName: 'Product Primary Name',
      mappingKey: 'PRODUCT_PRIMARY_NAME',
    },
    {
      mappingDisplayName: 'Product Strength',
      mappingKey: 'PRODUCT_STRENGTH',
    },
    {
      mappingDisplayName: 'Product Dosage Form',
      mappingKey: 'PRODUCT_DOSAGE_FORM',
    },
    {
      mappingDisplayName: 'Rx Number',
      mappingKey: 'RX_NUMBER',
    },
    {
      mappingDisplayName: 'Pharmacist',
      mappingKey: 'PHARMACIST',
    },
    {
      mappingDisplayName: 'Doctor',
      mappingKey: 'DOCTOR',
    },
    {
      mappingDisplayName: 'Total Bag Count',
      mappingKey: 'TOTAL_BAG_COUNT',
    },
    {
      mappingDisplayName: 'Unique Bag Number',
      mappingKey: 'UNIQUE_BAG_NUMBER',
    }
  ];
}

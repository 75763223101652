import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as SearchIcon } from "../../assets/search-icon.svg";
import Accordion from "../../components/Accordion/Accordion";
import Checkbox from "../../components/Checkbox";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import ListBox from "../../components/ListBox";
import OptionGroup from "../../components/OptionGroup";
import TextArea from "../../components/TextArea";
import TextInput from "../../components/TextInput";
import { ScreenContext } from "../../contexts/ScreenContext";
import ProductController from "../../controllers/ProductController";
import ConfigService from "../../services/ConfigService";
import styles from "./CreateProductAccordion.module.css";

const CreateProductGeneral = ({
  isNew,
  p,
  errors,
  onPropertyChange,
  onHandleNDCLookup,
  onError,
  isCopy,
  user,
  dosageFormList,
}) => {
  const { t } = useTranslation("createProductGeneral");
  const productControllerRef = useRef(null);

  const [routeList, setRouteList] = useState([]);
  const [controlLevelList, setControlLevelList] = useState([
    {
      type: 0,
      description: "Not Assigned",
      displayName: t("Not Assigned", { ns: "controlLevels" }),
    },
  ]);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    productControllerRef.current = new ProductController();
  }, []);

  useEffect(() => {
    ConfigService.routes()
      .then((routes) => {
        routes.forEach((obj) => {
          obj.abbrName = t(obj.type, { ns: "routes" });
          obj.fullName = t(`${obj.type} Name`, { ns: "routes" });
          obj.description = t(`${obj.type} Description`, { ns: "routes" });
        });

        const routesWithBlankOption = [
          { type: " ", description: " " },
          ...routes,
        ];
        setRouteList(routesWithBlankOption);
      })
      .catch((error) => {
        onError(error);
      });
  }, []);

  // Get Control Levels
  useEffect(() => {
    const userCountry = user.defaultSite?.shippingAddress?.country || "";
    const userCountryCode =
      {
        // TODO revisit after updating Address country strategy
        "United States": "us",
        Canada: "ca",
      }[userCountry] || "us";

    ConfigService.controlLevels(userCountryCode)
      .then((controlLevels) => {
        controlLevels.forEach((obj) => {
          obj.displayName = t(obj.description, { ns: "controlLevels" });
        });
        setControlLevelList(controlLevels);
        if (isNew && !p?.controlLevel) {
          onPropertyChange({
            controlLevel: {
              type: controlLevelList[0].type,
              description: controlLevelList[0].description,
            },
          });
        }
      })
      .catch((error) => {
        onError(error);
      });
  }, [isNew, p]);

  useEffect(() => {
    if (isNew) {
      onPropertyChange({ legend: { type: "rx", description: t("rxLabel") } });
    }
  }, [isNew]);

  let valueForDosageForm;
  if (p?.dosageForm && p.dosageForm?.type) {
    valueForDosageForm = p.dosageForm.type;
  } else if (p?.dosageForm && p.dosageForm.type === "") {
    valueForDosageForm = " ";
  } else {
    valueForDosageForm = "";
  }

  let valueForRouteForm;
  if (p?.route && p.route?.fullName) {
    valueForRouteForm = p.route.fullName;
  } else if (p?.route && p.route.fullName === "") {
    valueForRouteForm = t("Blank", { ns: "routes" });
  } else {
    valueForRouteForm = "";
  }

  return (
    <Accordion openByDefault={true} title={t("tab")} rightMargin={"10px"}>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__viewTransition}>
          <div
            className={
              smScreen
                ? styles["CreateProductAccordion__col"]
                : styles.CreateProductAccordion__col
            }
          >
            <div
              className={
                smScreen
                  ? styles.CreateProductAccordion__row
                  : styles.CreateProductAccordion__row
              }
            >
              <TextInput
                type={"text"}
                labelName={t("packageIdLabel")}
                isEnabled={isNew || isCopy}
                value={p?.packageId || ""}
                validationText={t(errors.packageId, { ns: "validation" })}
                focus={true}
                onChange={(val) => {
                  onPropertyChange({ packageId: val });
                }}
                placeholder={t("requiredPlaceholder")}
              />
              {isNew && (
                <div
                  className={styles.CreateProductAccordion__searchIconContainer}
                >
                  <SearchIcon
                    style={{ cursor: "pointer" }}
                    onClick={onHandleNDCLookup}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.CreateProductAccordion__col}>
            <TextInput
              type={"text"}
              labelName={t("productIdLabel")}
              isEnabled={true}
              value={p?.productId || ""}
              validationText={t(errors.productId, { ns: "validation" })}
              focus={false}
              onChange={(val) => {
                onPropertyChange({ productId: val });
              }}
              placeholder={t("requiredPlaceholder")}
            />
          </div>
        </div>
        <div className={styles.CreateProductAccordion__viewTransition}>
          <div className={styles.CreateProductAccordion__col}>
            <TextInput
              type={"text"}
              labelName={t("humanIdLabel")}
              isEnabled={true}
              value={p?.humanReadableId || ""}
              validationText={t(errors.humanReadableId, { ns: "validation" })}
              focus={false}
              onChange={(val) => {
                onPropertyChange({ humanReadableId: val });
              }}
            />
          </div>
          {/*<div className={styles.CreateProductAccordion__col}>*/}
          {/*  <TextInput*/}
          {/*    type={"text"}*/}
          {/*    labelName={"GCN"}*/}
          {/*    isEnabled={true}*/}
          {/*    value={p?.gcn || ""}*/}
          {/*    validationText={errors.gcn}*/}
          {/*    focus={false}*/}
          {/*    onChange={(val) => {*/}
          {/*      onPropertyChange({ gcn: val });*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={styles.CreateProductAccordion__col}>
            <TextInput
              type={"text"}
              labelName={t("substitutionIdLabel")}
              isEnabled={true}
              value={p?.therapeuticEquivalencyCode || ""}
              validationText={t(errors.therapeuticEquivalencyCode, {
                ns: "validation",
              })}
              focus={false}
              onChange={(val) => {
                onPropertyChange({ therapeuticEquivalencyCode: val });
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"text"}
            labelName={t("primaryNameLabel")}
            isEnabled={true}
            value={p?.primaryName || ""}
            validationText={t(errors.primaryName, { ns: "validation" })}
            focus={false}
            onChange={(val) => {
              const name = productControllerRef.current.getPrimaryName(val);
              onPropertyChange({ primaryName: name });
            }}
            placeholder={t("requiredPlaceholder")}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"text"}
            labelName={t("secondaryNameLabel")}
            isEnabled={true}
            value={p?.secondaryName || ""}
            validationText={t(errors.secondaryName, { ns: "validation" })}
            focus={false}
            onChange={(val) => {
              const name = productControllerRef.current.getSecondaryName(val);
              onPropertyChange({ secondaryName: name });
            }}
          />
        </div>
      </div>

      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"text"}
            labelName={t("tertiaryNameLabel")}
            isEnabled={true}
            value={p?.tertiaryName || ""}
            validationText={t(errors.tertiaryName, { ns: "validation" })}
            focus={false}
            onChange={(val) => {
              const name = productControllerRef.current.getTertiaryName(val);
              onPropertyChange({ tertiaryName: name });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"text"}
            labelName={t("tallManNameLabel")}
            isEnabled={true}
            value={p?.tallManName || ""}
            validationText={t(errors.tallManName, { ns: "validation" })}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ tallManName: val });
            }}
          />
        </div>
      </div>

      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__viewTransition}>
          <div
            className={styles.CreateProductAccordion__col}
            // style={{ marginTop: "18px" }}
          >
            <TextInput
              type={"text"}
              labelName={t("strengthLabel")}
              isEnabled={true}
              value={p?.strength || ""}
              validationText={t(errors.strength, { ns: "validation" })}
              focus={false}
              onChange={(val) => {
                let strength = productControllerRef.current.getStrength(val);
                onPropertyChange({ strength: strength });
              }}
            />
          </div>
          <div className={styles.CreateProductAccordion__col}>
            <DropdownInput
              value={valueForDosageForm}
              labelName={t("dosageFormLabel")}
              placeholder={""}
              onSelection={(listItem) => {
                let dosageForm = dosageFormList.find(
                  (o) => o.type === listItem.type
                );
                onPropertyChange({ dosageForm: dosageForm });
              }}
              onClearInput={() => {
                const defaultDosageForm = { type: "", description: "", id: -1 };
                onPropertyChange({ dosageForm: defaultDosageForm });
              }}
              listElements={dosageFormList}
              isEnabled={true}
              validationText={t(errors.dosageForm, { ns: "validation" })}
              display={"type"}
              inputStyle={{ width: "100%" }}
            />
          </div>
        </div>

        <div className={styles.CreateProductAccordion__viewTransition}>
          <div className={styles.CreateProductAccordion__col}>
            <DropdownInput
              value={t(valueForRouteForm, { ns: "routes" })}
              labelName={t("routeLabel")}
              placeholder={""}
              onSelection={(listItem) => {
                let val = {
                  fullName: "",
                  abbrName: "",
                };
                let route = routeList.find((o) => o.type === listItem.type);
                val.fullName = route.type;
                onPropertyChange({ route: val });
              }}
              listElements={routeList}
              isEnabled={true}
              validationText={t(errors.route, { ns: "validation" })}
              display={"abbrName"}
            />
          </div>
          <div className={styles.CreateProductAccordion__col}>
            <DropdownInput
              labelName={t("controlLevelLabel")}
              placeholder={""}
              onSelection={(listItem) => {
                onPropertyChange({
                  controlLevel: {
                    type: listItem.type,
                    id: listItem.id,
                    description: listItem.description,
                  },
                });
              }}
              listElements={controlLevelList}
              isEnabled={true}
              value={t(p?.controlLevel?.description, { ns: "controlLevels" })}
              validationText={t(errors.controlLevel, { ns: "validation" })}
              display={"displayName"}
            />
          </div>
        </div>
      </div>

      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"text"}
            labelName={t("defaultNameLabel")}
            isEnabled={true}
            value={
              p?.defaultName ||
              productControllerRef.current?.getDefaultName(p) ||
              ""
            }
            validationText={t(errors.defaultName, { ns: "validation" })}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ defaultName: val });
            }}
          />
        </div>
      </div>

      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"text"}
            labelName={t("manufacturerNameLabel")}
            isEnabled={true}
            value={p?.manufacturer?.fullName || ""}
            validationText={t(errors.manufacturer?.fullName, {
              ns: "validation",
            })}
            focus={false}
            onChange={(val) => {
              let data = p?.manufacturer;
              if (!data) {
                data = {
                  fullName: val,
                  abbrName: "",
                };
              } else {
                data = { ...p.manufacturer, fullName: val };
              }
              onPropertyChange({ manufacturer: data });
            }}
            placeholder={t("requiredPlaceholder")}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"text"}
            labelName={t("manufacturerAbbrLabel")}
            isEnabled={true}
            value={p?.manufacturer?.abbrName || ""}
            validationText={t(errors.manufacturer?.abbrName, {
              ns: "validation",
            })}
            focus={false}
            onChange={(val) => {
              let data = p?.manufacturer;
              if (!data) {
                data = {
                  fullName: "",
                  abbrName: val,
                };
              } else {
                data = { ...p.manufacturer, abbrName: val };
              }
              onPropertyChange({ manufacturer: data });
            }}
            placeholder={t("requiredPlaceholder")}
          />
        </div>
        <div
          className={styles.CreateProductAccordion__col}
          style={{ marginBottom: "40px" }}
        >
          <OptionGroup
            labelName={t("legendLabel")}
            optionList={[
              { type: "rx", description: t("rxLabel") },
              { type: "otc", description: t("otcLabel") },
            ]}
            selection={p?.legend || { type: "rx", description: t("rxLabel") }}
            onSelect={(option) => {
              onPropertyChange({ legend: option });
            }}
          />
        </div>
      </div>

      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
        style={{ marginTop: smScreen ? "0" : "20px" }}
      >
        <div className={styles.CreateProductAccordion__col}>
          <div className={styles.CreateProductAccordion__listBox}>
            <ListBox
              title={t("ingredientsLabel")}
              listItems={
                p?.ingredients
                  ? p?.ingredients.sort((a, b) => {
                      if (a < b) return -1;
                      if (b < a) return 1;
                      return 0;
                    })
                  : []
              }
              onAdd={(listItem) => {
                let data = p?.ingredients;
                if (!data) {
                  data = [listItem];
                } else {
                  data = [...p?.ingredients, listItem];
                }
                onPropertyChange({ ingredients: data });
              }}
              onEdit={(oldListItem, listItem) => {
                const list = [...p?.ingredients];
                const index = p?.ingredients.findIndex(
                  (o) => o === oldListItem
                );
                list[index] = listItem;
                onPropertyChange({ ingredients: list });
              }}
              onRemove={(listItem) => {
                const list = [...p?.ingredients];
                const index = p?.ingredients.findIndex((o) => o === listItem);
                list.splice(index, 1);
                onPropertyChange({ ingredients: list });
              }}
            />
          </div>
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextArea
            labelName={t("medCommentsLabel")}
            width={"100%"}
            height={"120px"}
            text={p?.comments || ""}
            onChange={(text) => {
              onPropertyChange({ comments: text });
            }}
          />
        </div>
      </div>

      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
        style={{ marginTop: smScreen ? "24px" : "40px" }}
      >
        <div
          className={`${styles.CreateProductAccordion__checkboxGroup} ${styles["CreateProductAccordion__checkboxGroup--1"]}`}
        >
          <div
            className={`${styles.CreateProductAccordion__col} ${styles["CreateProductAccordion__col--checkboxSpacing"]}`}
          >
            <Checkbox
              labelName={t("hazardousLabel")}
              isOn={p?.isHazardous || false}
              onCheck={(isOn) => onPropertyChange({ isHazardous: isOn })}
            />
          </div>
          <div
            className={`${styles.CreateProductAccordion__col} ${styles["CreateProductAccordion__col--checkboxSpacing"]}`}
          >
            <Checkbox
              labelName={t("truCardLabel")}
              isOn={p?.allowVBM}
              onCheck={(isOn) => onPropertyChange({ allowVBM: isOn })}
            />
          </div>
          <div
            className={`${styles.CreateProductAccordion__col} ${styles["CreateProductAccordion__col--checkboxSpacing"]}`}
          >
            <Checkbox
              labelName={t("truPakLabel")}
              isOn={p?.allowProud}
              onCheck={(isOn) => onPropertyChange({ allowProud: isOn })}
            />
          </div>
          <div
            className={`${styles.CreateProductAccordion__col} ${styles["CreateProductAccordion__col--checkboxSpacing"]}`}
          >
            <Checkbox
              labelName={t("truScriptLabel")}
              isOn={p?.allowEV54}
              onCheck={(isOn) => {
                // if (isOn && (!p?.ev54DrugId || p.ev54DrugId.length > 9)) {
                //   onError("The EV-54 drug id must be 9 characters or less");
                // }
                onPropertyChange({ allowEV54: isOn });
              }}
            />
          </div>
          <div
            className={`${styles.CreateProductAccordion__col} ${styles["CreateProductAccordion__col--checkboxSpacing"]}`}
          >
            <Checkbox
              labelName={t("truSortLabel")}
              isOn={p?.allowReturn}
              onCheck={(isOn) => onPropertyChange({ allowReturn: isOn })}
            />
          </div>
        </div>

        <div
          className={`${styles.CreateProductAccordion__checkboxGroup} ${styles["CreateProductAccordion__checkboxGroup--2"]}`}
        >
          <div
            className={`${styles.CreateProductAccordion__col} ${styles["CreateProductAccordion__col--checkboxSpacing"]}`}
          >
            <Checkbox
              labelName={t("doNotMixLabel")}
              isOn={p?.doNotMix}
              onCheck={(isOn) => onPropertyChange({ doNotMix: isOn })}
            />
          </div>
          <div
            className={`${styles.CreateProductAccordion__col} ${styles["CreateProductAccordion__col--checkboxSpacing"]}`}
          >
            <Checkbox
              labelName={t("unitDoseLabel")}
              isOn={p?.unitDoseOnly}
              onCheck={(isOn) => onPropertyChange({ unitDoseOnly: isOn })}
            />
          </div>
          <div className={styles.CreateProductAccordion__col}>
            <Checkbox
              labelName={t("externalLabel")}
              isOn={p?.externalMed}
              onCheck={(isOn) => onPropertyChange({ externalMed: isOn })}
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
};

export default CreateProductGeneral;

import axios from "axios";
import ApiService from "./ApiService";

class DispensingOrderService extends ApiService {
  static get = async (user, site, facility, dispensingOrderId) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/facilities/${facility._id}/dispensing-orders/get-by-dispensing-order-id?dispensingOrderId=${dispensingOrderId}`,
        rc
      );
      return response.data.dispensingOrder;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getDispensingOrdersForSite = async (user, site, state) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders-2?state=${state}`,
        rc
      );
      return response.data.dispensingOrders;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getDetails = async (user, site, dispensingOrderIds) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const queryString = dispensingOrderIds
        .map((id) => `dispensingOrderId=${id}`)
        .join("&");
      const url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders/details?${queryString}`;
      let response = await axios.get(url, rc);
      return response.data.details;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static clearError = async (user, site, dispensingOrder) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders/${dispensingOrder._id}/clear-error`,
        rc
      );
      return response.data.details;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static assignFacility = async (user, site, dispOrder, facility) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders/${dispOrder._id}/assignFacility?facilityId=${facility._id}`,
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static rxNumberIsActive = async (user, rxNumber) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/rx-active?rxNumber=${rxNumber}`,
        rc
      );
      return response.data.isActive;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static create = async (user, site, facility, dispensingOrder) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/facilities/${facility._id}/dispensing-orders`,
        { dispensingOrder: dispensingOrder },
        rc
      );
      return response.data.dispensingOrder;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static addDispensingOrderItem = async (user, dispensingOrderItem) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${dispensingOrderItem.site._id}/facilities/${dispensingOrderItem.facility._id}/dispensing-orders/${dispensingOrderItem.dispensingOrder._id}/dispensing-order-items`,
        { dispensingOrderItem: dispensingOrderItem },
        rc
      );
      return response.data.dispensingOrderItem;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getBySite = async (user, site, ignorePackagedItems) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
        timeout: 20000,
      };
      let uri = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders`;
      if (ignorePackagedItems) {
        uri += "?ignorePackagedItems=true";
      }

      let response = await axios.get(uri, rc);
      return response.data.dispensingOrders;
    } catch (error) {
      ApiService.handleResponseError(error.response);
      return [];
    }
  };

  static delete = async (user, site, dispensingOrder) => {
    try {
      let siteId = site._id || site;

      const rc = {
        headers: { "x-access-token": user.token },
      };
      await axios.delete(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${siteId}/dispensing-orders/${dispensingOrder._id}`,
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static resetOrder = async (user, site, dispensingOrder, state) => {
    try {
      let siteId = site._id || site;

      const rc = {
        headers: { "x-access-token": user.token },
      };
      await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${siteId}/dispensing-orders/${dispensingOrder._id}/reset?state=${state}`,
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static routeOrder = async (user, site, device, dispensingOrder) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${device._id}/dispensing-orders/${dispensingOrder._id}/route`,
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static combineOrders = async (user, dispensingOrders) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/combine`,
        {
          dispensingOrders: dispensingOrders,
        },
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getDispensingNeeds = async (user, stockLocation, dispensingOrders) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/stock-locations/${stockLocation._id}/dispensing-orders/needs`,
        {
          dispensingOrders: dispensingOrders,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getSiteReplenishmentNeeds = async (user, site) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/needs`,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static addProduct = async (user, dispensingOrderItem, product) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/add-product`,
        {
          dispensingOrderItem: dispensingOrderItem,
          product: product,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static substituteProduct = async (
    user,
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/substitute-product`,
        {
          originalDispensingOrderItem: originalDispensingOrderItem,
          modifiedDispensingOrderItem: modifiedDispensingOrderItem,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updateAdminDateTime = async (
    user,
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/update-admin-datetime`,
        {
          originalDispensingOrderItem: originalDispensingOrderItem,
          modifiedDispensingOrderItem: modifiedDispensingOrderItem,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updateOrderPriority = async (
    user,
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/update-order-priority`,
        {
          originalDispensingOrderItem: originalDispensingOrderItem,
          modifiedDispensingOrderItem: modifiedDispensingOrderItem,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updateQuantity = async (
    user,
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/update-quantity`,
        {
          originalDispensingOrderItem: originalDispensingOrderItem,
          modifiedDispensingOrderItem: modifiedDispensingOrderItem,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updatePatientLocation = async (
    user,
    dispensingOrder,
    patientId,
    room
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/update-patient-location`,
        {
          dispensingOrder: dispensingOrder,
          patientId: patientId,
          room: room,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updatePatientLanguage = async (
    user,
    dispensingOrder,
    patientId,
    language
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/update-patient-language`,
        {
          dispensingOrder: dispensingOrder,
          patientId: patientId,
          language: language,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static splitOrder = async (
    user,
    originalDispensingOrderIds,
    newDispensingOrders
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/split-order`;
      const response = await axios.post(
        url,
        {
          originalDispensingOrderIds: originalDispensingOrderIds,
          newDispensingOrders: newDispensingOrders,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getTrayNeeds = async (user, dispensingOrder) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/${dispensingOrder._id}/tray-needs`,
        rc
      );
      return response.data.trayNeeds;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static deleteDispensingOrderItem = async (
    userLoggedIn,
    dispensingOrderItem
  ) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id}/dispensingOrderItem/${dispensingOrderItem._id}`;

      url += `?dispensingOrderItemId=${dispensingOrderItem._id}`;

      const rc = {
        headers: { "x-access-token": userLoggedIn.token },
      };

      let response = await axios.delete(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static generate = async (user, site, selectedDevice, generationType) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${selectedDevice._id}/order-generation/generate?generationType=${generationType}`,
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updateDispensingOrderId = async (
    user,
    site,
    id,
    dispensingOrderId
  ) => {
    try {
      const url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders/${id}/dispensingOrderId`;
      await axios.put(
        url,
        {
          dispensingOrderId: dispensingOrderId,
        },
        {
          headers: { "x-access-token": user.token },
        }
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getPatients = async (user, site, dispensingOrder) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders/${dispensingOrder._id}/patients`,
        rc
      );
      return response.data.patients;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  // patientIds should be a list if patientIds separated by a comma
  static getMedLists = async (user, site, dispensingOrder, patientIdList) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders/${dispensingOrder._id}/patient-med-lists?patientIdList=${patientIdList}`,
        rc
      );
      return response.data.medLists;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
  static getByIds = async (
    user,
    ids
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      const queryString = ids
        .map((id) => `dispensingOrderIds=${id}`)
        .join("&");

      const url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dispensing-orders/ids?${queryString}`;

      let response = await axios.get(url, rc);
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default DispensingOrderService;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import DashboardService from "../../../../../../services/DashboardService";
import DailyTotalBarChart from "./DailyTotalBarChart/DailyTotalBarChart";
import { ReactComponent as ArrowUpCircle } from "../../../../../../assets/arrow-up-circle.svg";
import { ReactComponent as ArrowDownCircle } from "../../../../../../assets/arrow-down-circle.svg";
import {
  getNumberOfWeekDayInDateRange,
  getNumberOfDaysInDateRange,
} from "../../../../../../functions/dates";
import styles from "./MetricsChartView.module.css";
const MetricsChartViewTrays = ({
  user,
  activeKey,
  selectedDayTray,
  onSelectedDayTray,
  selectedDevices,
  selectedSites,
  selectedDateRange,
  applicableDates,
  onApplicableDates,
  trayProducts,
  onTrayProducts,
  onTrayProductsSelectedDay,
  trayProductSelectionLookup,
  onTrayProductSelectionLookup,
  onShowDropdowns,
  batchesCurrentWeek,
  batchesLastWeek,
  batchesSelectedDateRange,
}) => {
  const { t } = useTranslation("trupakDashboard");

  const [
    totalWholePillDispensedTraySelectedDateRange,
    setTotalWholePillDispensedTraySelectedDateRange,
  ] = useState({
    U: 0,
    M: 0,
    T: 0,
    W: 0,
    R: 0,
    F: 0,
    S: 0,
    total: 0,
  });

  const [trayBargraphData, setTrayBargraphData] = useState([]);
  const [classNameForBargraph, setClassNameforBargraph] = useState(null);
  const _dashboardServiceRef = useRef(DashboardService);

  const [
    totalWholePillsDispensedFromTrayLastWeek,
    setTotalWholePillsDispensedFromTrayLastWeek,
  ] = useState(0);

  const [
    totalWholePillsDispensedFromTrayCurrentWeek,
    setTotalWholePillsDispensedFromTrayCurrentWeek,
  ] = useState(0);

  const [
    dailyAverageTraySelectedDateRange,
    setDailyAverageTraySelectedDateRange,
  ] = useState(0);

  const [dispensingOrderIds, setDispensingOrderIds] = useState([]);
  const [packageIdsList, setPackageIdsList] = useState([]);
  const [readyForServerRequest, setReadyForServerRequest] = useState(false);

  useEffect(() => {
    /**
     * Very important
     *  - Needed to clear trayProducts, trayProductSelectionLookup and
     *      dailyAverageTraySelectedDateRange if there are no trays
     **/
    const trayBargraphDataWithValues = trayBargraphData.filter(
      (dataSet) => dataSet.y > 0
    );

    if (!trayBargraphDataWithValues.length) {
      onTrayProducts([]);
      onTrayProductSelectionLookup({});
      setDailyAverageTraySelectedDateRange(0);
    }
  }, [trayBargraphData, onTrayProducts, onTrayProductSelectionLookup]);

  // SELECTED DATE RANGE
  useEffect(() => {
    /**
     * If batchesSelectedDateRange array has length, sets:
     *  - readyForServerRequest
     *  - totalWholePillDispensedTraySelectedDateRange
     *  - trayBargraphData
     *  - trayProductSelectionLookup,
     *  - dispensingOrderIds
     *  - packagedIdsList
     *
     * Else, resets the following to default (starting) values:
     *  - trayProducts,
     *  - trayProductsSelectedDay,
     *  - trayProductSelectionLookup,
     *  - trayBargraphData,
     *  - selectedTrayDate
     *  - readyForServerRequest
     **/
    if (batchesSelectedDateRange.length) {
      let trayPackageIds = [];
      let tempDispensingOrderIds = [];
      let trayProducts_temp = [];
      let totalWholePillQuantity = 0;
      let tempWholePillQtySunday = 0;
      let tempWholePillQtyMonday = 0;
      let tempWholePillQtyTuesday = 0;
      let tempWholePillQtyWednesday = 0;
      let tempWholePillQtyThursday = 0;
      let tempWholePillQtyFriday = 0;
      let tempWholePillQtySaturday = 0;
      let dayOfWeek;
      let tempTrayProductSelectionLookup = {};

      const incrementQuantityForDayOfWeek = (day, quantity) => {
        switch (day) {
          case 0:
            tempWholePillQtySunday += quantity;
            break;
          case 1:
            tempWholePillQtyMonday += quantity;
            break;
          case 2:
            tempWholePillQtyTuesday += quantity;
            break;
          case 3:
            tempWholePillQtyWednesday += quantity;
            break;
          case 4:
            tempWholePillQtyThursday += quantity;
            break;
          case 5:
            tempWholePillQtyFriday += quantity;
            break;
          case 6:
            tempWholePillQtySaturday += quantity;
            break;
          default:
        }
      };

      for (const batchSelectedDateRange of batchesSelectedDateRange) {
        dayOfWeek = new Date(batchSelectedDateRange.createdAt).getDay(); // gets day of corresponding local time

        if (
          !tempDispensingOrderIds.includes(
            batchSelectedDateRange.dispensingOrderId
          )
        ) {
          tempDispensingOrderIds.push(batchSelectedDateRange.dispensingOrderId);
        }
        for (const dispensingDetail of batchSelectedDateRange.dispensingDetails) {
          if (dispensingDetail.isTrayMed && !dispensingDetail.isPartial) {
            if (!trayPackageIds.includes(dispensingDetail.packageId)) {
              trayPackageIds.push(dispensingDetail.packageId);
              trayProducts_temp.push({
                dispensingOrderId: batchSelectedDateRange.dispensingOrderId,
                packageId: dispensingDetail.packageId,
                productName: dispensingDetail.productDescription,
                qty: dispensingDetail.quantity,
                trays: 0,
                dayOfWeek: dayOfWeek,
                manufacturer: dispensingDetail.productManufacturer,
              });
            } else {
              if (
                trayProducts_temp.find(
                  (p) => p.packageId === dispensingDetail.packageId
                )
              ) {
                const product = trayProducts_temp.find(
                  (p) => p.packageId === dispensingDetail.packageId
                );
                if (product.dayOfWeek === dayOfWeek) {
                  product.qty += dispensingDetail.quantity;
                }
              }
            }
          }
        } // end for batch of
      }

      trayProducts_temp.sort(compareByQuantityDescending);

      trayProducts_temp.forEach((product) => {
        totalWholePillQuantity += product.qty;
        incrementQuantityForDayOfWeek(product.dayOfWeek, product.qty);
        tempTrayProductSelectionLookup[product.packageId] = {
          selected: false,
          product: product.productName,
          manufacturer: product.manufacturer,
        };
      });
      setReadyForServerRequest(true);

      setTotalWholePillDispensedTraySelectedDateRange({
        total: totalWholePillQuantity,
        U: tempWholePillQtySunday,
        M: tempWholePillQtyMonday,
        T: tempWholePillQtyTuesday,
        W: tempWholePillQtyWednesday,
        R: tempWholePillQtyThursday,
        F: tempWholePillQtyFriday,
        S: tempWholePillQtySaturday,
      });

      setTrayBargraphData([
        {
          x: t("Sunday"),
          y: tempWholePillQtySunday > 0 ? tempWholePillQtySunday : 0,
        },
        {
          x: t("Monday"),
          y: tempWholePillQtyMonday > 0 ? tempWholePillQtyMonday : 0,
        },
        {
          x: t("Tuesday"),
          y: tempWholePillQtyTuesday > 0 ? tempWholePillQtyTuesday : 0,
        },
        {
          x: t("Wednesday"),
          y: tempWholePillQtyWednesday > 0 ? tempWholePillQtyWednesday : 0,
        },
        {
          x: t("Thursday"),
          y: tempWholePillQtyThursday > 0 ? tempWholePillQtyThursday : 0,
        },
        {
          x: t("Friday"),
          y: tempWholePillQtyFriday > 0 ? tempWholePillQtyFriday : 0,
        },
        {
          x: t("Saturday"),
          y: tempWholePillQtySaturday > 0 ? tempWholePillQtySaturday : 0,
        },
      ]);

      onTrayProductSelectionLookup(tempTrayProductSelectionLookup);
      onTrayProducts(trayProducts_temp);
      setDispensingOrderIds(tempDispensingOrderIds);

      // convert packageIds array to string list
      let tempPackageIdsList = "";

      trayPackageIds.forEach((packageId) => {
        tempPackageIdsList += `${packageId},`;
      });

      tempPackageIdsList = tempPackageIdsList.substring(
        0,
        tempPackageIdsList.length - 1
      );

      setPackageIdsList(tempPackageIdsList);
    } else {
      onTrayProducts([]);
      onTrayProductsSelectedDay([]);
      onTrayProductSelectionLookup({});
      setTrayBargraphData([]);
      setReadyForServerRequest(false);
    }
  }, [
    user,
    batchesSelectedDateRange,
    onTrayProducts,
    onTrayProductSelectionLookup,
    onTrayProductsSelectedDay,
    t,
  ]);

  // SELECTED DATE RANGE
  useEffect(() => {
    /**
     * Sets dailyAverageTraySelectedDateRange
     *  - If selectedDayTray is null, this is the average across the entire selected date range
     *  - If selectedDayTray is set, this is the average for that specific day over the date range
     **/
    let tempDailyAverageTraySelectedDateRange;
    if (
      selectedDateRange?.startDate &&
      selectedDateRange?.endDate &&
      !applicableDates &&
      !selectedDayTray &&
      selectedDayTray !== 0
    ) {
      tempDailyAverageTraySelectedDateRange = (
        totalWholePillDispensedTraySelectedDateRange.total /
        getNumberOfDaysInDateRange(
          selectedDateRange.startDate,
          selectedDateRange.endDate
        )
      ).toFixed(1);

      setDailyAverageTraySelectedDateRange(
        tempDailyAverageTraySelectedDateRange
      );
    } else if (selectedDayTray || selectedDayTray === 0) {
      let totalTrayPillsForSelectedDay;
      switch (selectedDayTray) {
        case 0:
          totalTrayPillsForSelectedDay =
            totalWholePillDispensedTraySelectedDateRange.U;
          break;
        case 1:
          totalTrayPillsForSelectedDay =
            totalWholePillDispensedTraySelectedDateRange.M;
          break;
        case 2:
          totalTrayPillsForSelectedDay =
            totalWholePillDispensedTraySelectedDateRange.T;
          break;
        case 3:
          totalTrayPillsForSelectedDay =
            totalWholePillDispensedTraySelectedDateRange.W;
          break;
        case 4:
          totalTrayPillsForSelectedDay =
            totalWholePillDispensedTraySelectedDateRange.R;
          break;
        case 5:
          totalTrayPillsForSelectedDay =
            totalWholePillDispensedTraySelectedDateRange.F;
          break;
        case 6:
          totalTrayPillsForSelectedDay =
            totalWholePillDispensedTraySelectedDateRange.S;
          break;
        default:
        // code block
      }

      if (applicableDates && applicableDates.length > 0) {
        tempDailyAverageTraySelectedDateRange =
          totalTrayPillsForSelectedDay /
          getNumberOfWeekDayInDateRange(
            selectedDateRange.startDate,
            selectedDateRange.endDate,
            selectedDayTray
          );

        if (tempDailyAverageTraySelectedDateRange) {
          setDailyAverageTraySelectedDateRange(
            tempDailyAverageTraySelectedDateRange.toFixed(1)
          );
        } else {
          setDailyAverageTraySelectedDateRange((0.0).toFixed(1));
        }
      } else {
        setDailyAverageTraySelectedDateRange((0.0).toFixed(1));
      }
    } else {
      setDailyAverageTraySelectedDateRange((0.0).toFixed(1));
    }
  }, [
    selectedDateRange,
    totalWholePillDispensedTraySelectedDateRange,
    selectedDayTray,
    applicableDates,
  ]);

  useEffect(() => {
    /**
     * If readyForServerRequest is true and selectedDayTray is null:
     *  - sets productTrayCountLookup
     *  - updates trayProducts to include tray counts for each product
     **/
    if (readyForServerRequest && !selectedDayTray && selectedDayTray !== 0) {
      const getTrayProductCounts = async (
        utcStartDate,
        utcStopDate,
        packageIds,
        dispensingOrderIds
      ) => {
        let tempProductTrayCountLookup = {};
        for (let device of selectedDevices) {
          for (let site of selectedSites) {
            await _dashboardServiceRef.current
              .getTruPakTrayProductCounts(
                user,
                site,
                device,
                utcStartDate,
                utcStopDate,
                packageIds,
                dispensingOrderIds,
                false // dayOfWeekIsSelected
              )
              .then((results) => {
                for (const result of results.results) {
                  if (!(result.packageId in tempProductTrayCountLookup)) {
                    tempProductTrayCountLookup[result.packageId] =
                      result.trayCount;
                  } else {
                    tempProductTrayCountLookup[result.packageId] +=
                      result.trayCount;
                  }
                }

                let trayProductsTemp = [...trayProducts];
                for (const tempTrayProduct of trayProductsTemp) {
                  if (
                    tempProductTrayCountLookup[tempTrayProduct.packageId] &&
                    !selectedDayTray &&
                    selectedDayTray !== 0
                  ) {
                    tempTrayProduct.trays =
                      tempProductTrayCountLookup[tempTrayProduct.packageId];
                  } else if (!selectedDayTray && selectedDayTray !== 0) {
                    tempTrayProduct.trays = 0;
                  }
                }
                onTrayProducts(trayProductsTemp);
              });
          }
        }
      };

      let tempDispensingOrderIdsList = "";

      dispensingOrderIds.forEach((dispensingOrderId) => {
        tempDispensingOrderIdsList += `${dispensingOrderId},`;
      });

      tempDispensingOrderIdsList = tempDispensingOrderIdsList.substring(
        0,
        tempDispensingOrderIdsList.length - 1
      );
      if (
        selectedDateRange.startDate &&
        selectedDateRange.endDate &&
        packageIdsList?.length &&
        tempDispensingOrderIdsList?.length
      ) {
        getTrayProductCounts(
          selectedDateRange.startDate.toUTCString(),
          selectedDateRange.endDate.toUTCString(),
          packageIdsList,
          tempDispensingOrderIdsList
        );
      }
      setReadyForServerRequest(false);
    }
  }, [
    onTrayProductSelectionLookup,
    dispensingOrderIds,
    onTrayProducts,
    packageIdsList,
    selectedDateRange,
    selectedDayTray,
    selectedDevices,
    selectedSites,
    trayProducts,
    user,
    readyForServerRequest,
  ]);

  // SELECTED DAY
  const handleTrayProductsSelectedDay = (day) => {
    /**
     * Sets trayProductsSelectedDay
     * Note that this method also initializes the number of trays each product to 0
     * The tray count for each product is later calculated and updated by ...
     **/
    let trayPackageIds = [];
    let trayProducts_temp = [];
    let dayOfWeek;

    let batchesSelectedDay = batchesSelectedDateRange.filter(
      (batchSelectedDay) => {
        dayOfWeek = new Date(batchSelectedDay.createdAt).getDay();
        return (
          (dayOfWeek === day || (dayOfWeek === 0 && day === 0)) &&
          batchSelectedDay.trayWholeQty > 0
        );
      }
    );

    let dispensingOrderIds = [];
    let tempApplicableDates = [];

    for (const batchSelectedDay of batchesSelectedDay) {
      if (!(batchSelectedDay.createdAt in tempApplicableDates)) {
        tempApplicableDates.push(batchSelectedDay.createdAt);
      }

      if (!dispensingOrderIds.includes(batchSelectedDay.dispensingOrderId)) {
        dispensingOrderIds.push(batchSelectedDay.dispensingOrderId);
      }

      for (const dispensingDetail of batchSelectedDay.dispensingDetails) {
        if (
          !trayPackageIds.includes(dispensingDetail.packageId) &&
          dispensingDetail.isTrayMed &&
          !dispensingDetail.isPartial
        ) {
          trayPackageIds.push(dispensingDetail.packageId);
        }
      }
    }

    let trayProductsSelectedTemp = [];
    trayProducts_temp.sort(compareByQuantityDescending);

    trayProducts.forEach((product) => {
      trayProductsSelectedTemp.push({
        packageId: product.packageId,
        productName: product.productName,
        qty: product.qty,
        trays: 0,
        dayOfWeek: product.dayOfWeek,
      });
    });

    trayProductsSelectedTemp = [
      ...trayProductsSelectedTemp.filter(
        (product) => product.dayOfWeek === day
      ),
    ];

    if (batchesSelectedDay.length) {
      // convert packageIds array to string list
      let trayPackageIdsList = "";

      trayPackageIds.forEach((packageId) => {
        trayPackageIdsList += `${packageId},`;
      });

      trayPackageIdsList = trayPackageIdsList.substring(
        0,
        trayPackageIdsList.length - 1
      );

      // convert dispensingOrderIds array to string list
      let trayDispensingOrderIdsList = "";

      dispensingOrderIds.forEach((dispensingOrderId) => {
        trayDispensingOrderIdsList += `${dispensingOrderId},`;
      });

      trayDispensingOrderIdsList = trayDispensingOrderIdsList.substring(
        0,
        trayDispensingOrderIdsList.length - 1
      );

      tempApplicableDates.sort((a, b) => new Date(a) - new Date(b));
      onApplicableDates(tempApplicableDates);

      let startDateSelectedDay = new Date(tempApplicableDates[0]);
      startDateSelectedDay.setHours(0);
      startDateSelectedDay.setMinutes(0);
      startDateSelectedDay.setSeconds(0);

      let stopDateSelectedDay = new Date(
        tempApplicableDates[tempApplicableDates.length - 1]
      );

      stopDateSelectedDay.setHours(23);
      stopDateSelectedDay.setMinutes(59);
      stopDateSelectedDay.setSeconds(59);

      setReadyForServerRequest(true);

      const getTrayProductCounts = async (
        utcStartDate,
        utcStopDate,
        packageIds,
        dispensingOrderIds
      ) => {
        let tempProductTrayCountLookup = {};
        for (let device of selectedDevices) {
          for (let site of selectedSites) {
            await _dashboardServiceRef.current
              .getTruPakTrayProductCounts(
                user,
                site,
                device,
                utcStartDate,
                utcStopDate,
                packageIds,
                dispensingOrderIds,
                true // dayOfWeekIsSelected
              )
              .then((results) => {
                for (const result of results.results) {
                  if (!(result.packageId in tempProductTrayCountLookup)) {
                    tempProductTrayCountLookup[result.packageId] =
                      result.trayCount;
                  } else {
                    tempProductTrayCountLookup[result.packageId] +=
                      result.trayCount;
                  }
                }
                for (const tempTrayProduct of trayProductsSelectedTemp) {
                  if (tempProductTrayCountLookup[tempTrayProduct.packageId]) {
                    tempTrayProduct.trays =
                      tempProductTrayCountLookup[tempTrayProduct.packageId];
                  } else {
                    tempTrayProduct.trays = 0;
                  }
                }
                const trayProductsSelectedTempWithTrayCounts =
                  trayProductsSelectedTemp.map((product) => {
                    const matchingResultsObj = results.results.find(
                      (result) => result.packageId === product.packageId
                    );
                    if (matchingResultsObj) {
                      product.trays = matchingResultsObj.trayCount;
                    }
                    return product;
                  });

                onTrayProductsSelectedDay(
                  trayProductsSelectedTempWithTrayCounts
                );
              });
          }
        }
      };
      getTrayProductCounts(
        startDateSelectedDay.toUTCString(),
        stopDateSelectedDay.toUTCString(),
        trayPackageIdsList,
        trayDispensingOrderIdsList
      );
    } else {
      onTrayProductsSelectedDay(trayProductsSelectedTemp);
    }
  };

  // FOR BATCHES LAST WEEK
  useEffect(() => {
    /**
     * Sets:
     *  - totalDispensedPillsFromCanisterLastWeek
     **/
    let totalWholePillQuantity = 0;

    for (const batchLastWeek of batchesLastWeek) {
      for (const dispensingDetail of batchLastWeek.dispensingDetails) {
        if (dispensingDetail.isTrayMed && !dispensingDetail.isPartial) {
          totalWholePillQuantity += dispensingDetail.quantity;
        }
      }
    }
    setTotalWholePillsDispensedFromTrayLastWeek(totalWholePillQuantity);
  }, [batchesLastWeek]);

  // FOR BATCHES CURRENT WEEK
  useEffect(() => {
    /**
     * Sets:
     *  - totalWholePillsDispensedFromTrayCurrentWeek
     **/
    let totalWholePillQuantity = 0;

    for (const batchCurrentWeek of batchesCurrentWeek) {
      for (const dispensingDetail of batchCurrentWeek.dispensingDetails) {
        if (dispensingDetail.isTrayMed && !dispensingDetail.isPartial) {
          totalWholePillQuantity += dispensingDetail.quantity;
        }
      }
    }
    setTotalWholePillsDispensedFromTrayCurrentWeek(totalWholePillQuantity);
  }, [batchesCurrentWeek]);

  /***** Calculate percent increase from last week ****/
  let percentIncreaseFromLastWeek = NaN;

  if (totalWholePillsDispensedFromTrayLastWeek > 0) {
    percentIncreaseFromLastWeek =
      ((totalWholePillsDispensedFromTrayCurrentWeek -
        totalWholePillsDispensedFromTrayLastWeek) /
        totalWholePillsDispensedFromTrayLastWeek) *
      100;
  }

  let percentMessage = `${parseFloat(percentIncreaseFromLastWeek).toFixed(
    1
  )}% ${t("increase from last week")}`;
  let IconIndicator = <ArrowUpCircle />;

  if (isNaN(percentIncreaseFromLastWeek)) {
    percentMessage = "No fills last week";
  } else if (percentIncreaseFromLastWeek < 0) {
    IconIndicator = <ArrowDownCircle />;
    percentMessage = `${(-parseFloat(percentIncreaseFromLastWeek)).toFixed(
      1
    )}% ${t("decrease from last week")}`;
  }

  const dailyAverage = dailyAverageTraySelectedDateRange;

  const compareByQuantityDescending = (a, b) => {
    if (a.qty < b.qty) {
      return 1;
    } else if (a.qty > b.qty) {
      return -1;
    } else if (a.productName.toUpperCase() < b.productName.toUpperCase()) {
      return -1;
    }
    return 1;
  };

  const handleClassNameForBargraph = useCallback((name) => {
    setClassNameforBargraph(name);
  }, []);

  const handleNullifyApplicableDates = () => {
    onApplicableDates(null);
  };

  let tableTitle;

  tableTitle = (
    <div className={styles.MetricChartView__bargraphTitle}>
      <h2 className={styles.MetricChartView__bargraphTitleMain}>
        {t("Daily Tray Total")}
      </h2>
    </div>
  );

  return (
    <>
      <div className={styles.MetricChartView__tableContainer}>
        <div className={styles.MetricChartView__bargraphHeadingContainer}>
          <div className={styles.MetricChartView__dailyAverageContainer}>
            <h3>{t("Daily Average")}</h3>
            <p className={styles.MetricChartView_dailyAverage}>
              {dailyAverage}
            </p>
          </div>
          {tableTitle}
          <div
            className={styles.MetricChartView__percentageFromLastWeekContainer}
          >
            {IconIndicator}
            <p>{percentMessage}</p>
          </div>
        </div>

        <DailyTotalBarChart
          user={user}
          stylesName="BarGraph"
          data={trayBargraphData}
          chartWidth="700"
          chartHeight="300"
          onProductsSelectedDay={handleTrayProductsSelectedDay}
          selectedDay={selectedDayTray}
          onSelectedDay={onSelectedDayTray}
          classNameForBargraph={classNameForBargraph}
          onClassNameForBargraph={handleClassNameForBargraph}
          productSelectionLookup={trayProductSelectionLookup}
          onProductSelectionLookup={onTrayProductSelectionLookup}
          onShowDropdowns={onShowDropdowns}
          onNullifyApplicableDates={handleNullifyApplicableDates}
        />
      </div>
    </>
  );
};

export default MetricsChartViewTrays;

import React, { useEffect, useState } from "react";
import BarGraph from "../../../../../sharedGraphs/BarGraph";
import "./DailyTotalBarChart.css";

function DailyTotalBarChart({
  user,
  stylesName,
  data,
  chartWidth,
  chartHeight,
  selectedDay,
  onSelectedDay,
  onProductsSelectedDay,
  classNameForBargraph,
  onClassNameForBargraph,
  productSelectionLookup,
  onProductSelectionLookup,
  onNullifyApplicableDates,
}) {
  const [palette, setPalette] = useState([
    "#218678",
    "#218678",
    "#218678",
    "#218678",
    "#218678",
    "#218678",
    "#218678",
  ]);
  useEffect(() => {
    if (selectedDay === null) {
      onClassNameForBargraph("DailyTotalBarChart__allDaysSelected");
    }
  }, [selectedDay, onClassNameForBargraph]);

  useEffect(() => {
    let tempPalette = [
      "#21867830",
      "#21867830",
      "#21867830",
      "#21867830",
      "#21867830",
      "#21867830",
      "#21867830",
    ];
    switch (selectedDay) {
      case 0:
        tempPalette[0] = "#218678";
        break;
      case 1:
        tempPalette[1] = "#218678";
        break;
      case 2:
        tempPalette[2] = "#218678";
        break;
      case 3:
        tempPalette[3] = "#218678";
        break;
      case 4:
        tempPalette[4] = "#218678";
        break;
      case 5:
        tempPalette[5] = "#218678";
        break;
      case 6:
        tempPalette[6] = "#218678";
        break;
      default:
        tempPalette.fill("#218678");
    }
    setPalette(tempPalette);
  }, [selectedDay]);

  const handleBarClick = (index) => {
    let tempRowStatus = { ...productSelectionLookup };

    for (const [key, value] of Object.entries(tempRowStatus)) {
      if (value.selected) {
        value.selected = false;
      }
    }
    onProductSelectionLookup(tempRowStatus);

    let tempSelectedDay = null;

    if (selectedDay !== index) {
      tempSelectedDay = index;

      switch (tempSelectedDay) {
        case 0:
          onClassNameForBargraph("DailyTotalBarChart__SundaySelected");
          break;
        case 1:
          onClassNameForBargraph("DailyTotalBarChart__MondaySelected");
          break;
        case 2:
          onClassNameForBargraph("DailyTotalBarChart__TuesdaySelected");
          break;
        case 3:
          onClassNameForBargraph("DailyTotalBarChart__WednesdaySelected");
          break;
        case 4:
          onClassNameForBargraph("DailyTotalBarChart__ThursdaySelected");
          break;
        case 5:
          onClassNameForBargraph("DailyTotalBarChart__FridaySelected");
          break;
        case 6:
          onClassNameForBargraph("DailyTotalBarChart__SaturdaySelected");
          break;
        default:
          onClassNameForBargraph("DailyTotalBarChart__allDaysSelected");
      }
      onSelectedDay(tempSelectedDay);
      onProductsSelectedDay(index);
    } else {
      onSelectedDay(null);
      onProductsSelectedDay([]);
      onClassNameForBargraph("DailyTotalBarChart__allDaysSelected");
      onNullifyApplicableDates();
    }
  };

  const ChartTrayGraph = (
    <div className={classNameForBargraph}>
      <BarGraph
        user={user}
        stylesName={stylesName}
        graphData={data}
        chartWidth={chartWidth}
        chartHeight={chartHeight}
        palette={palette}
        onBarClick={handleBarClick}
      />
    </div>
  );

  return <div>{ChartTrayGraph}</div>;
}
export default DailyTotalBarChart;

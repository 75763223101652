import React, { useState, useEffect } from "react";
import Spacer from "./Spacer";
import checkmarkImage from "../assets/checkmark.svg";
import { getNewDeviceName } from "../functions/utilities";

const CheckboxMultiLine = ({ labelName, description, onCheck, isOn, isDevices }) => {
  const [isChecked, setIsChecked] = useState(isOn);

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 20 + "px",
    height: 20 + "px",
    borderRadius: 2 + "px",
    borderColor: "var(--green)",
    borderStyle: "solid",
    borderWidth: 1 + "px",
    backgroundColor: "white",
    cursor: "pointer",
    outline: "none",
  };

  const buttonStyleChecked = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 20 + "px",
    height: 20 + "px",
    borderRadius: 2 + "px",
    borderColor: "var(--green)",
    borderStyle: "solid",
    borderWidth: 1 + "px",
    backgroundColor: "var(--green)",
    cursor: "pointer",
    outline: "none",
  };

  const labelStyle = {
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14 + "px",
    lineHeight: 21 + "px",
    margin: 0,
  };

  const descriptionStyle = {
    color: "#000000",
    opacity: 0.5,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12 + "px",
    lineHeight: 18 + "px",
    margin: 0,
  };

  const columnStyle = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    height: "auto",
  };

  useEffect(() => {
    setIsChecked(isOn);
  }, [isOn]);

  let updatedLabelName = isDevices ? getNewDeviceName(labelName) : labelName;

  return (
    <div className={"row"}>
      <button
        style={isChecked ? buttonStyleChecked : buttonStyle}
        onClick={() => {
          let val = !isChecked;
          setIsChecked(val);
          onCheck(val);
        }}
      >
        {isChecked && <img src={checkmarkImage} alt={"checkbox is checked"} />}
      </button>
      <Spacer space={10} unit={"px"} />
      <section style={columnStyle}>
        <label
          style={isChecked ? { ...labelStyle, color: "#089BAB" } : labelStyle}
        >
          {updatedLabelName}
        </label>
        <p style={descriptionStyle}>{description}</p>
        <Spacer space={10} unit={"px"} />
      </section>
    </div>
  );
};

export default CheckboxMultiLine;

import ApiService from "./ApiService";
import axios from 'axios';

class UserService extends ApiService {

    static createAccountAdmin = async (account, user) => {
        try {
            let response = await axios.post(`${ApiService.BASE_URL}/accounts/${account._id}/create-first-user`, {user: user});
            return response.data; // user document
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static create = async (user, newUser) => {
        try {
            const rc = {
                headers: {'x-access-token': user.token}
            }

            let response = await axios.post(`${ApiService.BASE_URL}/accounts/${user.account._id}/users`, {user: newUser}, rc);
            return response.data; // user document
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static read = async (accountId, userId) => {
        try {
            let response = await axios.get(`${ApiService.BASE_URL}/accounts/${accountId}/users/${userId}`);
            return response.data.user; // user document
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static list = async (user) => {
        try {
            const rc = {
                headers: {'x-access-token': user.token}
            }

            let response = await axios.get(`${ApiService.BASE_URL}/accounts/${user.account._id}/users`, rc);
            return response.data.users; // user document
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static update = async (userLoggedIn, userToUpdate) => {
        try {
            const rc = {
                headers: {'x-access-token': userLoggedIn.token}
            }

            let response = await axios.put(`${ApiService.BASE_URL}/accounts/${userToUpdate.account._id ? userToUpdate.account._id : userToUpdate.account}/users/${userToUpdate._id}`, {user: userToUpdate}, rc);
            return response.data.user; // user document
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static delete = async (userLoggedIn, userToDelete) => {
        try {
            const rc = {
                headers: {'x-access-token': userLoggedIn.token}
            }

            let response = await axios.delete(`${ApiService.BASE_URL}/accounts/${userToDelete.account}/users/${userToDelete._id}`, rc);
            return response.data.user; // user document
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static setPassword = async (user) => {
        try {
            let response = await axios.put(`${ApiService.BASE_URL}/accounts/${user.account._id?user.account._id:user.account}/users/${user._id}/set-pwd`, {user: user});
            return response.data.user; // user document
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static resetPassword = async (user, resetToken) => {
        try {
            const rc = {
                headers: {'x-access-token': resetToken}
            }

            let response = await axios.put(
                `${ApiService.BASE_URL}/accounts/${user.account._id?user.account._id:user.account}/users/${user._id}/reset-pwd`, 
                {user: user},
                rc
            );
            return response.data.user; // user document
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static exists = async (account, emailAddress, username) => {
        try {
            let url = `${ApiService.BASE_URL}/accounts/${account._id}/user-exists?`;

            if (emailAddress) {
                url += `emailAddress=${encodeURIComponent(emailAddress)}`;
            } else {
                url += `username=${encodeURIComponent(username)}`;
            }
            let response = await axios.get(url);
            return response.data.exists;
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static sendInvite = async (account, user) => {
        try {
            console.log("Account",account);
            let response = await axios.get(`${ApiService.BASE_URL}/accounts/${account._id}/users/${user._id}/send-invite`);
            return response.data.emailSent;
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static sendPasswordReset = async (emailAddress) => {
        try{
            let response = await axios.post(`${ApiService.BASE_URL}/reset-psw`, {emailAddress: emailAddress});
            return response.data.emailSent;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static getAccessibleSites = async (user) => {
        try{
            const rc = {
                headers: {'x-access-token': user.token}
            }
            let response = await axios.get(`${ApiService.BASE_URL}/accounts/${user.account._id}/users/${user._id}/accessible-sites`, rc);
            return response.data.sites;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }
}

export default UserService;

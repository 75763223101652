import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  Fragment,
} from "react";
import { useNavigate } from "react-router-dom";
import EV54InventoryCardList from "./MobileViews/EV54Inventory/EV54InventoryCardList";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table/Table";
import Popup from "../../../../components/PopupMenu/Popup";
import { ReactComponent as MoreActionsIcon } from "../../../../assets/more-actions.svg";
import { ReactComponent as MoreActionsMobileIcon } from "../../../../assets/more-actions-mobile.svg";
import backIcon from "../../../../assets/back_icon.svg";
import ProductController from "../../../../controllers/ProductController";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./EV54InventoryTable.module.css";
import { useTranslation } from "react-i18next";

const EV54InventoryTable = ({
  goBackHandler,
  selectedSite,
  selectedGraph,
  windowHeight,
  inventoryTableData,
}) => {
  const { t } = useTranslation("truscriptInventoryTable");

  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(null);
  const [popupIndex, setPopupIndex] = useState(-1);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const navigate = useNavigate();

  const ProductControllerInstance = new ProductController();

  const _controllerRef = useRef(ProductControllerInstance);

  const actionMenu = [
    {
      type: "canisterManagement",
      description: t("canisterAction"),
    },
    {
      type: "deviceReplenishment",
      description: t("replenishmentAction"),
    },
  ];

  const handleAction = (action) => {
    switch (action.type) {
      case "canisterManagement":
        navigate("/devices", {
          state: {
            dataFromDashboard: {
              source: "EV54InventoryTable",
              actionItem: "canisterManagement",
              selectedTabIndex: 0,
              returnURL: "/dashboard",
              device: inventoryTableData[popupIndex]?.device,
              canister: inventoryTableData[popupIndex]?.canister,
              canisterNo: Number(
                inventoryTableData[popupIndex].canister.name.split("-")[2]
              ),
              canisterBarcode: inventoryTableData[popupIndex]?.canister.barcode,
              returnView: "EV-54",
              selectedSite:
                typeof selectedSite === "object" ? selectedSite : null,
              selectedGraph: selectedGraph,
            },
          },
        });
        break;
      case "deviceReplenishment":
        navigate("/devices", {
          state: {
            dataFromDashboard: {
              source: "EV54InventoryTable",
              selectedTabIndex: 2,
              returnURL: "/dashboard",
              returnView: "EV-54",
              selectedSite:
                typeof selectedSite === "object" ? selectedSite : null,
              selectedGraph: selectedGraph,
            },
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    (() => {
      const tempItems = [];

      try {
        inventoryTableData.forEach((tableRow, index) => {
          const actions = (
            <div
              className={styles.EV54InventoryTable__actionsContainer}
              onClick={(e) => {
                if (!showPopup) {
                  setShowPopup(true);
                  setPopupIndex(index);
                }
              }}
            >
              {smScreen ? <MoreActionsMobileIcon /> : <MoreActionsIcon />}
              {showPopup && popupIndex === index && (
                <Popup
                  menuItems={actionMenu}
                  onAction={(action) => {
                    setShowPopup(null);
                    setPopupIndex(-1);
                    handleAction(action);
                  }}
                  onClose={() => setShowPopup(null)}
                  rightJustify={true}
                />
              )}
            </div>
          );
          tempItems.push([
            tableRow?.device[0]?.name,
            tableRow?.canister?.name,
            _controllerRef.current.getDefaultName(tableRow.product[0]),
            tableRow?.min,
            tableRow?.max,
            tableRow?.quantity,
            actions,
          ]);
        });
        setItems(tempItems);
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [showPopup, popupIndex, inventoryTableData]);

  const tableHeight = (+windowHeight - 200).toString() + "px";

  const headers = [
    t("col0"),
    t("col1"),
    t("col2"),
    t("col3"),
    t("col4"),
    t("col5"),
    <div className={styles.EV54InventoryTable__actionsHeadingContainer}>
      {t("col6")}
    </div>,
  ];

  const DesktopView = (
    <Fragment>
      <div className={styles.EV54InventoryTable__header}>
        <h1>{t("title")}</h1>
        <p>{t("contact")}: 262-729-9202</p>
      </div>
      <div
        style={{ height: tableHeight }}
        className={styles.EV54InventoryTable__mainContainer}
      >
        {/* <p>{deviceName}</p> */}
        <div className={styles.EV54InventoryTable__tableContainer}>
          <Table
            padScrollBar={true}
            // searchText={searchText}
            headers={headers}
            records={items}
            showPopup={showPopup}
            popupIndex={popupIndex}
            tableRowHeight={37}
            scrollAdjustConstant={160}
          />
        </div>
        <div className={styles.EV54InventoryTable__buttonGroup}>
          <Button
            onClick={goBackHandler}
            labelName={t("backButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            minWidth={null}
          />
        </div>
      </div>
    </Fragment>
  );

  const MobileView = (
    <div className={styles.EV54InventoryTable__mainContainer}>
      <header className={styles.EV54InventoryTable__header}>
        <div className={styles.EV54InventoryTable__backButton}>
          <img src={backIcon} onClick={goBackHandler} />
        </div>
        <p>{t("title")}</p>
      </header>
      {
        <div className={styles.EV54InventoryTable__cardListContainer}>
          <EV54InventoryCardList headings={headers} data={items} />
        </div>
      }
    </div>
  );

  return smScreen ? MobileView : DesktopView;
};

export default EV54InventoryTable;

import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
} from "victory";

import { useTranslation } from "react-i18next";
import { ReactComponent as Square } from "../../../../assets/square.svg";
import styles from "./BatchesPerDayGraph.module.css";

const BatchesPerDayGraph = ({
  user,
  fetchingData,
  graphData,
  benchmarkData,
  graphTitle,
  palette,
  timeRange,
  onTimeRange,
  primarySeriesLabel,
  secondarySeriesLabel,
}) => {
  // For Testing
  // const benchmarkData = [
  //   { x: "Sun", y: 3 },
  //   { x: "Mon", y: 6 },
  //   { x: "Tues", y: 9 },
  //   { x: "Wed", y: 12 },
  //   { x: "Thurs", y: 15 },
  //   { x: "Fri", y: 18 },
  //   { x: "Sat", y: 21 },
  // ];

  // const graphData = [
  //   { x: "Sun", y: 3 },
  //   { x: "Mon", y: 12 },
  //   { x: "Tues", y: 10 },
  //   { x: "Wed", y: 15 },
  //   { x: "Thurs", y: 10 },
  //   { x: "Fri", y: 7 },
  //   { x: "Sat", y: 6 },
  // ];

  // const benchmarkData = [];
  // const graphData = [];

  const { t } = useTranslation(["trupakDashboard", "graphsGeneral"]);
  const style = getStyle(palette);
  const tickValues = getTickValues();

  const Legend = (
    <div className={styles.BatchesPerDayGraph__legendContainer}>
      <div className={styles.BatchesPerDayGraph__legendGroup}>
        <div className={styles.BatchesPerDayGraph__mainDataLine}></div>
        <p>{primarySeriesLabel}</p>
      </div>
      <div className={styles.BatchesPerDayGraph__legendGroup}>
        <div className={styles.BatchesPerDayGraph__benchmarkLine}></div>
        <p>{secondarySeriesLabel}</p>
      </div>
    </div>
  );

  let mainData = [];

  const ticks = 5;

  // when ticks = 5, returns [0, 1, 2, 3, 4, 5]
  // or, using the npm package lodash, the same as const tickValues = _.range(6);
  const tickValuesForYAxis = Array.from({ length: ticks + 1 }, (_, i) => i);

  function getMaxValueInDataSet(dataSet) {
    let maxValue = -1;
    dataSet.forEach((num) => {
      if (num > maxValue) {
        maxValue = num;
      }
    });
    return Math.ceil(maxValue / ticks) * ticks;
  }

  const maxYValueInMainData = getMaxValueInDataSet(
    graphData.map((dataObj) => dataObj.y)
  );

  const maxYValueForBenchmarkData = getMaxValueInDataSet(
    benchmarkData.map((dataObj) => dataObj.y)
  );

  const maxYValue =
    maxYValueForBenchmarkData >= maxYValueInMainData
      ? maxYValueForBenchmarkData
      : maxYValueInMainData;

  const yRange = [0, maxYValue];

  const domain = { y: [0, ticks] };

  const tickFormatBar = (range) => (t) =>
    ((t * (range[1] - range[0])) / ticks).toFixed(1);

  const normalize = (range, props) => (datum) =>
    datum[props] / ((range[1] - range[0]) / ticks);

  graphData.forEach((dataObj) => {
    mainData.push({
      x: dataObj.x,
      y: dataObj.y,
    });
  });

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  let xValueNoGraphDataFound = 176;
  let yValueNoGraphDataFound = 133;

  if (user?.language?.type === "es") {
    xValueNoGraphDataFound = 138;
  } else if (user?.language?.type === "fr") {
    xValueNoGraphDataFound = 140;
  }

  const Chart = (
    <section className={styles.BatchesPerDayGraph__container}>
      <h2>{graphTitle}</h2>
      <VictoryChart
        domain={domain}
        domainPadding={{ x: 0, y: 0 }}
        theme={VictoryTheme.material}
        width={450}
        height={268}
      >
        {!fetchingData && !graphData.length && (
          <VictoryLabel
            x={xValueNoGraphDataFound}
            y={yValueNoGraphDataFound}
            style={{
              fontFamily: "inherit",
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: "normal",
              fill: "	#909090",
            }}
            text={fetchingData ? "" : t("graphsGeneral:noData")}
          />
        )}

        {/* shared independent axis (x-axis) */}
        <VictoryAxis
          scale="linear"
          standalone={false}
          style={style.axisDays}
          tickValues={tickValues}
        />
        {/*
       y-axis
     */}
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          offsetX={50}
          orientation="left"
          standalone={false}
          tickValues={tickValuesForYAxis}
          tickFormat={tickFormatBar(yRange)}
          style={{
            axis: { stroke: "#909090", strokeWidth: 1 },
            ticks: { stroke: "#909090", strokeWidth: 1 },
            tickLabels: {
              fill:
                !fetchingData && graphData.length ? "#909090" : "transparent",
              fontSize: 11,
              width: 40,
            },
            grid: {
              stroke: "#CFD8DC",
              strokeDasharray: "0, 0",
            },
          }}
        />
        {/* dataset one */}
        <VictoryLine
          data={mainData}
          y={normalize(yRange, "y")}
          scale={{ x: "linear", y: "linear" }}
          standalone={false}
          style={style.lineOne}
          labelComponent={<VictoryLabel dy={-6} />}
        />

        {/* dataset two */}
        {!fetchingData && graphData.length && (
          <VictoryLine
            data={benchmarkData}
            y={normalize(yRange, "y")}
            scale={{ x: "linear", y: "linear" }}
            standalone={false}
            style={style.lineTwo}
          />
        )}
      </VictoryChart>
      {Legend}
    </section>
  );

  const TimeRangeSelection = (
    <div
      className={styles.BatchesPerDayGraph__timeRangeContainer}
      onClick={handleStopPropagation}
    >
      <div
        className={styles.BatchesPerDayGraph__timeRangeOption}
        onClick={() => onTimeRange("month")}
      >
        <div
          className={
            timeRange === "month"
              ? `${styles.BatchesPerDayGraph__squareContainer} ${styles["BatchesPerDayGraph__squareContainer--active"]}`
              : styles.BatchesPerDayGraph__squareContainer
          }
        >
          <Square />
        </div>
        <p>{t("trupakDashboard:lastMonthLabel")}</p>
      </div>
      <div
        className={styles.BatchesPerDayGraph__timeRangeOption}
        onClick={() => onTimeRange("week")}
      >
        <div
          className={
            timeRange === "week"
              ? `${styles.BatchesPerDayGraph__squareContainer} ${styles["BatchesPerDayGraph__squareContainer--active"]}`
              : styles.BatchesPerDayGraph__squareContainer
          }
        >
          <Square />
        </div>
        <p>{t("trupakDashboard:lastWeekLabel")}</p>
      </div>
      <div
        className={styles.BatchesPerDayGraph__timeRangeOption}
        onClick={() => onTimeRange("day")}
      >
        <div
          className={
            timeRange === "day"
              ? `${styles.BatchesPerDayGraph__squareContainer} ${styles["BatchesPerDayGraph__squareContainer--active"]}`
              : styles.BatchesPerDayGraph__squareContainer
          }
        >
          <Square />
        </div>
        <p>{t("trupakDashboard:lastDayLabel")}</p>
      </div>
      <div
        className={styles.BatchesPerDayGraph__timeRangeOption}
        onClick={() => onTimeRange("12 hours")}
      >
        <div
          className={
            timeRange === "12 hours"
              ? `${styles.BatchesPerDayGraph__squareContainer} ${styles["BatchesPerDayGraph__squareContainer--active"]}`
              : styles.BatchesPerDayGraph__squareContainer
          }
        >
          <Square />
        </div>
        <p>{t("trupakDashboard:last12HoursLabel")}</p>
      </div>
    </div>
  );

  return (
    <div>
      {Chart}
      {TimeRangeSelection}
    </div>
  );
};

function getTickValues() {
  return ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
}

function getStyle(palette) {
  const COLOR_1 = palette[0];
  const COLOR_2 = palette[1];

  return {
    parent: {
      fontFamily: "inherit",
      background: "transparent",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      width: "450px",
      height: "278px",
    },
    title: {
      fontFamily: "inherit",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: "normal",
    },
    labelNumber: {
      textAnchor: "middle",
      fill: "#ffffff",
      fontFamily: "inherit",
      fontSize: "14px",
    },

    // x axis
    axisDays: {
      axis: { stroke: "black", strokeWidth: 1 },
      ticks: {
        size: ({ tick }) => {
          if (
            ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"].includes(tick)
          ) {
            return 5;
          } else {
            return 0;
          }
        },
        stroke: "#CFD8DC",
        strokeWidth: 2,
      },
      grid: {
        stroke: "transparent",
      },
      tickLabels: { fill: "#455A64", fontSize: 11 },
    },

    // mainData line
    axisOne: {
      axis: { stroke: "transparent", strokeWidth: 0 },
      ticks: {
        stroke: "transparent",
      },
      grid: {
        stroke: "#CFD8DC",
        strokeDasharray: "0, 0",
      },
      tickLabels: {
        fill: "#455A64",
      },
    },
    labelOne: {
      fill: COLOR_1,
      fontFamily: "inherit",
      fontSize: 12,
      fontStyle: "italic",
    },
    lineOne: {
      data: { stroke: COLOR_1, strokeWidth: 3 },
    },
    axisOneCustomLabel: {
      fill: COLOR_1,
      fontFamily: "inherit",
      fontWeight: 300,
      fontSize: 21,
    },

    victoryScatterOne: {
      data: {
        fill: COLOR_1,
        stroke: "black",
        strokeOpacity: 0.5,
        strokeWidth: 1,
      },
    },

    // benchmark Data line
    lineTwo: {
      data: { stroke: COLOR_2, strokeWidth: 2 },
    },
    victoryScatterTwo: {
      data: {
        fill: COLOR_2,
        stroke: "black",
        strokeOpacity: 0.5,
        strokeWidth: 2,
      },
    },
  };
}

export default BatchesPerDayGraph;

import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getTimeRangeDay,
  getTimeRange12Hours,
  getTimeRangeWeek,
  getTimeRangeMonth,
  getTimeRangeYear,
} from "../../../../functions/dates";
import TruPakDashboardController from "../../TruPakDashboard/TruPakDashboardController";

import CanisterTrayRatioGraph from "../graphs/CanisterTrayRatioGraph";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "../TruPakDashboard.module.css";
import stylesMobile from "../MobileViews/TruPakDashboardMobileView.module.css";
import { useTranslation } from "react-i18next";

const GetCanisterTrayRatioGraph = ({
  user,
  selectedDevices,
  loader,
  onShowMedicationsDispensed,
  startDate,
  stopDate,
  onStartDate,
  onStopDate,
  onShowCanisterMedicationsDispensed,
  onShowTrayMedicationsDispensed,
  showDropdowns,
  onShowDropdowns,
}) => {
  const { t } = useTranslation("trupakDashboard");

  const [timeRange, setTimeRange] = useState("month");
  const [canisterTrayGraphData, setCanisterTrayGraphData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [Graph, setGraph] = useState(<div></div>);
  const _controllerRef = useRef(TruPakDashboardController);
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    let dateRangeObject;
    if (timeRange === "12 hours") {
      dateRangeObject = getTimeRange12Hours();
    } else if (timeRange === "day") {
      dateRangeObject = getTimeRangeDay();
    } else if (timeRange === "week") {
      dateRangeObject = getTimeRangeWeek();
    } else if (timeRange === "month") {
      dateRangeObject = getTimeRangeMonth();
    } else if (timeRange === "year") {
      dateRangeObject = getTimeRangeYear();
    }
    onStartDate(dateRangeObject.startDate);
    onStopDate(dateRangeObject.stopDate);
  }, [timeRange, onStartDate, onStopDate]);

  const handleTimeRangeCanisterTray = (stringVal) => {
    setTimeRange(stringVal);
  };

  useEffect(() => {
    const defaultCanisterTrayGraphData = [
      {
        x: t("canisterLabel"),
        y: 0,
        text: t("canisterLabel"),
      },
      {
        x: t("trayLabel"),
        y: 0,
        text: t("trayLabel"),
      },
      // { x: t("halfLabel"), y: partialPillCount, text: t("halfLabel") },
    ];

    if (startDate && stopDate && selectedDevices.length && showDropdowns) {
      const utcStartDate = startDate.toUTCString();
      const utcStopDate = stopDate.toUTCString();

      async function updateCanisterTrayRatioGraph() {
        // initializations
        let tempCanisterPillCount = 0;
        let tempFullPillCount = 0;
        let tempPartialPillCount = 0;

        for (let i = 0; i < selectedDevices.length; i++) {
          const device = selectedDevices[i];
          const site = selectedDevices[i].site;

          await _controllerRef.current
            .getTruPakCanisterUtilization(
              user,
              site,
              device,
              utcStartDate,
              utcStopDate,
              false // do not include detail
            )
            .then((DTO) => {
              const batches = DTO.results.batches;
              for (let batch of batches) {
                tempCanisterPillCount +=
                  batch.totalQty - batch.trayWholeQty - batch.trayPartialQty;
                tempFullPillCount += batch.trayWholeQty;
                tempPartialPillCount += batch.trayPartialQty;
              }
              if (
                DTO?.results?.batches?.length &&
                i === selectedDevices.length - 1
              ) {
                setCanisterTrayGraphData([
                  {
                    x: t("canisterLabel"),
                    y: tempCanisterPillCount,
                    text: t("canisterLabel"),
                  },
                  {
                    x: t("trayLabel"),
                    y: tempFullPillCount + tempPartialPillCount,
                    text: t("trayLabel"),
                  },
                ]);
              } else if (i === selectedDevices.length - 1) {
                setCanisterTrayGraphData([]);
              }
            });
        }

        setFetchingData(false);
      }
      setCanisterTrayGraphData(defaultCanisterTrayGraphData);
      setFetchingData(true);
      updateCanisterTrayRatioGraph();
    }
  }, [startDate, stopDate, selectedDevices, user, showDropdowns, t]);

  useEffect(() => {
    let tempGraph;
    if (selectedDevices.length) {
      tempGraph = (
        <CanisterTrayRatioGraph
          user={user}
          data={canisterTrayGraphData}
          fetchingData={fetchingData}
          palette={["#318ce7", "#FC8A17", "#A9A9A9"]}
          timeRange={timeRange}
          onTimeRange={handleTimeRangeCanisterTray}
          onShowMedicationsDispensed={onShowMedicationsDispensed}
          onShowCanisterMedicationsDispensed={
            onShowCanisterMedicationsDispensed
          }
          onShowTrayMedicationsDispensed={onShowTrayMedicationsDispensed}
          onShowDropdowns={onShowDropdowns}
          className={
            smScreen
              ? `${stylesMobile.TruPakDashboard__graphContainerMobile} ${stylesMobile["TruPakDashboard__graphContainerMobile--paddingBottom"]}`
              : `${styles.TruPakDashboard__graphContainerMobile} ${styles["TruPakDashboard__graphContainer--paddingBottom"]}`
          }
          canisterLabel={t("canisterLabel")}
          trayLabel={t("trayLabel")}
        />
      );
    } else {
      tempGraph = null;
    }
    setGraph(tempGraph);
  }, [
    canisterTrayGraphData,
    onShowCanisterMedicationsDispensed,
    onShowTrayMedicationsDispensed,
    selectedDevices,
    smScreen,
    onShowMedicationsDispensed,
    timeRange,
    showDropdowns,
    onShowDropdowns,
    user,
    t,
  ]);
  return (
    <div style={{ position: "relative" }}>
      {fetchingData && loader}
      {Graph}
    </div>
  );
};

export default GetCanisterTrayRatioGraph;

import { Dropdown } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as MoreActionsIconMobile } from "../../../assets/more-actions-mobile.svg";
import { ReactComponent as MoreActionsIcon } from "../../../assets/more-actions.svg";
import { ScreenContext } from "../../../contexts/ScreenContext";
import RoleCheckService from "../../../services/RoleCheckService";

const PendingDispensingOrderItem = ({
  user,
  item,
  onActivateSubstituteProductModal,
  onActivateAddProductModal,
  onActivateChangePatientLocationModal,
  onActivateChangePatientLanguageModal,
  onActivatePriorityModal,
  onHandleSelectedItem,
}) => {
  const { t } = useTranslation("pendingDispensingOrder");
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  let actionListItems = [
    {
      type: "Change Patient Location",
      description: t("menuItem4"),
    },
    {
      type: "Change Patient Language",
      description: t("menuItem6"),
    },
    {
      type: "Change Priority",
      description: t("menuItem5"),
    },
  ];

  if (!RoleCheckService.changePatientLocationFromOrderDetails(user)) {
    const index = actionListItems.findIndex(
      (o) => o.type === "Change Patient Location"
    );
    actionListItems.splice(index, 1);
  }

  if (!RoleCheckService.changePatientLanguageFromOrderDetails(user)) {
    const index = actionListItems.findIndex(
      (o) => o.type === "Change Patient Language"
    );
    actionListItems.splice(index, 1);
  }


  if (
    RoleCheckService.addProductFromOrderDetails(user) &&
    (!item.product ||
      (item?.errorTypes?.length && item.errorTypes.includes("NO_PRODUCT")))
  ) {
    actionListItems.splice(1, 0, {
      type: "Add Product",
      description: t("menuItem0"),
    });
  }

  if (
    RoleCheckService.substituteProductFromOrderDetails(user) &&
    item.product &&
    item.product.packageId
  ) {
    actionListItems.splice(1, 0, {
      type: "Substitute Product",
      description: t("menuItem1"),
    });
  }

  if (!user || (user && !RoleCheckService.addProductFromOrderDetails(user))) {
    const index = actionListItems.findIndex((o) => o.type === "Add Product");
    actionListItems.splice(index, 1);
  }

  const handleAction = (action) => {
    switch (action.type) {
      case "Add Product":
        onActivateAddProductModal();
        onHandleSelectedItem(item);
        break;
      case "Substitute Product":
        onActivateSubstituteProductModal();
        onHandleSelectedItem(item);
        break;
      case "Change Patient Location":
        onActivateChangePatientLocationModal();
        onHandleSelectedItem(item);
        break;
      case "Change Patient Language":
        onActivateChangePatientLanguageModal();
        onHandleSelectedItem(item);
        break;
      case "Change Priority":
        onActivatePriorityModal();
        onHandleSelectedItem(item);
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      trigger={["click"]}
      menu={{
        items: actionListItems.map((item) => ({
          key: item.type,
          label: (
            <div onClick={() => handleAction(item)}>{item.description}</div>
          ),
        })),
      }}
    >
      <div style={{ cursor: "pointer", margin: "4px" }}>
        {smScreen ? <MoreActionsIconMobile /> : <MoreActionsIcon />}
      </div>
    </Dropdown>
  );
};

export default PendingDispensingOrderItem;

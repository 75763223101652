import axios from "axios";
import ApiService from "./ApiService";

class ClientFDBService extends ApiService {
  static async getImagesByNdc(user, ndc) {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb/images?ndc=${ndc}`,
        rc
      );
      return response.data.images;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  }

  static async getFdbPackagedDrugByNdc(user, ndc) {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb?ndc=${ndc}`,
        rc
      );
      return response.data.fdbPackagedDrug;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  }

  static async getFdbModifications(user, token) {
    try {
      const url = `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb/modifications`;
      const response = await axios.get(url, {
        headers: { "x-access-token": user.token },
        timeout: 20 * 60 * 1000,
        cancelToken: token,
      });
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  }

  static async updateProductsByNdc(user, packageIds) {
    try {
      const url = `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb/products`;
      const response = await axios.put(
        url,
        {
          packageIds: packageIds,
        },
        {
          headers: { "x-access-token": user.token },
        }
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  }
}

export default ClientFDBService;

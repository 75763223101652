import EmailValidator from 'email-validator';

export default function validate(values) {
    let errors = {};

    if(!values.name) {
        errors.name = 'missingSiteName'
    }

    if(values.packagingMethod.type === 'POUCH' && (!values.facilityIds || values.facilityIds.length === 0)) {
        errors.missingFacilityId = 'missingFacilityId';
    }

    // if(!values.shippingAddress?.street1) {
    //     if(!errors.shippingAddress){errors.shippingAddress = {}}
    //     errors.shippingAddress.street1 = 'missingStreet'
    // }

    // if(!values.shippingAddress?.city) {
    //     if(!errors.shippingAddress){errors.shippingAddress = {}}
    //     errors.shippingAddress.city = 'missingCity'
    // }

    // if(!values.shippingAddress?.state) {
    //     if(!errors.shippingAddress){errors.shippingAddress = {}}
    //     errors.shippingAddress.state = 'missingState'
    // }

    // if(!values.shippingAddress?.zipcode) {
    //     if(!errors.shippingAddress){errors.shippingAddress = {}}
    //     errors.shippingAddress.zipcode = 'missingZip'
    // }

    // if(!values.shippingAddress?.country) {
    //     if(!errors.shippingAddress){errors.shippingAddress = {}}
    //     errors.shippingAddress.country = 'missingCountry'
    // }

    // if(!values.billingAddress?.street1) {
    //     if(!errors.billingAddress){errors.billingAddress = {}}
    //     errors.billingAddress.street1 = 'missingStreet'
    // }

    // if(!values.billingAddress?.city) {
    //     if(!errors.billingAddress){errors.billingAddress = {}}
    //     errors.billingAddress.city = 'missingCity'
    // }

    // if(!values.billingAddress?.state) {
    //     if(!errors.billingAddress){errors.billingAddress = {}}
    //     errors.billingAddress.state = 'missingState'
    // }

    // if(!values.billingAddress?.zipcode) {
    //     if(!errors.billingAddress){errors.billingAddress = {}}
    //     errors.billingAddress.zipcode = 'missingZip'
    // }

    // if(!values.billingAddress?.country) {
    //     if(!errors.billingAddress){errors.billingAddress = {}}
    //     errors.billingAddress.country = 'missingCountry'
    // }

    if(values.primaryContact && values.primaryContact.emailAddress && !EmailValidator.validate(values.primaryContact.emailAddress)){

        errors.emailAddress = 'invalidEmail'
    }

    if (values.timeCodes) {
        errors.timeCodes = 'Empty or invalid values in time codes'
    }

    return errors;
}

import axios from "axios";
import ApiService from "./ApiService";

class NotificationsService extends ApiService {
  static get = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${user.defaultSite._id}/notifications`,
        rc
      );


      return response.data.notifications;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static acknowledge = async (user, notification) => {    
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.put(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${user.defaultSite._id}/notifications`,
        {
          notification: { 
            ...notification,
            isAcknowledged: true,
            acknowledgedBy: user,
            acknowledgedDate: new Date()
          }
        },
        rc
      );
      return response.data.notifications;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default NotificationsService;
import { Button, Modal } from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const ProductsModalForOrderGenerator = ({
  onCloseModalForOrderGenerator,
  onPackageId,
  packageId,
  productPrimaryName,
  onHideProductsTable,
}) => {
  const handleOk = () => {
    onPackageId(packageId);
    onHideProductsTable();
    onCloseModalForOrderGenerator();
  };

  const handleCancel = () => {
    onCloseModalForOrderGenerator();
  };

  return (
    <Modal
      title="Confirm Selected Product"
      open={true}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Accept
        </Button>,
      ]}
    >
      <p>{`Product: ${productPrimaryName}`}</p>
      <p>{`Package ID: ${packageId}`}</p>
    </Modal>
  );
};
export default ProductsModalForOrderGenerator;

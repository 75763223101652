import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import TruPakDashboardController from "../../../../TruPakDashboard/TruPakDashboardController";
import MetricsChartViewTrays from "./MetricsChartViewTrays";
import MetricsChartViewCanisters from "./MetricsChartViewCanisters";
const MetricsChartView = ({
  user,
  activeKey,
  selectedDayTray,
  onSelectedDayTray,
  selectedDayCanister,
  onSelectedDayCanister,
  selectedDevices,
  selectedSites,
  startDateCurrentWeek,
  stopDateCurrentWeek,
  startDateLastWeek,
  stopDateLastWeek,
  selectedDateRange,
  applicableDates,
  onApplicableDates,
  trayProducts,
  onTrayProducts,
  onTrayProductsSelectedDay,
  canisterProducts,
  onCanisterProducts,
  onCanisterProductsSelectedDay,
  trayProductSelectionLookup,
  onTrayProductSelectionLookup,
  canisterProductSelectionLookup,
  onCanisterProductSelectionLookup,
  onShowDropdowns,
}) => {
  const { t } = useTranslation("trupakDashboard");
  const [batchesCurrentWeek, setBatchesCurrentWeek] = useState([]);
  const [batchesLastWeek, setBatchesLastWeek] = useState([]);
  const [batchesSelectedDateRange, setBatchesSelectedDateRange] = useState([]);
  const _controllerRef = useRef(TruPakDashboardController);

  const updateBatches = useCallback(
    /**
     * Sets:
     *  - batchesCurrentWeek
     *  - batchesLastWeek
     *  - batchesSelectedDateRange
     * Example of call: updateBatches(utcStartDate, utcStopDate, "current");
     **/
    async (utcStartDate, utcStopDate, dateMode) => {
      let batchesForAllSites = [];
      const updateBatchesForAllSites = (newBatchesToAdd) => {
        batchesForAllSites = [...batchesForAllSites, ...newBatchesToAdd];
      };
      for (const device of selectedDevices) {
        for (const site of selectedSites) {
          await _controllerRef.current
            .getTruPakCanisterUtilization(
              user,
              site,
              device,
              utcStartDate,
              utcStopDate,
              true // include detail
            )
            .then((DTO) => {
              updateBatchesForAllSites(DTO.results.batches);
            });
        }
      }
      if (dateMode === "current") {
        setBatchesCurrentWeek(batchesForAllSites);
      } else if (dateMode === "last") {
        setBatchesLastWeek(batchesForAllSites);
      } else if (dateMode === "selectedDateRange") {
        setBatchesSelectedDateRange(batchesForAllSites);
      }
    },
    [selectedDevices, selectedSites, user]
  );

  // FOR BATCHES CURRENT WEEK
  useEffect(() => {
    /**
     * Calls updateBatches for the current week
     **/
    if (
      startDateCurrentWeek &&
      stopDateCurrentWeek &&
      selectedDevices.length &&
      selectedSites.length
    ) {
      const utcStartDate = startDateCurrentWeek.toUTCString(); // convert local time to UTC
      const utcStopDate = stopDateCurrentWeek.toUTCString(); // convert locatl time to UTC
      updateBatches(utcStartDate, utcStopDate, "current");
    }
  }, [
    startDateCurrentWeek,
    stopDateCurrentWeek,
    selectedDevices,
    selectedSites,
    updateBatches,
  ]);

  // PREVIOUS WEEK
  useEffect(() => {
    /**
     * Calls updateBatches for the previous week
     **/
    if (
      startDateLastWeek &&
      stopDateLastWeek &&
      selectedDevices.length &&
      selectedSites.length
    ) {
      const utcStartDate = startDateLastWeek.toUTCString(); // convert local time to UTC
      const utcStopDate = stopDateLastWeek.toUTCString(); // convert locatl time to UTC
      updateBatches(utcStartDate, utcStopDate, "last");
    }
  }, [
    startDateLastWeek,
    stopDateLastWeek,
    selectedDevices,
    selectedSites,
    updateBatches,
  ]);

  // SELECTED DATE RANGE
  useEffect(() => {
    /**
     * Calls updateBatches for the selected date range
     **/
    if (
      selectedDateRange &&
      selectedDateRange.startDate &&
      selectedDateRange.endDate &&
      selectedDevices.length &&
      selectedSites.length
    ) {
      const utcStartDate = selectedDateRange.startDate.toUTCString(); // convert local time to UTC
      const utcStopDate = selectedDateRange.endDate.toUTCString(); // convert locatl time to UTC
      updateBatches(utcStartDate, utcStopDate, "selectedDateRange");
    }
  }, [selectedDateRange, selectedDevices, selectedSites, updateBatches]);

  if (activeKey === "tray") {
    return (
      <MetricsChartViewTrays
        user={user}
        selectedDayTray={selectedDayTray}
        onSelectedDayTray={onSelectedDayTray}
        selectedDayCanister={selectedDayCanister}
        onSelectedDayCanister={onSelectedDayCanister}
        selectedDevices={selectedDevices}
        selectedSites={selectedSites}
        selectedDateRange={selectedDateRange}
        applicableDates={applicableDates}
        onApplicableDates={onApplicableDates}
        trayProducts={trayProducts}
        onTrayProducts={onTrayProducts}
        onTrayProductsSelectedDay={onTrayProductsSelectedDay}
        trayProductSelectionLookup={trayProductSelectionLookup}
        onTrayProductSelectionLookup={onTrayProductSelectionLookup}
        onShowDropdowns={onShowDropdowns}
        batchesCurrentWeek={batchesCurrentWeek}
        batchesLastWeek={batchesLastWeek}
        batchesSelectedDateRange={batchesSelectedDateRange}
      />
    );
  } else
    return (
      <MetricsChartViewCanisters
        user={user}
        selectedDayTray={selectedDayTray}
        onSelectedDayTray={onSelectedDayTray}
        selectedDayCanister={selectedDayCanister}
        onSelectedDayCanister={onSelectedDayCanister}
        selectedDateRange={selectedDateRange}
        applicableDates={applicableDates}
        onApplicableDates={onApplicableDates}
        canisterProducts={canisterProducts}
        onCanisterProducts={onCanisterProducts}
        onCanisterProductsSelectedDay={onCanisterProductsSelectedDay}
        canisterProductSelectionLookup={canisterProductSelectionLookup}
        onCanisterProductSelectionLookup={onCanisterProductSelectionLookup}
        onShowDropdowns={onShowDropdowns}
        batchesCurrentWeek={batchesCurrentWeek}
        batchesLastWeek={batchesLastWeek}
        batchesSelectedDateRange={batchesSelectedDateRange}
      />
    );
};

export default MetricsChartView;

export const TruPakInputTypes = [
  "text",
  "text",
  "text",
  "text",
  "text",
  "number",
  "text",
  "text",
  "text",
  "text",
  "text",
  "text",
  "text",
  "text",
  "text",
];

import { VictoryChart, VictoryLine, VictoryAxis, VictoryTheme } from "victory";
import { useTranslation } from "react-i18next";
import styles from "./ScriptsByHourGraph.module.css";

const ScriptsByHourGraph = ({
  user,
  fetchingData,
  graphData,
  benchmarkValue,
  graphTitle,
  palette,
  primarySeriesLabel,
  secondarySeriesLabel,
}) => {
  // For Testing
  // const benchmarkValue = 20;
  // const graphData = [
  //   { x: "12 AM", y: 20 },
  //   { x: "1 AM", y: 23 },
  //   { x: "2 AM", y: 17 },
  //   { x: "4 AM", y: 16 },
  //   { x: "5 AM", y: 22 },
  //   { x: "6 AM", y: 31 },
  //   { x: "7 AM", y: 33 },
  //   { x: "8 AM", y: 29 },
  //   { x: "9 AM", y: 21 },
  //   { x: "10 AM", y: 17 },
  //   { x: "11 AM", y: 14 },
  //   { x: "12 PM", y: 21 },
  //   { x: "1 PM", y: 24 },
  //   { x: "2 PM", y: 22 },
  //   { x: "3 PM", y: 15 },
  //   { x: "4 PM", y: 12 },
  //   { x: "5 PM", y: 19 },
  //   { x: "6 PM", y: 23 },
  //   { x: "7 PM", y: 20 },
  //   { x: "8 PM", y: 13 },
  //   { x: "9 PM", y: 17 },
  //   { x: "10 PM", y: 21 },
  //   { x: "11 PM", y: 18 },
  // ];

  const { t } = useTranslation("graphsGeneral");

  const style = getStyle(palette, fetchingData, graphData);
  const dataSetTwo =
    !fetchingData && graphData.length
      ? getBenchmarkDataSet(benchmarkValue)
      : null;

  const ticks = 5;

  // when ticks = 5, returns [0, 1, 2, 3, 4, 5]
  // or, using the npm package lodash, the same as const tickValues = _.range(6);
  const tickValuesYAxis = Array.from({ length: ticks + 1 }, (_, i) => i);

  function getMaxValueInDataSet(dataSet) {
    let maxValue = -1;
    dataSet.forEach((num) => {
      if (num > maxValue) {
        maxValue = num;
      }
    });
    return Math.ceil(maxValue / ticks) * ticks;
  }

  const maxYValueScriptsFilled = getMaxValueInDataSet(
    graphData.map((dataObj) => dataObj.y)
  );

  let maxYValue = maxYValueScriptsFilled;
  if (benchmarkValue && benchmarkValue > maxYValue) {
    maxYValue = benchmarkValue;
  }

  const yRange = [0, maxYValue];

  const domain = { y: [0, ticks] };

  const tickFormatBar = (range) => (t) =>
    ((t * (range[1] - range[0])) / ticks).toFixed(1);

  const normalize = (range, props) => (datum) =>
    datum[props] / ((range[1] - range[0]) / ticks);

  const tickValues = getTickValues();

  const Legend = (
    <div className={styles.ScriptsByHourGraph__legendContainer}>
      <div className={styles.ScriptsByHourGraph__legendGroup}>
        <div className={styles.ScriptsByHourGraph__mainDataLine}></div>
        <p>{primarySeriesLabel}</p>
      </div>
      <div className={styles.ScriptsByHourGraph__legendGroup}>
        <div className={styles.ScriptsByHourGraph__benchmarkLine}></div>
        <p>{secondarySeriesLabel}</p>
      </div>
    </div>
  );

  let mainData = [];

  graphData.forEach((dataObj, index) => {
    mainData.push({
      x: dataObj.x,
      y: dataObj.y,
    });
  });

  // const maxYValueInGetDataSet1 =
  //   mainData && mainData.length ? getMaxYValueInMainData(mainData) : 0;

  let noDataFoundClass = `${styles.ScriptsByHourGraph__noDataFoundMsg} ${styles["ScriptsByHourGraph__noDataFoundMsg--English"]}`;

  if (user?.language?.type === "es") {
    noDataFoundClass = `${styles.ScriptsByHourGraph__noDataFoundMsg} ${styles["ScriptsByHourGraph__noDataFoundMsg--Spanish"]}`;
  } else if (user?.language?.type === "fr") {
    noDataFoundClass = `${styles.ScriptsByHourGraph__noDataFoundMsg} ${styles["ScriptsByHourGraph__noDataFoundMsg--French"]}`;
  }

  return (
    <section className={styles.ScriptsByHourGraph__container}>
      <h2>{graphTitle}</h2>
      <VictoryChart
        domain={domain}
        domainPadding={{ x: 0, y: 0 }}
        theme={VictoryTheme.material}
        width={450}
        height={272}
      >
        {/* shared independent axis (x-axis) */}
        <VictoryAxis
          scale="linear"
          standalone={false}
          style={style.axisYears}
          tickValues={tickValues}
          tickFormat={(x, index) => {
            if (index % 3 === 0) {
              return tickValues[index];
            } else {
              return "";
            }
          }}
        />
        {/*
            y-axis
          */}
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          tickValues={tickValuesYAxis}
          tickFormat={tickFormatBar(yRange)}
          // domain={[0, maxYValueInGetDataSet1 > 0 ? maxYValueInGetDataSet1 : 1]}
          offsetX={50}
          orientation="left"
          standalone={false}
          style={style.axisOne}
        />
        {/* dataset one */}
        {!fetchingData && graphData.length && (
          <VictoryLine
            data={mainData}
            y={normalize(yRange, "y")}
            // domain={{
            //   y: [0, maxYValueInGetDataSet1],
            // }}
            scale={{ x: "linear", y: "linear" }}
            standalone={false}
            style={style.lineOne}
          />
        )}

        {/* dataset two */}
        {!fetchingData && graphData.length && (
          <VictoryLine
            data={dataSetTwo}
            y={normalize(yRange, "y")}
            scale={{ x: "linear", y: "linear" }}
            standalone={false}
            style={style.lineTwo}
          />
        )}
      </VictoryChart>
      {Legend}
      {!fetchingData && !graphData.length && (
        <p className={noDataFoundClass}>{t("graphsGeneral:noData")}</p>
      )}
    </section>
  );
};

function getBenchmarkDataSet(benchmarkValue) {
  return [
    {
      x: "12 AM",
      y: benchmarkValue,
    },
    {
      x: "1 AM",
      y: benchmarkValue,
    },
    {
      x: "2 AM",
      y: benchmarkValue,
    },
    {
      x: "3 AM",
      y: benchmarkValue,
    },
    {
      x: "4 AM",
      y: benchmarkValue,
    },
    {
      x: "5 AM",
      y: benchmarkValue,
    },
    {
      x: "6 AM",
      y: benchmarkValue,
    },
    {
      x: "7 AM",
      y: benchmarkValue,
    },
    {
      x: "8 AM",
      y: benchmarkValue,
    },
    {
      x: "9 AM",
      y: benchmarkValue,
    },
    {
      x: "10 AM",
      y: benchmarkValue,
    },
    {
      x: "11 AM",
      y: benchmarkValue,
    },
    {
      x: "12 PM",
      y: benchmarkValue,
    },
    {
      x: "1 PM",
      y: benchmarkValue,
    },
    {
      x: "2 PM",
      y: benchmarkValue,
    },
    {
      x: "3 PM",
      y: benchmarkValue,
    },
    {
      x: "4 PM",
      y: benchmarkValue,
    },
    {
      x: "5 PM",
      y: benchmarkValue,
    },
    {
      x: "6 PM",
      y: benchmarkValue,
    },
    {
      x: "7 PM",
      y: benchmarkValue,
    },
    {
      x: "8 PM",
      y: benchmarkValue,
    },
    {
      x: "9 PM",
      y: benchmarkValue,
    },
    {
      x: "10 PM",
      y: benchmarkValue,
    },
    {
      x: "11 PM",
      y: benchmarkValue,
    },
  ];
}

function getMaxYValueInMainData(dataSet) {
  let maxValue = -1;
  dataSet.forEach((set) => {
    if (set.y > maxValue) {
      maxValue = set.y;
    }
  });
  return maxValue;
}

function getTickValues() {
  return [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
  ];
}

function getStyle(palette, fetchingData, graphData) {
  const BLUE_COLOR = palette[0];
  const RED_COLOR = palette[1];

  return {
    parent: {
      fontFamily: "inherit",
      background: "transparent",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      width: "450px",
      height: "278px",
    },
    title: {
      fontFamily: "inherit",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: "normal",
    },
    labelNumber: {
      textAnchor: "middle",
      fill: "#ffffff",
      fontFamily: "inherit",
      fontSize: "14px",
    },

    // x axis
    axisYears: {
      axis: { stroke: "#909090", strokeWidth: 1 },
      ticks: {
        size: ({ tick }) => {
          if (
            [
              "12 AM",
              "3 AM",
              "6 AM",
              "9 AM",
              "12 PM",
              "3 PM",
              "6 PM",
              "9 PM",
            ].includes(tick)
          ) {
            return 5;
          } else {
            return 2;
          }
        },
        stroke: "#CFD8DC",
        strokeWidth: 2,
      },
      grid: {
        stroke: "transparent",
      },
      tickLabels: { fill: "#455A64", fontSize: 11 },
    },

    // mainData line
    axisOne: {
      axis: { stroke: "#909090", strokeWidth: 1 },
      ticks: {
        stroke: "transparent",
      },
      grid: {
        stroke: "#CFD8DC",
        strokeDasharray: "0, 0",
      },
      tickLabels: {
        fill: !fetchingData && graphData.length ? "#455A64" : "transparent",
      },
    },
    labelOne: {
      fill: BLUE_COLOR,
      fontFamily: "#909090",
      fontSize: 12,
      fontStyle: "italic",
    },
    lineOne: {
      data: { stroke: BLUE_COLOR, strokeWidth: 3 },
    },
    axisOneCustomLabel: {
      fill: BLUE_COLOR,
      fontFamily: "inherit",
      fontWeight: 300,
      fontSize: 21,
    },

    victoryScatterOne: {
      data: {
        fill: BLUE_COLOR,
        stroke: "black",
        strokeOpacity: 0.5,
        strokeWidth: 1,
      },
    },

    // benchmark Data line
    lineTwo: {
      data: { stroke: RED_COLOR, strokeWidth: 2 },
    },
    victoryScatterTwo: {
      data: {
        fill: RED_COLOR,
        stroke: "black",
        strokeOpacity: 0.5,
        strokeWidth: 2,
      },
    },
  };
}

export default ScriptsByHourGraph;

import React, { Fragment, useEffect, useState, useContext } from "react";
import SearchBar from "../../components/SearchBar";
import PharmacySitesDataCard from "./PharmacySitesDataCard";
import Spacer from "../../components/Spacer";
import CreateButton from "../../components/CreateButton";
import RoleCheckService from "../../services/RoleCheckService";
import PharmacyService from "../../services/PharmacyService";
import ErrorBanner from "../../components/ErrorBanner";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { ReactComponent as MoreActionsIcon } from "../../assets/more-actions.svg";
import { ReactComponent as MoreActionsIconMobile } from "../../assets/more-actions-mobile.svg";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Popup from "../../components/PopupMenu/Popup";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./PharmacySites.module.css";

const PharmacySites = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation("pharmacySites");

  const { handleShowSmScreenHeader, smallScreen, mediumScreen, largeScreen } =
    useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
  }, [smScreen]);

  const actionListItems = [
    {
      type: "enable",
      description: t("menuItem0"),
    },
    {
      type: "disable",
      description: t("menuItem1"),
    },
    {
      type: "edit",
      description: t("menuItem2"),
    },
    {
      type: "stockLocations",
      description: t("menuItem3"),
    },
  ];

  // const largeScreenOnlyListItems = [
  //   {
  //     type: "stockLocations",
  //     description: t("menuItem3"),
  //   },
  // ];

  // const actionListItems = !smScreen
  //   ? commonActionListItems.concat(largeScreenOnlyListItems)
  //   : commonActionListItems;

  const [error, setError] = useState("");
  const [sites, setSites] = useState([]);
  const [locationPathname, setLocationPathname] = useState("");
  const [filterText, setFilterText] = useState("");
  // const [contextMenu, setContextMenu] = useState(null);
  const [showPopup, setShowPopup] = useState(null);
  const [stockLocationsActive, setStockLocationsActive] = useState(false);

  useEffect(() => {
    setLocationPathname(location.pathname);
    // console.log("location.pathname", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    PharmacyService.list(user)
      .then((sites) => {
        sites.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (b.name.toUpperCase() < a.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });

        const filteredList = sites.filter((site) => {
          const exists = user.sites.some(
            (userSite) => site._id === userSite._id
          );
          return user.isEnterprise || exists;
        })
        
        setSites(filteredList);
      })
      .catch((error) => {
        setError(error);
      });

    return () => {
      setSites([]);
      setError("");
    };
  }, [user, location.state]);

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleCreate = () => {
    navigate("create");
  };

  const getAddress = (address) => {
    if (address) {
      let country = t(address.country, {ns: "countries"})

      let state =
        address?.country === "Canada"
          ? t(address.state, { ns: "states/Canada" })
          : address.state;

      let street = address.street1 || "";
      if (address.street2) {
        street += ", " + address.street2;
      }
      return `${street}, ${address.city || ""} ${state || ""} ${
        address.zipcode || ""
      } ${country || ""}`;
    }
    return "";
  };

  const getActionMenu = (site) => {
    let listItems = [...actionListItems];
    if (!RoleCheckService.viewStockLocations(user)) {
      let index = listItems.findIndex((o) => o.type === "stockLocations");
      if (index != -1) {
        listItems.splice(index, 1);
      }
    }

    if (site.isEnabled) {
      let index = listItems.findIndex((o) => o.type === "enable");
      listItems.splice(index, 1);
    } else if (!site.isEnabled) {
      let index = listItems.findIndex((o) => o.type === "disable");
      listItems.splice(index, 1);
    }

    if (!RoleCheckService.canCreateSites(user)) {
      let index = listItems.findIndex((o) => o.type === "enable");
      if (index === -1) {
        index = listItems.findIndex((o) => o.type === "disable");
      }
      listItems.splice(index, 1);
      index = listItems.findIndex((o) => o.type === "edit");
      listItems.splice(index, 1);
    }

    return listItems;
  };

  const handleAction = (action, site) => {
    let newSite = { ...site };
    switch (action.type) {
      case "enable":
        newSite.isEnabled = true;
        updateSite(newSite);
        break;
      case "disable":
        newSite.isEnabled = false;
        updateSite(newSite);
        break;
      case "edit":
        //setCurrentAction(1);
        // setSite(site);
        navigate("create", { state: { site: site } });
        break;
      case "stockLocations":
        //setSite(site);
        //setCurrentAction(2);
        setStockLocationsActive(true);
        navigate("stock-locations", { state: { site: site } });
        break;
      default:
        break;
    }
  };

  const updateSite = (site) => {
    PharmacyService.update(user, site)
      .then((siteDoc) => {
        let index = sites.findIndex((o) => o._id === siteDoc._id);
        let list = [...sites];
        list[index] = siteDoc;

        list.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (b.name.toUpperCase() < a.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });

        setSites(list);
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <Outlet />
      {error && (
        <div className={styles.pharmacySites__errorBannerContainer}>
          <ErrorBanner message={error} onClose={() => setError(null)} />
        </div>
      )}

      {locationPathname === "/settings/sites" && (
        <>
          <div
            className={
              smScreen
                ? [
                    styles["pharmacySites__row"],
                    styles["pharmacySites__row--spaceBetween"],
                    styles["pharmacySites__row--addNewSites"],
                  ].join(" ")
                : [
                    styles["pharmacySites__row"],
                    styles["pharmacySites__row--spaceBetween"],
                  ].join(" ")
            }
          >
            <div className={styles.pharmacySites__searchBarContainer}>
              <SearchBar
                placeholder={t("searchPlaceholder")}
                onSearch={handleSearch}
                focus={true}
              />
            </div>
            {RoleCheckService.canCreateSites(user) ? (
              <div className={styles.pharmacySites__createButtonContainer}>
                <CreateButton
                  onClick={handleCreate}
                  isEnabled={true}
                  labelName={t("createButtonLabel")}
                />
              </div>
            ) : lgScreen ? (
              <Spacer space={200} unit={"px"} />
            ) : (
              mdScreen && <Spacer space={100} unit={"px"} />
            )}
          </div>

          {/* Generation of Data Table Headings */}
          {!smScreen && (
            <div className={styles.pharmacySites__tableHeader}>
              <div
                className={`${styles.pharmacySites__col} ${styles["pharmacySites__siteName"]} ${styles.pharmacySites__overflowHidden}`}
              >
                <p className={styles.pharmacySites__text}>{t("col0")}</p>
              </div>

              <div
                className={`${styles.pharmacySites__col} ${styles["pharmacySites__location"]} ${styles.pharmacySites__overflowHidden}`}
              >
                <p className={styles.pharmacySites__text}>{t("col1")}</p>
              </div>

              <div
                className={`${styles.pharmacySites__col} ${styles["pharmacySites__phoneNumber"]} ${styles.pharmacySites__overflowHidden}`}
              >
                <p className={styles.pharmacySites__text}>{t("col2")}</p>
              </div>

              <div
                className={`${styles.pharmacySites__col} ${styles["pharmacySites__type"]} ${styles.pharmacySites__overflowHidden}`}
              >
                <p className={styles.pharmacySites__text}>{t("col3")}</p>
              </div>

              {lgScreen && (
                <div
                  className={`${styles.pharmacySites__col} ${styles["pharmacySites__col--width100Per"]}`}
                >
                  <p
                    className={`${styles.pharmacySites__text} ${styles["pharmacySites__actionsHeader"]}`}
                  >
                    {t("col4")}
                  </p>
                </div>
              )}
            </div>
          )}

          <Spacer space={smScreen ? 0 : 20} unit={"px"} />

          {/* Generation of Data Table */}
          <div
            className={
              !smScreen
                ? styles.pharmacySites__scroll
                : styles.pharmacySites__dataCardsContainer
            }
          >
            {sites.length > 0 &&
              sites
                .filter(
                  (o) =>
                    (RoleCheckService.viewPharmacySettings(user) ||
                      user.sites.some((item) => item === o._id)) &&
                    (filterText
                      ? o.name.toUpperCase().includes(filterText) ||
                        o.type.description.toUpperCase().includes(filterText)
                      : true)
                )
                .map((site, index) => {
                  return !smScreen ? (
                    <div
                      key={site._id}
                      className={
                        site.isEnabled
                          ? styles.pharmacySites__listItem
                          : `${styles.pharmacySites__listItem} ${styles["pharmacySites__listItem--siteEnabled"]}`
                      }
                      style={{
                        zIndex: sites.length - index,
                      }}
                    >
                      <div
                        className={`${styles.pharmacySites__col} ${styles["pharmacySites__siteName"]} ${styles.pharmacySites__overflowHidden}`}
                      >
                        <p className={`${styles.pharmacySites__listItemText}`}>
                          {site.name}
                        </p>
                      </div>

                      <div
                        className={`${styles.pharmacySites__col} ${styles["pharmacySites__location"]}`}
                      >
                        <p className={styles.pharmacySites__listItemText}>
                          {getAddress(site.shippingAddress)}
                        </p>
                      </div>

                      <div
                        className={`${styles.pharmacySites__col} ${styles["pharmacySites__phoneNumber"]} ${styles.pharmacySites__overflowHidden}`}
                      >
                        <p className={styles.pharmacySites__listItemText}>
                          {site.phoneNumbers.length > 0
                            ? formatPhoneNumberIntl(site.phoneNumbers[0].number)
                            : ""}
                        </p>
                      </div>

                      <div
                        className={`${styles.pharmacySites__col} ${styles["pharmacySites__type"]} ${styles.pharmacySites__overflowHidden}`}
                      >
                        <p className={styles.pharmacySites__listItemText}>
                          {t(site.type.type, {ns: "pharmacyTypes"})}
                        </p>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          justifyContent: "flex-end",
                          // flex: 1,
                        }}
                        className={styles.pharmacySites__col}
                      >
                        <div
                          className={styles.pharmacySites__actionsContainer}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowPopup(site);
                          }}
                        >
                          {mdScreen ? (
                            <MoreActionsIconMobile />
                          ) : (
                            <MoreActionsIcon />
                          )}

                          {showPopup === site && (
                            <div
                              className={styles.pharmacySites__popupContainer}
                            >
                              <Popup
                                menuItems={getActionMenu(site)}
                                onAction={(action) => {
                                  setShowPopup(null);
                                  handleAction(action, site);
                                }}
                                onClose={() => setShowPopup(null)}
                                rightJustify={true}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <PharmacySitesDataCard
                      key={site._id}
                      data={{
                        siteNameHeading: t("col0"),
                        locationHeading: t("col1"),
                        phoneNumberHeading: t("col2"),
                        typeHeading: t("col3"),
                        actionsHeading: t("col4"),
                        siteName: site.name,
                        location: getAddress(site.shippingAddress),
                        phoneNumber:
                          site.phoneNumbers.length > 0
                            ? formatPhoneNumberIntl(site.phoneNumbers[0].number)
                            : "",
                        type: t(site.type.type, {ns: "pharmacyTypes"}),
                        actions: (
                          <div
                            className={styles.pharmacySites__actionsContainer}
                            onClick={(e) => {
                              setShowPopup(site);
                            }}
                          >
                            <MoreActionsIconMobile />
                            {showPopup === site && (
                              <div
                                className={styles.pharmacySites__popupContainer}
                              >
                                <Popup
                                  menuItems={getActionMenu(site)}
                                  onAction={(action) => {
                                    setShowPopup(null);
                                    handleAction(action, site);
                                  }}
                                  onClose={() => setShowPopup(null)}
                                  rightJustify={true}
                                />
                              </div>
                            )}
                          </div>
                        ),
                      }}
                    />
                  );
                })}
          </div>
        </>
      )}
    </>
  );
};

export default PharmacySites;

import React, { useState, useEffect, useContext } from "react";
import Spacer from "../../components/Spacer";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import validate from "../../validation/CreatePasswordValidationRules";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import UserService from "../../services/UserService";
import ErrorBanner from "../../components/ErrorBanner";
import { useTranslation } from "react-i18next";
import styles from "./CreatePasswordView.module.css";

const CreatePasswordView = (props) => {
  const location = useLocation();
  const { t } = useTranslation("createPassword");
  let params = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const accountId = params.accountId;
  const userId = params.userId;
  const resetToken = params.resetToken;

  if (!accountId || !userId) {
    navigate("/");
  }

  useEffect(() => {
    UserService.read(accountId, userId)
      .then((user) => {
        setUser((prevState) => {
          return {
            ...prevState,
            ...user,
          };
        });
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const handleLogIn = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let validationErrors = validate({
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
    });

    setErrors(validationErrors);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (resetToken) {
        UserService.resetPassword(user, resetToken)
        .then((user) => {
          navigate("/log-in");
        })
        .catch((error) => {
          setError(error);
        });
      } else {
        UserService.setPassword(user)
        .then((user) => {
          navigate("/log-in");
        })
        .catch((error) => {
          setError(error);
        });
      }      
    }
  }, [errors]);

  return (
    <section className={styles.container}>
      <aside className={styles.cardContainer}>
        <section className={styles.cardBody}>
          <header className={styles.header}>
            <p className={styles.logoText}>
              <b className={styles.logoTextBold}>{resetToken ? t("titleReset") : t("title")}</b>
            </p>
          </header>
          {error && (
            <>
              <Spacer space={2} unit={"%"} />
              <ErrorBanner message={error} onClose={() => setError(null)} />
              <Spacer space={2} unit={"%"} />
            </>
          )}
          {/* {!error && <Spacer space={10} unit={"%"} />} */}
          <p className={styles.title}>{resetToken ? t("subtitleReset") : t("subtitle")}</p>

          {!resetToken && (
            <>
              <TextInput
                focus={true}
                isEnabled={true}
                labelName={t("firstNameLabel")}
                onChange={(value) => {
                  setUser((prevState) => {
                    return { ...prevState, firstName: value };
                  });
                }}
                placeholder={""}
                type={"text"}
                validationText={t(errors.firstName, {ns: "validation"})}
                value={user.firstName || ""}
              />
              <TextInput
                focus={false}
                isEnabled={true}
                labelName={t("lastNameLabel")}
                onChange={(value) => {
                  setUser((prevState) => {
                    return { ...prevState, lastName: value };
                  });
                }}
                placeholder={""}
                type={"text"}
                validationText={t(errors.lastName, {ns: "validation"})}
                value={user.lastName || ""}
              />
            </>
          )}
          <TextInput
            focus={false}
            isEnabled={true}
            labelName={t("passwordLabel")}
            onChange={(value) => {
              setUser((prevState) => {
                return { ...prevState, password: value };
              });
            }}
            placeholder={""}
            type={"password"}
            validationText={t(errors.password, {ns:"errors"})}
            value={user.password || ""}
          />
          {/*<Spacer space={10} unit={"px"} />*/}
          {/*<a*/}
          {/*  className={styles.linkButton}*/}
          {/*  onClick={(e) => {*/}
          {/*    e.preventDefault();*/}
          {/*    //todo: add forgot password flow*/}
          {/*    navigate("/account-setup");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {t("forgotPasswordText")}*/}
          {/*</a>*/}
          <Spacer space={7} unit={"%"} />
          <Button
            labelName={t("buttonLabel")}
            isPrimary={true}
            isDisabled={false}
            onClick={handleLogIn}
            minWidth={"100%"}
          />
        </section>
      </aside>
    </section>
  );
};

export default CreatePasswordView;

import { getCanisterDataMapping } from "./getCanisterDataMapping"
import { getPouchDataMapping } from "./getPouchDataMapping"

export const getDataMapping = (selectedLabelType) => {
  if (selectedLabelType === "Canister") {
    return getCanisterDataMapping()
  } else if (selectedLabelType === "Pouch") {
    return getPouchDataMapping()
  } else {
    return []
  }
}
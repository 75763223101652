import React, { useRef, useEffect } from 'react'
import { Line as KonvaLine } from 'react-konva'
import { mmToPx } from "../functions/converters.js";



const Line = ({
  obj,
  isSelected,
  onDragStart,
  onDragMove,
  onDragEnd,
  onTransformEnd,
  viewOnly,
  attrs
}) => {
  const lineRef = useRef(null)

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.x(mmToPx(obj.x))
      lineRef.current.y(mmToPx(obj.y))
    }
  }, [obj])

  const dragProps =
    onDragStart && onDragMove && onDragEnd
      ? { onDragStart, onDragMove, onDragEnd }
      : {}

  const transformProps = onTransformEnd ? { onTransformEnd } : {}

  return (
    <KonvaLine
      ref={lineRef}
      objectType='line'
      key={obj.id}
      id={obj.id}
      points={[0, 0, mmToPx(obj.width), 0]}
      hitStrokeWidth={100}
      strokeWidth={obj.strokeWidth}
      stroke={obj.stroke}
      dash={obj.dashEnabled ? [obj.dashSize, obj.dashGap] : null}
      listening={!viewOnly}
      rotation={obj.rotation}
      draggable={
        !obj.locked && isSelected && onDragStart && onDragMove && onDragEnd
      }
      transformable={!obj.locked && isSelected}
      scaleX={obj.scaleX || 1}
      scaleY={obj.scaleY || 1}
      {...dragProps}
      {...transformProps}
      {...(attrs || {})}
    />
  )
}

export default Line

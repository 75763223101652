import { useState, useEffect, useCallback, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import MetricsChartView from "./MetricsChartView/MetricsChartView";
import DispensedMedicationsTableForTrays from "./DispensedMedicationsTableForTrays";
import DispensedMedicationsTableForCanisters from "./DispensedMedicationsTableForCanisters";
import backIcon from "../../../../../assets/back_icon.svg";
import AntTabs from "../../../../../components/Ant/AntTabs/AntTabs";
import DateRangePickerComponent from "../../../../../components/DateRangePickerComponent";
import { getNumberOfDaysInDateRange } from "../../../../../functions/dates";
import Button from "../../../../../components/Button";
import {
  getTimeRangeCurrentWeek,
  getTimeRangeWeek,
} from "../../../../../functions/dates";
import { dateSelectionRangeTimeAdjustment } from "../../../../../functions/dates";
import { getLocalizedDate } from "../../../../../functions/localization";
import styles from "./MedicationsDispensed.module.css";
const MedicationsDispensed = ({
  user,
  selectedDevices,
  selectedSites,
  onShowMedicationsDispensed,
  showCanisterMedicationsDispensed,
  onShowCanisterMedicationsDispensed,
  onShowTrayMedicationsDispensed,
  onShowDropdowns,
}) => {
  const { t } = useTranslation("trupakDashboard");

  const [canisterProducts, setCanisterProducts] = useState([]);
  const [canisterProductsSelectedDay, setCanisterProductsSelectedDay] =
    useState([]);
  const [trayProducts, setTrayProducts] = useState([]);
  const [trayProductsSelectedDay, setTrayProductsSelectedDay] = useState([]);
  const [activeKey, setActiveKey] = useState("tray");
  const [startDateCurrentWeek, setStartDateCurrentWeek] = useState(null);
  const [stopDateCurrentWeek, setStopDateCurrentWeek] = useState(null);
  const [startDateLastWeek, setStartDateLastWeek] = useState(null);
  const [stopDateLastWeek, setStopDateLastWeek] = useState(null);
  const [applicableDates, setApplicableDates] = useState(null);
  const [trayProductSelectionLookup, setTrayProductLookup] = useState({});
  const [canisterProductSelectionLookup, setCanisterProductLookup] = useState(
    {}
  );
  const [selectedDayTray, setSelectedDayTray] = useState(null);
  const [selectedDayCanister, setSelectedDayCanister] = useState(null);
  const [showCanisterPlacementPopup, setShowCanisterPlacementPopup] =
    useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({});
  const [selectedDateRangeDatePicker, setSelectedDateRangeDatePicker] =
    useState({});
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);
  const [validDateRange, setValidDateRange] = useState(true);

  useEffect(() => {
    const dateRangeObjectCurrentWeek = getTimeRangeCurrentWeek();
    setStartDateCurrentWeek(dateRangeObjectCurrentWeek.startDate);
    setStopDateCurrentWeek(dateRangeObjectCurrentWeek.stopDate);
    const dateRangeObject = getTimeRangeWeek();
    setStartDateLastWeek(dateRangeObject.startDate);
    setStopDateLastWeek(dateRangeObject.stopDate);
  }, []);

  useEffect(() => {
    if (showCanisterMedicationsDispensed) {
      setActiveKey("canister");
    } else {
      setActiveKey("tray");
    }
    setSelectedDayTray(null);
    setSelectedDayCanister(null);
  }, [showCanisterMedicationsDispensed]);

  useEffect(() => {
    setSelectedDateRange((prevState) => {
      // check if key exists
      const hasKey = "name" in prevState;
      if (!hasKey) {
        const startDate = new Date(startDateLastWeek);
        const endDate = new Date(stopDateLastWeek);
        return dateSelectionRangeTimeAdjustment(startDate, endDate);
      }
    });
    setSelectedDateRangeDatePicker((prevState) => {
      // check if key exists
      const hasKey = "name" in prevState;
      if (!hasKey) {
        const startDate = new Date(startDateLastWeek);
        const endDate = new Date(stopDateLastWeek);
        return dateSelectionRangeTimeAdjustment(startDate, endDate);
      }
    });
  }, [startDateLastWeek, stopDateLastWeek]);

  const handleTrayProducts = useCallback((trayProductsArray) => {
    setTrayProducts(trayProductsArray);
  }, []);

  const handleCanisterProducts = useCallback((canisterProductsArray) => {
    setCanisterProducts(canisterProductsArray);
  }, []);

  const handleTrayProductLookup = useCallback((rowObject) => {
    setTrayProductLookup(rowObject);
  }, []);

  const handleCanisterProductLookup = useCallback((rowObject) => {
    setCanisterProductLookup(rowObject);
  }, []);

  const handleShowCanisterPlacementPopup = useCallback((boolVal) => {
    setShowCanisterPlacementPopup(boolVal);
  }, []);

  const tabItems = [
    { key: "tray", label: t("Tray medications") },
    { key: "canister", label: t("Canister medications") },
  ];

  const handleTabChange = (key) => {
    setApplicableDates(null);
    if (key === "canister") {
      onShowTrayMedicationsDispensed(false);
      onShowCanisterMedicationsDispensed(true);
    } else {
      onShowTrayMedicationsDispensed(true);
      onShowCanisterMedicationsDispensed(false);
    }
  };

  const handleSelectedDayTray = useCallback((day) => {
    setSelectedDayTray(day);
  }, []);

  const handleSelectedDayCanister = useCallback((day) => {
    setSelectedDayCanister(day);
  }, []);

  const handleTrayProductsSelectedDay = useCallback((trayProductsArray) => {
    setTrayProductsSelectedDay(trayProductsArray);
  }, []);

  const handleCanisterProductsSelectedDay = useCallback(
    (canisterProductsArray) => {
      setCanisterProductsSelectedDay(canisterProductsArray);
    },
    []
  );

  const handleGoBack = () => {
    onShowMedicationsDispensed(false);
    onShowCanisterMedicationsDispensed(false);
    onShowTrayMedicationsDispensed(false);
    onShowDropdowns(true);
  };

  const handleDateRangeSelect = (ranges) => {
    const startDate = new Date(ranges.selection.startDate);
    let endDate = new Date(ranges.selection.endDate);

    if (getNumberOfDaysInDateRange(startDate, endDate) > 60) {
      setValidDateRange(false);
    } else {
      setValidDateRange(true);
    }

    const dateRangeObject = dateSelectionRangeTimeAdjustment(
      startDate,
      endDate
    );

    setSelectedDateRangeDatePicker(dateRangeObject);
  };

  const handleDateRangeSelectorButtonClick = () => {
    setShowDateTimeRangePicker(false);
    setSelectedDateRange(selectedDateRangeDatePicker);
    setSelectedDayTray(null);
    setTrayProductsSelectedDay([]);
    setApplicableDates(null);
  };

  const DateRangePickerGroup = (
    <div className={styles.MedicationsDispensed__modal}>
      <DateRangePickerComponent
        ranges={[selectedDateRangeDatePicker]}
        maxDate={new Date()}
        onChange={handleDateRangeSelect}
        rangeColors={["#089BAB"]}
        country={user?.defaultSite?.shippingAddress?.country}
      />
      <br />
      <div
        className={
          styles.MedicationsDispensed__validDateRangeAndButtonContainer
        }
      >
        {!validDateRange && (
          <p className={styles.MedicationsDispensed__invalidDateRangeMessage}>
            The date range cannot exceeed 60 days
          </p>
        )}
        <Button
          labelName={t("continueButtonLabel")}
          isPrimary={true}
          onClick={handleDateRangeSelectorButtonClick}
          isDisabled={!validDateRange}
        />
      </div>
    </div>
  );

  let applicableDatesLocalized = [];
  if (
    applicableDates &&
    ((activeKey === "tray" && selectedDayTray !== null) ||
      (activeKey === "canister" && selectedDayCanister !== null))
  ) {
    applicableDates.forEach((date) => {
      applicableDatesLocalized.push(
        getLocalizedDate(
          new Date(date),
          user?.defaultSite?.shippingAddress?.country
        )
      );
    });

    applicableDatesLocalized.sort();
  }

  const applicableDatesLocalizedString = applicableDatesLocalized.join(", ");
  let viewApplicableDatesLocalizedString = false;

  if (
    (activeKey === "tray" && selectedDayTray !== null) ||
    (activeKey === "canister" && selectedDayCanister !== null)
  ) {
    viewApplicableDatesLocalizedString = true;
  }

  let selectedDates = `${getLocalizedDate(
    selectedDateRange.startDate,
    user?.defaultSite?.shippingAddress?.country
  )}
     – 
    ${getLocalizedDate(
      selectedDateRange.endDate,
      user?.defaultSite?.shippingAddress?.country
    )}`;

  const DateRangeGroup = (
    <div className={styles.MedicationsDispensed__dateRangeGroup}>
      <p className={styles.MedicationsDispensed__dateRangeLabel}>
        {t("datePickerLabel")}
      </p>
      <p
        className={styles.MedicationsDispensed__dateRange}
        onClick={() => setShowDateTimeRangePicker(true)}
      >
        {selectedDates}
      </p>
      {viewApplicableDatesLocalizedString && (
        <p className={styles.MedicationsDispensed__showApplicableDates}>
          {applicableDatesLocalizedString}
        </p>
      )}
    </div>
  );

  const handleApplicableDates = (datesArray) => {
    setApplicableDates(datesArray);
  };

  return (
    <Fragment>
      {showDateTimeRangePicker && (
        <div className={styles.MedicationsDispensed__overlay}></div>
      )}
      <div className={styles.MedicationsDispensed__medicationsContainer}>
        {!showCanisterPlacementPopup && (
          <div>
            <div className={styles.MedicationsDispensed__pageTitleContainer}>
              <div className={styles.MedicationsDispensed__backButton}>
                <img src={backIcon} alt="" onClick={handleGoBack} />
              </div>
              <h2 className={styles.MedicationsDispensed__pageTitle}>
                {t("Medications Dispensed")}
              </h2>
            </div>

            <div className={styles.MedicationsDispensed__tabsAndDateSelection}>
              <AntTabs
                tabsStyle={"ant-tabs-000"}
                defaultActiveKey="tray"
                activeKey={activeKey}
                tabItems={tabItems}
                onChange={handleTabChange}
              />
              <div
                className={styles.MedicationsDispensed__DateRangeGroupContainer}
              >
                {DateRangeGroup}
              </div>
              {showDateTimeRangePicker && DateRangePickerGroup}
            </div>
          </div>
        )}
        <div
          className={
            showCanisterPlacementPopup
              ? styles["MedicationsDispensed__medicationsGroup--fullHeight"]
              : styles.MedicationsDispensed__medicationsGroup
          }
        >
          <div
            className={
              showCanisterPlacementPopup
                ? styles["MedicationsDispensed__MetricsChartView--hide"]
                : styles["MedicationsDispensed__MetricsChartView--show"]
            }
          >
            <MetricsChartView
              user={user}
              activeKey={activeKey}
              selectedDayTray={selectedDayTray}
              onSelectedDayTray={handleSelectedDayTray}
              selectedDayCanister={selectedDayCanister}
              onSelectedDayCanister={handleSelectedDayCanister}
              selectedDevices={selectedDevices}
              selectedSites={selectedSites}
              startDateCurrentWeek={startDateCurrentWeek}
              stopDateCurrentWeek={stopDateCurrentWeek}
              startDateLastWeek={startDateLastWeek}
              stopDateLastWeek={stopDateLastWeek}
              selectedDateRange={selectedDateRange}
              applicableDates={applicableDates}
              onApplicableDates={handleApplicableDates}
              trayProducts={trayProducts}
              onTrayProducts={handleTrayProducts}
              onTrayProductsSelectedDay={handleTrayProductsSelectedDay}
              canisterProducts={canisterProducts}
              onCanisterProducts={handleCanisterProducts}
              onCanisterProductsSelectedDay={handleCanisterProductsSelectedDay}
              trayProductSelectionLookup={trayProductSelectionLookup}
              canisterProductSelectionLookup={canisterProductSelectionLookup}
              onTrayProductSelectionLookup={handleTrayProductLookup}
              onCanisterProductSelectionLookup={handleCanisterProductLookup}
              onShowDropdowns={onShowDropdowns}
            />
          </div>
        </div>

        {activeKey === "tray" && (
          <DispensedMedicationsTableForTrays
            user={user}
            activeKey={activeKey}
            canisterProductsSelectedDay={canisterProductsSelectedDay}
            canisterProducts={canisterProducts}
            startDateLastWeek={startDateLastWeek}
            stopDateLastWeek={stopDateLastWeek}
            applicableDates={applicableDates}
            selectedDateRange={selectedDateRange}
            selectedDayTray={selectedDayTray}
            selectedDayCanister={selectedDayCanister}
            trayProductSelectionLookup={trayProductSelectionLookup}
            onTrayProductSelectionLookup={handleTrayProductLookup}
            trayProductsSelectedDay={trayProductsSelectedDay}
            trayProducts={trayProducts}
            showCanisterPlacementPopup={showCanisterPlacementPopup}
            onShowCanisterPlacementPopup={handleShowCanisterPlacementPopup}
          />
        )}

        {activeKey === "canister" && (
          <DispensedMedicationsTableForCanisters
            user={user}
            selectedDateRange={selectedDateRange}
            selectedDayCanister={selectedDayCanister}
            canisterProductSelectionLookup={canisterProductSelectionLookup}
            onCanisterProductSelectionLookup={handleCanisterProductLookup}
            canisterProductsSelectedDay={canisterProductsSelectedDay}
            canisterProducts={canisterProducts}
          />
        )}
      </div>
    </Fragment>
  );
};

export default MedicationsDispensed;

import { Select } from "antd";
import styles from "./AntSelect.module.css";
import "./AntSelect.css";

/* 
  Example of use
    <AntSelect
        value={orderData[rowIndex][columnKey]}
        selectStyle="ant-selectGroup-000"
        minWidth={`${columnWidths[columnIndex] - 10}px`}
        width={`${columnWidths[columnIndex] - 10}px`}
        placeholder={"Select"}
        options={[
            { value: "id1", label: "Facility 1" },
            { value: "id2", label: "Facility 2" },
            { value: "id3", label: "Facility 3" },
        ]}
        onChange={(value) =>
            onFieldValueChange(value, rowIndex, columnKey)
        }
    />
*/

const AntSelect = ({
  label,
  value,
  selectStyle,
  minWidth,
  width,
  placeholder,
  options,
  onChange,
}) => {
  return (
    <div className={selectStyle || null}>
      {label && <p className={styles.AntSelect__label}>{label}</p>}
      <Select
        defaultValue={value}
        value={value}
        className="ant-select-selector"
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        style={{
          minWidth: minWidth,
          width: width,
          border: "none",
          outline: "none",
        }}
      />
    </div>
  );
};

export default AntSelect;

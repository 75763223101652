import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ReactComponent as Square } from "../../../../../assets/square.svg";
import AntButton from "../../../../../components/Ant/AntButton/AntButton";
import backIcon from "../../../../../assets/back_icon.svg";
import { getLocalizedDate } from "../../../../../functions/localization";
import {
  getNumberOfWeekDayInDateRange,
  getNumberOfDaysInDateRange,
} from "../../../../../functions/dates";
import styles from "./SelectTrayPillsForCanister.module.css";
import "./SelectTrayPillsForCanisterTableAntStyles.css";
const SelectTrayPillsForCanister = ({
  user,
  trayProductSelectionLookup,
  onShowCanisterPlacementPopup,
  trayProducts,
  trayProductsSelectedDay,
  selectedDayTray,
  startDateLastWeek,
  selectedDateRange,
  applicableDates,
}) => {
  const { t } = useTranslation("trupakDashboard");
  const [
    drugsSelectedForCanisterPlacementLookup,
    setDrugsSelectedForCanisterPlacementLookup,
  ] = useState({});
  const [DrugList, setDrugList] = useState([]);
  const [
    atLeastOneDrugSelectedToCanister,
    setAtLeastOneDrugSelectedToCanister,
  ] = useState(false);
  const [orderCanisterButtonClicked, setOrderCanisterButtonClicked] =
    useState(false);

  const [newTrayFillDailyAverage, setNewTrayFillDailyAverage] = useState(null);

  const [tableData, setTableData] = useState([]);

  const [allTrayDrugsSelected, setAllTrayDrugsSelected] = useState(true);

  const tableColumns = [
    {
      dataIndex: "idSquare",
      ellipsis: true,
      key: false,
      onCell: null,
      sortColumnKey: false,
      sorter: null,
      title: (
        <div
          className={
            allTrayDrugsSelected
              ? `${styles.SelectTrayPillsForCanister__idSquareContainer} ${styles["SelectTrayPillsForCanister__idSquareContainer--active"]}`
              : styles.SelectTrayPillsForCanister__idSquareContainer
          }
          onClick={() => handleAllTrayDrugsSelected()}
        >
          <Square width="24px" height="24px" />
        </div>
      ),
      width: "26px",
    },
    {
      dataIndex: "packageId",
      ellipsis: true,
      key: "packageId",
      onCell: null,
      sorter: (a, b) => {
        return a.packageId.localeCompare(b.packageId);
      },
      sortDirections: ["ascend", "descend"],
      title: t("Drug ID"),
      width: "150px",
    },
    {
      dataIndex: "medication",
      ellipsis: true,
      key: "medication",
      onCell: null,
      sorter: (a, b) => {
        return a.medication.localeCompare(b.medication);
      },
      sortDirections: ["ascend", "descend"],
      title: t("Description"),
      width: "60%",
    },
    {
      dataIndex: "manufacturer",
      ellipsis: true,
      key: t("manufacturer"),
      onCell: null,
      sorter: (a, b) => {
        return a.manufacturer.localeCompare(b.manufacturer);
      },
      sortDirections: ["ascend", "descend"],
      title: "Manufacturer",
      width: "40",
    },
  ];

  useEffect(() => {
    let tempDrugsLookup = {};
    for (const [key, value] of Object.entries(trayProductSelectionLookup)) {
      if (value.selected) {
        tempDrugsLookup[key] = {
          product: value.product,
          manufacturer: value.manufacturer,
          selectedForCanisterPlacement: true,
        };
      }
    }
    const sortedKeys = Object.keys(tempDrugsLookup).sort((a, b) =>
      tempDrugsLookup[a].product.localeCompare(tempDrugsLookup[b].product)
    );
    const sortedTempDrugsLookup = {};
    sortedKeys.forEach((key) => {
      sortedTempDrugsLookup[key] = tempDrugsLookup[key];
    });
    setDrugsSelectedForCanisterPlacementLookup((prevState) => {
      for (const [key, value] of Object.entries(prevState)) {
        if (
          sortedTempDrugsLookup.hasOwnProperty(key) &&
          !value.selectedForCanisterPlacement
        ) {
          sortedTempDrugsLookup[key].selectedForCanisterPlacement = false;
        } else if (sortedTempDrugsLookup.hasOwnProperty(key)) {
          sortedTempDrugsLookup[key].selectedForCanisterPlacement = true;
        }
      }
      return sortedTempDrugsLookup;
    });
  }, [trayProductSelectionLookup]);

  useEffect(() => {
    const handleSelection = (key, selectStatus) => {
      let tempDrugsLookup = { ...drugsSelectedForCanisterPlacementLookup };
      if (tempDrugsLookup[key].selectedForCanisterPlacement) {
        tempDrugsLookup[key].selectedForCanisterPlacement = false;
        setAllTrayDrugsSelected(false);
      } else {
        tempDrugsLookup[key].selectedForCanisterPlacement = true;
      }
      setDrugsSelectedForCanisterPlacementLookup(tempDrugsLookup);
    };

    let tempTableData = [];

    for (const [key, value] of Object.entries(
      drugsSelectedForCanisterPlacementLookup
    )) {
      const IDSquare = (
        <div
          className={
            value.selectedForCanisterPlacement === true
              ? `${styles.SelectTrayPillsForCanister__idSquareContainer} ${styles["SelectTrayPillsForCanister__idSquareContainer--active"]}`
              : styles.SelectTrayPillsForCanister__idSquareContainer
          }
        >
          <Square
            width="24px"
            height="24px"
            onClick={() =>
              handleSelection(key, value.selectedForCanisterPlacement)
            }
          />
        </div>
      );

      tempTableData.push({
        key: key,
        idSquare: IDSquare,
        packageId: key,
        medication: value.product,
        manufacturer: value.manufacturer,
      });
    }

    setTableData(tempTableData);

    let tempSelectedDrugs = [];
    for (const [key, value] of Object.entries(
      drugsSelectedForCanisterPlacementLookup
    )) {
      tempSelectedDrugs.push(
        <li key={key}>
          <span
            className={
              value.selectedForCanisterPlacement === true
                ? `${styles.SelectTrayPillsForCanister__idSquareContainer} ${styles["canisterOrderPopup__idSquareContainer--active"]}`
                : styles.SelectTrayPillsForCanister__idSquareContainer
            }
          >
            <Square
              width="24px"
              height="24px"
              onClick={() =>
                handleSelection(key, value.selectedForCanisterPlacement)
              }
            />
          </span>
          {value.product}
        </li>
      );
    }
    setDrugList(tempSelectedDrugs);
  }, [drugsSelectedForCanisterPlacementLookup]);

  useEffect(() => {
    let drugToBeCanisteredFound = false;
    for (const [key, value] of Object.entries(
      drugsSelectedForCanisterPlacementLookup
    )) {
      if (value.selectedForCanisterPlacement === true) {
        drugToBeCanisteredFound = true;
        break;
      }
    }
    setAtLeastOneDrugSelectedToCanister(drugToBeCanisteredFound);
  }, [drugsSelectedForCanisterPlacementLookup]);

  useEffect(() => {
    let trayProductsSelectedForCanister = trayProducts;
    if (selectedDayTray || selectedDayTray === 0) {
      trayProductsSelectedForCanister = trayProductsSelectedDay;
    }
    let totalTrayQty = 0;
    let totalQtyForCanisterPlacement = 0;
    if (Object.keys(drugsSelectedForCanisterPlacementLookup).length > 0) {
      for (const [key] of Object.entries(
        drugsSelectedForCanisterPlacementLookup
      )) {
        const foundDrug = trayProductsSelectedForCanister.find(
          (trayProduct) => {
            return (
              trayProduct.packageId === key &&
              drugsSelectedForCanisterPlacementLookup[key]
                .selectedForCanisterPlacement
            );
          }
        );
        if (foundDrug) {
          totalQtyForCanisterPlacement += foundDrug.qty;
        }
      }
    }
    for (const trayProduct of trayProductsSelectedForCanister) {
      totalTrayQty += trayProduct.qty;
    }

    if (selectedDayTray || selectedDayTray === 0) {
      let numberOfDays = getNumberOfWeekDayInDateRange(
        selectedDateRange.startDate,
        selectedDateRange.endDate,
        selectedDayTray
      );

      if (numberOfDays) {
        setNewTrayFillDailyAverage(
          (
            (totalTrayQty - totalQtyForCanisterPlacement) /
            numberOfDays
          ).toFixed(1)
        );
      } else {
        setNewTrayFillDailyAverage((0.0).toFixed(1));
      }
    } else {
      let numberOfDays = getNumberOfDaysInDateRange(
        selectedDateRange.startDate,
        selectedDateRange.endDate
      );
      setNewTrayFillDailyAverage(
        ((totalTrayQty - totalQtyForCanisterPlacement) / numberOfDays).toFixed(
          1
        )
      );
    }
  }, [
    selectedDayTray,
    trayProducts,
    trayProductsSelectedDay,
    drugsSelectedForCanisterPlacementLookup,
    selectedDateRange,
    applicableDates,
  ]);

  const cancelButtonClicked = () => {
    onShowCanisterPlacementPopup(false);
    setOrderCanisterButtonClicked(false);
  };

  const handleOrderCanisterButtonClicked = (boolVal) => {
    setOrderCanisterButtonClicked(boolVal);
  };

  const handleGoBack = () => onShowCanisterPlacementPopup(false);

  const handleChange = (pagination, filters, sorter) => {
    // console.log("Various parameters", pagination, filters, sorter);
  };

  let dateRangeForTitle = "";
  let startDateLocalized;
  let stopDateLocalized;
  if (selectedDayTray || selectedDayTray === 0) {
    const selectedDate = new Date(startDateLastWeek);
    selectedDate.setDate(selectedDate.getDate() + selectedDayTray);

    startDateLocalized = getLocalizedDate(
      selectedDate,
      user?.defaultSite?.shippingAddress?.country
    );

    let applicableDatesLocalized = [];
    applicableDates.forEach((date) => {
      applicableDatesLocalized.push(
        getLocalizedDate(
          new Date(date),
          user?.defaultSite?.shippingAddress?.country
        )
      );
    });

    applicableDatesLocalized.sort();

    const applicableDatesLocalizedString = applicableDatesLocalized.join(", ");

    dateRangeForTitle = applicableDatesLocalizedString;
  } else {
    const startDate = new Date(selectedDateRange.startDate);
    startDateLocalized = getLocalizedDate(
      startDate,
      user?.defaultSite?.shippingAddress?.country
    );

    const stopDate = new Date(selectedDateRange.endDate);
    stopDateLocalized = getLocalizedDate(
      stopDate,
      user?.defaultSite?.shippingAddress?.country
    );

    dateRangeForTitle = `${startDateLocalized} - ${stopDateLocalized}`;
  }

  const dailyAverageSplitArray = t(
    "SelectTrayPillsForCanisterDailyAverageMsg"
  ).split("0 %");

  const TableTitle = (
    <div className={styles.SelectTrayPillsForCanister__tableTitle}>
      <h2 className={styles.SelectTrayPillsForCanister__tableTitleMain}>
        Most Filled Trays for Canister Placement
      </h2>
      <h3 className={styles.SelectTrayPillsForCanister__tableTitleSub}>
        {dateRangeForTitle}
      </h3>
      <p
        className={
          styles.SelectTrayPillsForCanister__newTrayFillDailyAverageMessage
        }
      >
        {dailyAverageSplitArray[0]}
        <span
          className={styles.SelectTrayPillsForCanister__newTrayFillDailyAverage}
        >
          {" "}
          {`${newTrayFillDailyAverage} %`}
        </span>{" "}
        {dailyAverageSplitArray[1]}
      </p>
    </div>
  );

  const handleAllTrayDrugsSelected = () => {
    let tempDrugsSelectedForCanisterPlacementLookup = {
      ...drugsSelectedForCanisterPlacementLookup,
    };
    if (allTrayDrugsSelected) {
      Object.keys(trayProductSelectionLookup).forEach((key) => {
        if (drugsSelectedForCanisterPlacementLookup[key]) {
          drugsSelectedForCanisterPlacementLookup[
            key
          ].selectedForCanisterPlacement = false;
        }
      });
      setAllTrayDrugsSelected(false);
    } else {
      Object.keys(trayProductSelectionLookup).forEach((key) => {
        if (drugsSelectedForCanisterPlacementLookup[key]) {
          drugsSelectedForCanisterPlacementLookup[
            key
          ].selectedForCanisterPlacement = true;
        }
      });
      setAllTrayDrugsSelected(true);
    }
    setDrugsSelectedForCanisterPlacementLookup(
      tempDrugsSelectedForCanisterPlacementLookup
    );
  };

  return (
    <>
      <div className={styles.SelectTrayPillsForCanister__pageTitleContainer}>
        <div className={styles.SelectTrayPillsForCanister__backButton}>
          <img src={backIcon} alt="" onClick={handleGoBack} />
        </div>
        <h2 className={styles.SelectTrayPillsForCanister__pageTitle}>
          {t("Order Canister")}
        </h2>
      </div>
      <div
        className={`${styles.SelectTrayPillsForCanister__tableContainer} SelectTrayPillsForCanisterTable__AntStyle`}
      >
        {TableTitle}
        <div>
          <Table
            columns={tableColumns}
            dataSource={tableData}
            onChange={handleChange}
            scroll={{ y: `calc(70vh)` }}
            pagination={{ pageSize: 15 }}
          />
        </div>
        <div className={styles.SelectTrayPillsForCanister__buttonGroup}>
          <AntButton
            onClick={() => cancelButtonClicked()}
            text={"Cancel"}
            buttonstyle="ant-button-003"
            size="small"
          />
          <AntButton
            onClick={() => handleOrderCanisterButtonClicked(true)}
            text={t("Order Canister")}
            buttonstyle="ant-button-004"
            size="large"
            disabled={!atLeastOneDrugSelectedToCanister}
          />
        </div>
      </div>
    </>
  );
};

export default SelectTrayPillsForCanister;

import {
  Button,
  Checkbox,
  Col,
  Layout,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../contexts/ScreenContext";
import AccountService from "../../services/AccountService";
import RoleCheckService from "../../services/RoleCheckService";
import "./AccountSettings.module.css";

const { Footer, Content } = Layout;

const AccountSettings = ({ user, site }) => {
  const { t } = useTranslation("accountSettings");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [account, setAccount] = useState(user.account);

  const [productApprovalRequired, setProductApprovalRequired] = useState(
    account.productApprovalRequired
  );

  const [autoUpdateFdb, setAutoUpdateFdb] = useState(false);
  const [updatePackageId, setUpdatePackageId] = useState(false);
  const [updateDescription, setUpdateDescription] = useState(false);
  const [updateManufacturerName, setUpdateManufacturerName] = useState(false);
  const [updateSubstitutionCode, setUpdateSubstitutionCode] = useState(false);
  const [updatePackageInfo, setUpdatePackageInfo] = useState(false);
  const [updateImages, setUpdateImages] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const saveSettings = async () => {
    try {
      account.productApprovalRequired = productApprovalRequired;
      account.fdbSettings.autoUpdate = autoUpdateFdb;
      account.fdbSettings.updatePackageId = updatePackageId;
      account.fdbSettings.updateDescription = updateDescription;
      account.fdbSettings.updateManufacturerName = updateManufacturerName;
      account.fdbSettings.updateSubstitutionCode = updateSubstitutionCode;
      account.fdbSettings.updatePackageInfo = updatePackageInfo;
      account.fdbSettings.updateImages = updateImages;
      setLoading(true);
      await AccountService.put(user, account);
      message.success(t("updateSuccessWarning"));
    } catch (error) {
      console.error(error);
      message.error(t("updateFailWarning"));
    } finally {
      setLoading(false);
    }
  };

  const fetchAccount = async () => {
    try {
      setLoading(true);
      const account = await AccountService.get(user);
      setAccount(account);
      user.account = account;

      if (!account.fdbSettings) {
        account.fdbSettings = {
          autoUpdate: false,
          updatePackageId: false,
          updateDescription: false,
          updateManufacturerName: false,
          updateSubstitutionCode: false,
          updatePackageInfo: false,
          updateImages: false,
        };
      } else {
        setAutoUpdateFdb(account.fdbSettings.autoUpdate);
        setUpdatePackageId(account.fdbSettings.updatePackageId);
        setUpdateDescription(account.fdbSettings.updateDescription);
        setUpdateManufacturerName(account.fdbSettings.updateManufacturerName);
        setUpdateSubstitutionCode(account.fdbSettings.updateSubstitutionCode);
        setUpdatePackageInfo(account.fdbSettings.updatePackageInfo);
        setUpdateImages(account.fdbSettings.updateImages);
        setProductApprovalRequired(account.productApprovalRequired);
      }
    } catch (error) {
      console.error(error);
      message.error(t("updateFailWarning"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  useEffect(() => {
    fetchAccount();
  }, [user]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await saveSettings();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Layout>
        <Content style={{ background: "white" }}>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            {!smScreen && <Row style={{ height: "20px" }}></Row>}
            {loading && (
              <Row style={{ height: "70px" }}>
                <Col span={24}>{loading && <Spin size="large" />}</Col>
              </Row>
            )}
            <Row gutter={20}>
              <Col>
                <span>{t("approvalLabel")}</span>
              </Col>
              <Col>
                <Switch
                  checkedChildren={t("onLabel")}
                  unCheckedChildren={t("offLabel")}
                  checked={productApprovalRequired}
                  onChange={(checked) => {
                    setProductApprovalRequired(checked);
                  }}
                  disabled={
                    loading ||
                    !RoleCheckService.canManageMedicationSettings(user)
                  }
                />
              </Col>
            </Row>
            {/* THIS IS A TEMPORY COMMENT TO PREVENT FROM RELEASING WITH 1.2 */}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <span>{t("fdbAutoUpdateLabel")}</span>*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <Switch*/}
            {/*      checkedChildren={t("onLabel")}*/}
            {/*      unCheckedChildren={t("offLabel")}*/}
            {/*      checked={autoUpdateFdb}*/}
            {/*      onChange={(checked) => {*/}
            {/*        setAutoUpdateFdb(checked);*/}
            {/*      }}*/}
            {/*      disabled={*/}
            {/*        loading ||*/}
            {/*        !RoleCheckService.canManageMedicationSettings(user)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <span>{t("fdbAutoInfoLabel")}</span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <Checkbox*/}
            {/*      checked={updatePackageId}*/}
            {/*      onChange={(e) => {*/}
            {/*        setUpdatePackageId(e.target.checked);*/}
            {/*      }}*/}
            {/*      disabled={*/}
            {/*        loading ||*/}
            {/*        !RoleCheckService.canManageMedicationSettings(user)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <span>{t("packageIdLabel")}</span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <Checkbox*/}
            {/*      checked={updateDescription}*/}
            {/*      onChange={(e) => {*/}
            {/*        setUpdateDescription(e.target.checked);*/}
            {/*      }}*/}
            {/*      disabled={*/}
            {/*        loading ||*/}
            {/*        !RoleCheckService.canManageMedicationSettings(user)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <span>{t("descriptionLabel")}</span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <Checkbox*/}
            {/*      checked={updateManufacturerName}*/}
            {/*      onChange={(e) => {*/}
            {/*        setUpdateManufacturerName(e.target.checked);*/}
            {/*      }}*/}
            {/*      disabled={*/}
            {/*        loading ||*/}
            {/*        !RoleCheckService.canManageMedicationSettings(user)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <span>{t("manufacturerLabel")}</span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <Checkbox*/}
            {/*      checked={updateSubstitutionCode}*/}
            {/*      onChange={(e) => {*/}
            {/*        setUpdateSubstitutionCode(e.target.checked);*/}
            {/*      }}*/}
            {/*      disabled={*/}
            {/*        loading ||*/}
            {/*        !RoleCheckService.canManageMedicationSettings(user)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <span>{t("gcnLabel")}</span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <Checkbox*/}
            {/*      checked={updatePackageInfo}*/}
            {/*      onChange={(e) => {*/}
            {/*        setUpdatePackageInfo(e.target.checked);*/}
            {/*      }}*/}
            {/*      disabled={*/}
            {/*        loading ||*/}
            {/*        !RoleCheckService.canManageMedicationSettings(user)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <span>{t("packageLabel")}</span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row style={{ height: "20px" }}></Row>*/}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <span>{t("fdbImagePullLabel")}</span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={20}>*/}
            {/*  <Col>*/}
            {/*    <Checkbox*/}
            {/*      checked={updateImages}*/}
            {/*      onChange={(e) => {*/}
            {/*        setUpdateImages(e.target.checked);*/}
            {/*      }}*/}
            {/*      disabled={*/}
            {/*        loading ||*/}
            {/*        !RoleCheckService.canManageMedicationSettings(user)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <span>{t("imageLabel")}</span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </Space>
        </Content>
        <Footer style={{ background: "white", textAlign: "center" }}>
          <Button
            type="primary"
            onClick={showModal}
            disabled={!RoleCheckService.canManageMedicationSettings(user)}
          >
            {t("saveButtonLabel")}
          </Button>
        </Footer>
      </Layout>
      <Modal
        title={t("saveTitle")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("okLabel")}
        cancelText={t("cancelButton")}
      >
        <p>{t("saveConfirmation")}</p>
      </Modal>
    </>
  );
};

export default AccountSettings;

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import DailyTotalBarChart from "./DailyTotalBarChart/DailyTotalBarChart";
import { ReactComponent as ArrowUpCircle } from "../../../../../../assets/arrow-up-circle.svg";
import { ReactComponent as ArrowDownCircle } from "../../../../../../assets/arrow-down-circle.svg";
import { getLocalizedDate } from "../../../../../../functions/localization";
import {
  getNumberOfWeekDayInDateRange,
  getNumberOfDaysInDateRange,
} from "../../../../../../functions/dates";
import styles from "./MetricsChartView.module.css";
const MetricsChartViewCanisters = ({
  user,
  selectedDayCanister,
  onSelectedDayCanister,
  selectedDateRange,
  applicableDates,
  onApplicableDates,
  canisterProducts,
  onCanisterProducts,
  onCanisterProductsSelectedDay,
  canisterProductSelectionLookup,
  onCanisterProductSelectionLookup,
  onShowDropdowns,
  batchesCurrentWeek,
  batchesLastWeek,
  batchesSelectedDateRange,
}) => {
  const { t } = useTranslation("trupakDashboard");

  const [
    totalDispensedCanisterSelectedDateRange,
    setTotalDispensedCanisterSelectedDateRange,
  ] = useState({
    U: 0,
    M: 0,
    T: 0,
    W: 0,
    R: 0,
    F: 0,
    S: 0,
    total: 0,
  });

  const [canisterBargraphData, setCanisterBargraphData] = useState([]);
  const [classNameForBargraph, setClassNameforBargraph] = useState(null);

  const [
    totalPillsDispensedFromCanisterLastWeek,
    setTotalPillsDispensedFromCanisterLastWeek,
  ] = useState(0);

  const [
    totalPillsDispensedFromCanisterCurrentWeek,
    setTotalPillsDispensedFromCanisterCurrentWeek,
  ] = useState(0);

  const [name, setName] = useState("JB");

  const [
    dailyAverageCanisterSelectedDateRange,
    setDailyAverageCanisterSelectedDateRange,
  ] = useState(0);

  const [dispensingOrderIds, setDispensingOrderIds] = useState([]);
  const [packageIdsList, setPackageIdsList] = useState([]);
  const [readyForServerRequest, setReadyForServerRequest] = useState(false);

  useEffect(() => {
    /**
     * Very important
     *  - Needed to clear canisterProducts and
     *      dailyAverageCanisterSelectedDateRange if there are no trays
     **/
    const canisterBargraphDataWithValues = canisterBargraphData.filter(
      (dataSet) => dataSet.y > 0
    );

    if (!canisterBargraphDataWithValues.length) {
      onCanisterProducts([]);
      // onCanisterProductSelectionLookup({});
      setDailyAverageCanisterSelectedDateRange(0);
    }
  }, [canisterBargraphData, onCanisterProducts]);

  // SELECTED DATE RANGE
  useEffect(() => {
    /**
     * If batchesSelectedDateRange array has length, sets:
     *  - readyForServerRequest
     *  - totalDispensedCanisterSelectedDateRange
     *  - canisterBargraphData
     *  - canisteryProductSelectionLookup,
     *  - dispensingOrderIds
     *  - packagedIdsList
     *
     * Else, resets the following to default (starting) values:
     *  - canisterProducts,
     *  - canisterProductsSelectedDay,
     *  - canisterProductSelectionLookup,
     *  - canisterBargraphData,
     *  - selectedCanisterDate
     *  - readyForServerRequest
     **/
    if (batchesSelectedDateRange.length) {
      let canisterPackageIds = [];
      let tempDispensingOrderIds = [];
      let canisterProducts_temp = [];
      let totalCanisterQuantity = 0;
      let tempCanisterQtySunday = 0;
      let tempCanisterQtyMonday = 0;
      let tempCanisterQtyTuesday = 0;
      let tempCanisterQtyWednesday = 0;
      let tempCanisterQtyThursday = 0;
      let tempCanisterQtyFriday = 0;
      let tempCanisterQtySaturday = 0;
      let dayOfWeek;
      let tempCanisterProductSelectionLookup = {};

      const incrementQuantityForDayOfWeek = (day, quantity) => {
        switch (day) {
          case 0:
            tempCanisterQtySunday += quantity;
            break;
          case 1:
            tempCanisterQtyMonday += quantity;
            break;
          case 2:
            tempCanisterQtyTuesday += quantity;
            break;
          case 3:
            tempCanisterQtyWednesday += quantity;
            break;
          case 4:
            tempCanisterQtyThursday += quantity;
            break;
          case 5:
            tempCanisterQtyFriday += quantity;
            break;
          case 6:
            tempCanisterQtySaturday += quantity;
            break;
          default:
        }
      };

      for (const batchSelectedDateRange of batchesSelectedDateRange) {
        dayOfWeek = new Date(batchSelectedDateRange.createdAt).getDay(); // gets day of corresponding local time

        if (
          !tempDispensingOrderIds.includes(
            batchSelectedDateRange.dispensingOrderId
          )
        ) {
          tempDispensingOrderIds.push(batchSelectedDateRange.dispensingOrderId);
        }
        for (const dispensingDetail of batchSelectedDateRange.dispensingDetails) {
          if (!dispensingDetail.isTrayMed) {
            if (!canisterPackageIds.includes(dispensingDetail.packageId)) {
              canisterPackageIds.push(dispensingDetail.packageId);
              canisterProducts_temp.push({
                dispensingOrderId: batchSelectedDateRange.dispensingOrderId,
                packageId: dispensingDetail.packageId,
                productName: dispensingDetail.productDescription,
                qty: dispensingDetail.quantity,
                dayOfWeek: dayOfWeek,
                manufacturer: dispensingDetail.productManufacturer,
              });
            } else {
              if (
                canisterProducts_temp.some(
                  (p) => p.packageId === dispensingDetail.packageId
                )
              ) {
                const product = canisterProducts_temp.find(
                  (p) => p.packageId === dispensingDetail.packageId
                );
                if (product.dayOfWeek === dayOfWeek) {
                  product.qty += dispensingDetail.quantity;
                }
              }
            }
          }
        } // end for batch of
      }

      canisterProducts_temp.sort(compareByQuantityDescending);

      canisterProducts_temp.forEach((product) => {
        totalCanisterQuantity += product.qty;
        incrementQuantityForDayOfWeek(product.dayOfWeek, product.qty);
        tempCanisterProductSelectionLookup[product.packageId] = {
          selected: false,
          product: product.productName,
          manufacturer: product.manufacturer,
        };
      });

      setReadyForServerRequest(true);

      setTotalDispensedCanisterSelectedDateRange({
        total: totalCanisterQuantity,
        U: tempCanisterQtySunday,
        M: tempCanisterQtyMonday,
        T: tempCanisterQtyTuesday,
        W: tempCanisterQtyWednesday,
        R: tempCanisterQtyThursday,
        F: tempCanisterQtyFriday,
        S: tempCanisterQtySaturday,
      });

      setCanisterBargraphData([
        {
          x: t("Sunday"),
          y: tempCanisterQtySunday > 0 ? tempCanisterQtySunday : 0,
        },
        {
          x: t("Monday"),
          y: tempCanisterQtyMonday > 0 ? tempCanisterQtyMonday : 0,
        },
        {
          x: t("Tuesday"),
          y: tempCanisterQtyTuesday > 0 ? tempCanisterQtyTuesday : 0,
        },
        {
          x: t("Wednesday"),
          y: tempCanisterQtyWednesday > 0 ? tempCanisterQtyWednesday : 0,
        },
        {
          x: t("Thursday"),
          y: tempCanisterQtyThursday > 0 ? tempCanisterQtyThursday : 0,
        },
        {
          x: t("Friday"),
          y: tempCanisterQtyFriday > 0 ? tempCanisterQtyFriday : 0,
        },
        {
          x: t("Saturday"),
          y: tempCanisterQtySaturday > 0 ? tempCanisterQtySaturday : 0,
        },
      ]);

      onCanisterProductSelectionLookup(tempCanisterProductSelectionLookup);
      onCanisterProducts(canisterProducts_temp);
      setDispensingOrderIds(tempDispensingOrderIds);

      // convert packageIds array to string list
      let tempPackageIdsList = "";

      canisterPackageIds.forEach((packageId) => {
        tempPackageIdsList += `${packageId},`;
      });

      tempPackageIdsList = tempPackageIdsList.substring(
        0,
        tempPackageIdsList.length - 1
      );

      setPackageIdsList(tempPackageIdsList);
    } else {
      onCanisterProducts([]);
      onCanisterProductsSelectedDay([]);
      onCanisterProductSelectionLookup({});
      setCanisterBargraphData([]);
      setReadyForServerRequest(false);
    }
  }, [
    user,
    batchesSelectedDateRange,
    onCanisterProducts,
    onCanisterProductSelectionLookup,
    onCanisterProductsSelectedDay,
    t,
  ]);

  // SELECTED DATE RANGE
  useEffect(() => {
    /**
     * Sets dailyAverageCanisterSelectedDateRange
     *  - If selectedDayCanister is null, this is the average across the entire selected date range
     *  - If selectedDayCanister is set, this is the average for that specific day over the date range
     **/
    let tempDailyAverageCanisterSelectedDateRange;
    if (
      selectedDateRange?.startDate &&
      selectedDateRange?.endDate &&
      !applicableDates &&
      !selectedDayCanister &&
      selectedDayCanister !== 0
    ) {
      tempDailyAverageCanisterSelectedDateRange = (
        totalDispensedCanisterSelectedDateRange.total /
        getNumberOfDaysInDateRange(
          selectedDateRange.startDate,
          selectedDateRange.endDate
        )
      ).toFixed(1);

      setDailyAverageCanisterSelectedDateRange(
        tempDailyAverageCanisterSelectedDateRange
      );
    } else if (selectedDayCanister || selectedDayCanister === 0) {
      let totalCanisterPillsForSelectedDay;
      switch (selectedDayCanister) {
        case 0:
          totalCanisterPillsForSelectedDay =
            totalDispensedCanisterSelectedDateRange.U;
          break;
        case 1:
          totalCanisterPillsForSelectedDay =
            totalDispensedCanisterSelectedDateRange.M;
          break;
        case 2:
          totalCanisterPillsForSelectedDay =
            totalDispensedCanisterSelectedDateRange.T;
          break;
        case 3:
          totalCanisterPillsForSelectedDay =
            totalDispensedCanisterSelectedDateRange.W;
          break;
        case 4:
          totalCanisterPillsForSelectedDay =
            totalDispensedCanisterSelectedDateRange.R;
          break;
        case 5:
          totalCanisterPillsForSelectedDay =
            totalDispensedCanisterSelectedDateRange.F;
          break;
        case 6:
          totalCanisterPillsForSelectedDay =
            totalDispensedCanisterSelectedDateRange.S;
          break;
        default:
        // code block
      }

      if (applicableDates && applicableDates.length > 0) {
        tempDailyAverageCanisterSelectedDateRange =
          totalCanisterPillsForSelectedDay /
          getNumberOfWeekDayInDateRange(
            selectedDateRange.startDate,
            selectedDateRange.endDate,
            selectedDayCanister
          );

        if (tempDailyAverageCanisterSelectedDateRange) {
          setDailyAverageCanisterSelectedDateRange(
            tempDailyAverageCanisterSelectedDateRange.toFixed(1)
          );
        } else {
          setDailyAverageCanisterSelectedDateRange((0.0).toFixed(1));
        }
      } else {
        setDailyAverageCanisterSelectedDateRange((0.0).toFixed(1));
      }
    } else {
      setDailyAverageCanisterSelectedDateRange((0.0).toFixed(1));
    }
  }, [
    selectedDateRange,
    totalDispensedCanisterSelectedDateRange,
    selectedDayCanister,
    applicableDates,
  ]);

  // FOR BATCHES LAST WEEK
  useEffect(() => {
    /**
     * Sets:
     *  - totalDispensedPillsFromCanisterLastWeek
     **/
    let totalPillQuantity = 0;

    for (const batchLastWeek of batchesLastWeek) {
      for (const dispensingDetail of batchLastWeek.dispensingDetails) {
        if (dispensingDetail.isTrayMed && !dispensingDetail.isPartial) {
          totalPillQuantity += dispensingDetail.quantity;
        }
      }
    }
    setTotalPillsDispensedFromCanisterLastWeek(totalPillQuantity);
  }, [batchesLastWeek]);

  // FOR BATCHES CURRENT WEEK
  useEffect(() => {
    /**
     * Sets:
     *  - totalDispensedPillsFromCanisterCurrentWeek
     **/
    let totalPillQuantity = 0;

    for (const batchCurrentWeek of batchesCurrentWeek) {
      for (const dispensingDetail of batchCurrentWeek.dispensingDetails) {
        if (dispensingDetail.isTrayMed && !dispensingDetail.isPartial) {
          totalPillQuantity += dispensingDetail.quantity;
        }
      }
    }
    setTotalPillsDispensedFromCanisterCurrentWeek(totalPillQuantity);
  }, [batchesCurrentWeek]);

  // /***** Calculate percent increase from last week ****/
  let percentIncreaseFromLastWeek = NaN;

  if (totalPillsDispensedFromCanisterLastWeek > 0) {
    percentIncreaseFromLastWeek =
      ((totalPillsDispensedFromCanisterCurrentWeek -
        totalPillsDispensedFromCanisterLastWeek) /
        totalPillsDispensedFromCanisterLastWeek) *
      100;
  }

  // SELECTED DAY
  const handleCanisterProductsSelectedDay = (day) => {
    /**
     * Sets trayProductsSelectedDay
     * Note that this method also initializes the number of trays each product to 0
     * The tray count for each product is later calculated and updated by ...
     **/
    let canisterPackageIds = [];
    let canisterProducts_temp = [];
    let dayOfWeek;
    let batchesSelectedDay = batchesSelectedDateRange.filter(
      (batchSelectedDay, index) => {
        dayOfWeek = new Date(batchSelectedDay.createdAt).getDay();
        return (
          (dayOfWeek === day || (dayOfWeek === 0 && day === 0)) &&
          batchSelectedDay.totalQty -
            batchSelectedDay.trayWholeQty -
            batchSelectedDay.trayPartialQty >
            0
        );
      }
    );

    let dispensingOrderIds = [];
    let tempApplicableDates = [];

    for (const batchSelectedDay of batchesSelectedDay) {
      if (!(batchSelectedDay.createdAt in tempApplicableDates)) {
        tempApplicableDates.push(batchSelectedDay.createdAt);
      }

      if (!dispensingOrderIds.includes(batchSelectedDay.dispensingOrderId)) {
        dispensingOrderIds.push(batchSelectedDay.dispensingOrderId);
      }

      for (const dispensingDetail of batchSelectedDay.dispensingDetails) {
        if (
          !canisterPackageIds.includes(dispensingDetail.packageId) &&
          dispensingDetail.isTrayMed &&
          !dispensingDetail.isPartial
        ) {
          canisterPackageIds.push(dispensingDetail.packageId);
        }
      }
    }

    let canisterProductsSelectedTemp = [];
    canisterProducts_temp.sort(compareByQuantityDescending);

    canisterProducts.forEach((product) => {
      canisterProductsSelectedTemp.push({
        packageId: product.packageId,
        productName: product.productName,
        qty: product.qty,
        dayOfWeek: product.dayOfWeek,
      });
    });

    canisterProductsSelectedTemp = [
      ...canisterProductsSelectedTemp.filter(
        (product) => product.dayOfWeek === day
      ),
    ];

    onCanisterProductsSelectedDay(canisterProductsSelectedTemp);
    tempApplicableDates.sort((a, b) => new Date(a) - new Date(b));
    onApplicableDates(tempApplicableDates);
    setReadyForServerRequest(true);
  };

  let percentMessage = `${parseFloat(percentIncreaseFromLastWeek).toFixed(
    1
  )}% ${t("increase from last week")}`;
  let IconIndicator = <ArrowUpCircle />;

  if (isNaN(percentIncreaseFromLastWeek)) {
    percentMessage = "No fills last week";
  } else if (percentIncreaseFromLastWeek < 0) {
    IconIndicator = <ArrowDownCircle />;
    percentMessage = `${(-parseFloat(percentIncreaseFromLastWeek)).toFixed(
      1
    )}% ${t("decrease from last week")}`;
  }

  const dailyAverage = dailyAverageCanisterSelectedDateRange;

  const compareByQuantityDescending = (a, b) => {
    if (a.qty < b.qty) {
      return 1;
    } else if (a.qty > b.qty) {
      return -1;
    } else if (a.productName.toUpperCase() < b.productName.toUpperCase()) {
      return -1;
    }
    return 1;
  };

  const handleClassNameForBargraph = (name) => {
    setClassNameforBargraph(name);
  };

  const handleNullifyApplicableDates = () => {
    onApplicableDates(null);
  };

  let startDateLocalized = getLocalizedDate(
    selectedDateRange.startDate,
    user?.defaultSite?.shippingAddress?.country
  );

  let stopDateLocalized = getLocalizedDate(
    selectedDateRange.endDate,
    user?.defaultSite?.shippingAddress?.country
  );

  const dateRangeForTitle = `${startDateLocalized} - ${stopDateLocalized}`;

  const tableTitle = (
    <div className={styles.MetricChartView__bargraphTitle}>
      <h2 className={styles.MetricChartView__bargraphTitleMain}>
        {t("Daily Canister Usage")}
      </h2>
    </div>
  );

  return (
    <>
      <div className={styles.MetricChartView__tableContainer}>
        <div className={styles.MetricChartView__bargraphHeadingContainer}>
          <div className={styles.MetricChartView__dailyAverageContainer}>
            <h3>{t("Daily Average")}</h3>
            <p className={styles.MetricChartView_dailyAverage}>
              {dailyAverage}
            </p>
          </div>
          {tableTitle}
          <div
            className={styles.MetricChartView__percentageFromLastWeekContainer}
          >
            {IconIndicator}
            <p>{percentMessage}</p>
          </div>
        </div>

        <DailyTotalBarChart
          user={user}
          stylesName="BarGraph"
          data={canisterBargraphData}
          chartWidth="700"
          chartHeight="300"
          onProductsSelectedDay={handleCanisterProductsSelectedDay}
          selectedDay={selectedDayCanister}
          onSelectedDay={onSelectedDayCanister}
          classNameForBargraph={classNameForBargraph}
          onClassNameForBargraph={handleClassNameForBargraph}
          productSelectionLookup={canisterProductSelectionLookup}
          onProductSelectionLookup={onCanisterProductSelectionLookup}
          onShowDropdowns={onShowDropdowns}
          onNullifyApplicableDates={handleNullifyApplicableDates}
        />
      </div>
    </>
  );
};

export default MetricsChartViewCanisters;

import axios from "axios";
import ApiService from "./ApiService";

class ProductService extends ApiService {
  static createProduct = async (user, product) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products`,
        product,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static productExists = async (user, id) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${
          user.account._id
        }/products/exists?id=${encodeURIComponent(id)}`,
        rc
      );
      return response.data.exists;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static list = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products`,
        rc
      );
      return response.data.products;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getEV54Products = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/ev54`,
        rc
      );
      return response.data.products;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static search = async (user, searchText) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products?searchText=${searchText}`,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static searchPaginated = async (
    user,
    searchText,
    page,
    limit,
    isPendingFirst
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products?searchText=${searchText}&page=${page}&limit=${limit}&isPendingFirst=${isPendingFirst}`,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getPendingProducts = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products?isPending=true`,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static update = async (userLoggedIn, product) => {
    try {
      const rc = {
        headers: { "x-access-token": userLoggedIn.token },
      };

      let response = await axios.put(
        `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id}/products/${product._id}`,
        { product: product },
        rc
      );
      return response.data.product;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static enable = async (userLoggedIn, product) => {
    try {
      const rc = {
        headers: { "x-access-token": userLoggedIn.token },
      };

      let response = await axios.put(
        `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id}/products/${product._id}/enable`,
        { product: product },
        rc
      );
      return response.data.product;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static delete = async (user, product) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.delete(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/${product._id}`,
        rc
      );
      return response.data.product;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getProductCategories = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/product-categories`,
        rc
      );
      return response.data.productCategories;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static addProductCategory = async (user, name) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/product-categories`,
        { name: name },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static productCategoryExists = async (user, name) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${
          user.account._id
        }/product-categories/exists?name=${encodeURIComponent(name)}`,
        rc
      );
      return response.data.exists;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static removeProductCategory = async (user, productCategory) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.delete(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/product-categories/${productCategory._id}`,
        rc
      );
      return response.data.productCategory; // user document
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updateProductCategory = async (user, productCategory) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.put(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/product-categories/${productCategory._id}`,
        { productCategory: productCategory },
        rc
      );
      return response.data.productCategory;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static addRoute = async (user, route) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/routes`,
        { route: route },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static addDosageForm = async (user, dosageForm) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dosage-forms`,
        { dosageForm: dosageForm },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getDosageForms = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/dosage-forms`,
        rc
      );
      return response.data.dosageForms;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getRoutes = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/routes`,
        rc
      );
      return response.data.routes;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getProductByBarcode = async (user, barcode, defaultImage) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products?barcode=${barcode}&defaultImage=${defaultImage}`,
        rc
      );
      return response.data.product;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getProductByPackageId = async (user, packageId) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/packageId/${packageId}/?packageId=${packageId}`,
        rc
      );
      return response.data.product;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getTherapeuticEquivalencies = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/therapeutic-equivalencies`,
        rc
      );
      return response.data.therapeuticEquivalencies;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getTherapeuticEquivalentProducts = async (user, teCode) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/therapeutic-equivalencies?teCode=${teCode}`,
        rc
      );
      return response.data.therapeuticEquivalentProducts;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getWithNoTECode = async (user, search) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/therapeutic-equivalencies/available-products?search=${search}`,
        rc
      );
      return response.data.products;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static upload = async (user, fileName) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/products/upload?fileName=${fileName}`;

      const rc = {
        headers: { "x-access-token": user.token },
      };

      await axios.get(url, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default ProductService;

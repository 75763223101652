import ApiService from "./ApiService";
import axios from "axios";

class ReportService extends ApiService {
  static create = async (user, report) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/reports`;

      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(url, { report: report }, rc);
      return response.data.report;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  // For Reports2 Testing
  static create2 = async (user, reportObj) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/reports2`;

      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(
        url,
        { user: user, reportObj: reportObj },
        rc
      );
      return response.data.report;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static update = async (userLoggedIn, report) => {
    try {
      const rc = {
        headers: { "x-access-token": userLoggedIn.token },
      };

      let response = await axios.put(
        `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id}/reports/${report._id}`,
        { report: report },
        rc
      );
      return response.data.report;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static delete = async (user, report) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.delete(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/reports/${report._id}`,
        rc
      );
      return response.data.report;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  // For Reports2 Testing
  static delete2 = async (user, report) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.delete(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/reports/${report._id}`,
        rc
      );
      return response.data.report;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static list = async (user) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/reports`;

      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(url, rc);
      return response.data.reports;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  // For Reports2
  static list2 = async (user) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/reports2`;

      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(url, rc);
      return response.data.reports;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static exists = async (user, name) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${
          user.account._id
        }/reports/exists?name=${encodeURIComponent(name)}`,
        rc
      );
      return response.data.exists;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static run = async (user, query) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/reports/run`,
        { query: query },
        rc
      );
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static run2 = async (user, query) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/reports/run2`,
        { query: query },
        rc
      );
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getCanisterManuallyDispensedData = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/reports/canisterManagement`,
        rc
      );
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getSchemaPaths = async (user, schema) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/schemas/${schema}/paths`,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default ReportService;

import React, { useState, useEffect, useCallback } from "react";
import { Alert, notification } from "antd";
import { ReactComponent as BackButton } from "../../../assets/back_icon.svg";
import { TruPakHeaders } from "./OrderGeneratorTableHeaders";
import { TruPakInputTypes } from "./OrderGeneratorTableInputTypes";
import { TruPakRelativeColumnWidths } from "./OrderGeneratorRelativeColumnWidths";
import { TruPakMinColumnWidths } from "./OrderGeneratorMinColumnWidths";
import { dataValidityChecksTruPak } from "./OrderGeneratorDataValidationChecks";
import Products from "../../Product/Products";
import AntSpin from "../../../components/Ant/AntSpin/AntSpin";
import ResizableTable from "./ResizableTable";
import styles from "./OrderGenerator.module.css";

const OrderGenerator = ({ onGenerationType, site, user, selectedDevice }) => {
  const [uploadCSVFileError, setUploadCSVFileError] = useState("");
  const [batchOrderSuccessfullyCreated, setBatchOrderSuccessfullyCreated] =
    useState(false);
  const [generatingBatchOrder, setGeneratingBatchOrder] = useState(false);
  const [productSelectionObject, setProductSelectionObject] = useState({
    row: -1,
    column: -1,
    show: false,
  });
  const [selectedPackageId, setSelectedPackageId] = useState("");

  const handleUploadCSVFileError = (errorMsg) => {
    setUploadCSVFileError(errorMsg);
  };

  const handleBatchOrderSuccessfullyCreated = (boolVal) => {
    setBatchOrderSuccessfullyCreated(boolVal);
  };

  const openNotificationWithIcon = useCallback((type) => {
    notification[type]({
      message: "Success!",
      description: "Batch order was created.",
      duration: 5,
      placement: "top",
      onClose: () => handleBatchOrderSuccessfullyCreated(false),
    });
  }, []);

  const handleGeneratingBatchOrder = (boolValue) => {
    setGeneratingBatchOrder(boolValue);
  };

  useEffect(() => {
    if (batchOrderSuccessfullyCreated) {
      openNotificationWithIcon("success");
    }
  }, [batchOrderSuccessfullyCreated, openNotificationWithIcon]);

  const handleProductSelectionObject = (rowIndex, columnIndex) => {
    setProductSelectionObject((prevState) => {
      if (
        rowIndex === prevState.row &&
        columnIndex === prevState.column &&
        !prevState.show
      ) {
        return {
          row: rowIndex,
          column: columnIndex,
          show: true,
        };
      } else if (
        rowIndex === prevState.row &&
        columnIndex === prevState.column &&
        prevState.show
      ) {
        return {
          row: rowIndex,
          column: columnIndex,
          show: false,
        };
      } else {
        return {
          row: rowIndex,
          column: columnIndex,
          show: true,
        };
      }
    });
  };

  const resetProductSelectionObjectToDefault = useCallback(
    () =>
      setProductSelectionObject({
        row: -1,
        column: -1,
        show: false,
      }),
    []
  );

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handlePackageId = (id) => {
    setSelectedPackageId(id);
  };

  const handleHideProductsTable = () => {
    setProductSelectionObject((prevState) => {
      return { ...prevState, show: false };
    });
  };

  return (
    <div className={styles.OrderGenerator__container}>
      <div className={styles.OrderGenerator__backButtonContainer}>
        <BackButton
          onClick={
            productSelectionObject.show
              ? () => {
                  setProductSelectionObject({
                    row: -1,
                    column: -1,
                    show: false,
                  });
                }
              : () => onGenerationType("")
          }
        />
        <div className={styles.OrderGenerator__HeadingsContainer}>
          <h1 style={{color: 'black'}}>{site.name} Order Generator</h1>
          <h2
            className={
              productSelectionObject.show
                ? styles.OrderGenerator__PackageIDSelectionHeading
                : styles["OrderGenerator__PackageIDSelectionHeading--hide"]
            }
          >
            Package ID Selection Table
          </h2>
        </div>
        {generatingBatchOrder && (
          <div className={styles.OrderGenerator__spin}>
            <AntSpin
              message="Generating batch order..."
              containerStyle="container-000"
              spinnerStyle="ant-spin-000"
              messageStyle="message-000"
              spinSize="default"
            />
          </div>
        )}
      </div>
      {uploadCSVFileError && (
        <div className={styles.OrderGenerator__alertContainer}>
          <Alert
            message="Unable to populate CSV File data into batch order table"
            description={uploadCSVFileError}
            type="error"
            showIcon
            closable
            onClose={() => setUploadCSVFileError("")}
          />
        </div>
      )}
      {productSelectionObject.show && productSelectionObject.column === 3 && (
        <div
          onClick={() =>
            setProductSelectionObject((prevState) => {
              return { ...prevState, show: false };
            })
          }
        >
          <div onClick={handleClick}>
            <Products
              user={user}
              alarm={null}
              onPackageId={handlePackageId}
              onHideProductsTable={handleHideProductsTable}
            />
          </div>
        </div>
      )}
      <div
        style={{
          height: "100%",
          display:
            productSelectionObject.show && productSelectionObject.column === 3
              ? "none"
              : "block",
        }}
      >
        <ResizableTable
          site={site}
          user={user}
          selectedDevice={selectedDevice}
          headers={TruPakHeaders}
          inputTypes={TruPakInputTypes}
          startingRelativeColumnWidths={TruPakRelativeColumnWidths}
          minColumnWidths={TruPakMinColumnWidths}
          dataValidationChecks={dataValidityChecksTruPak}
          onUploadCSVFileError={handleUploadCSVFileError}
          onBatchOrderSuccessfullyCreated={handleBatchOrderSuccessfullyCreated}
          onGeneratingBatchOrder={handleGeneratingBatchOrder}
          productSelectionObject={productSelectionObject}
          onProductSelectionObject={handleProductSelectionObject}
          selectedPackageId={selectedPackageId}
          resetProductSelectionObjectToDefault={
            resetProductSelectionObjectToDefault
          }
        />
      </div>
    </div>
  );
};

export default OrderGenerator;

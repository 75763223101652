import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ReactComponent as Square } from "../../../../../assets/square.svg";
import AntButton from "../../../../../components/Ant/AntButton/AntButton";
import SelectTrayPillsForCanister from "./SelectTrayPillsForCanister";
import styles from "./DispensedMedicationsTable.module.css";
import "./DispensedMedicationsTableAntStyles.css";

const DispensedMedicationsTableForTrays = ({
  user,
  startDateLastWeek,
  stopDateLastWeek,
  selectedDateRange,
  applicableDates,
  selectedDayTray,
  trayProductSelectionLookup,
  onTrayProductSelectionLookup,
  trayProductsSelectedDay,
  trayProducts,
  showCanisterPlacementPopup,
  onShowCanisterPlacementPopup,
}) => {
  const LIMIT_NUM_PRODUCTS_HIGHEST_QTY = 25;
  const { t } = useTranslation("trupakDashboard");
  const [dataTrayTable, setDataTrayTable] = useState([]);
  const [allTrayDrugsSelected, setAllTrayDrugsSelected] = useState(false);
  const [atleastOneTrayDrugSelected, setAtleastOneTrayDrugSelected] =
    useState(false);
  const [tableTitle, setTableTitle] = useState("");
  const [trayProductsFiltered, setTrayProductsFiltered] = useState([]);
  const [trayProductsSelectedDayFiltered, setTrayProductsSelectedDayFiltered] =
    useState([]);

  useEffect(() => {
    setTrayProductsFiltered(
      trayProducts.filter((product, index) => {
        if (index < LIMIT_NUM_PRODUCTS_HIGHEST_QTY) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [trayProducts]);

  useEffect(() => {
    setTrayProductsSelectedDayFiltered(
      trayProductsSelectedDay.filter((product, index) => {
        if (index < LIMIT_NUM_PRODUCTS_HIGHEST_QTY) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [trayProductsSelectedDay]);

  const handleRowSelection = useCallback(
    (packageId) => {
      let tempRowStatus = { ...trayProductSelectionLookup };
      if (tempRowStatus[packageId].selected === true) {
        tempRowStatus[packageId].selected = false;
        setAllTrayDrugsSelected(false);
      } else {
        if (tempRowStatus[packageId].selected === false) {
          tempRowStatus[packageId].selected = true;
        }
      }
      onTrayProductSelectionLookup(tempRowStatus);
    },
    [onTrayProductSelectionLookup, trayProductSelectionLookup]
  );

  useEffect(() => {
    setAllTrayDrugsSelected(false);
    setAtleastOneTrayDrugSelected(false);
  }, [selectedDayTray]);

  useEffect(() => {
    if (selectedDayTray !== null) {
    }
  }, []);

  useEffect(() => {
    const hasKey = (obj) => {
      for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
          return true;
        }
      }
      return false;
    };
    if (hasKey(trayProductSelectionLookup)) {
      const createIDSquare = (id) => {
        const IDSquare = (
          <div
            className={
              trayProductSelectionLookup[id]?.selected === true
                ? `${styles.DispensedMedicationsTable__idSquareContainer} ${styles["DispensedMedicationsTable__idSquareContainer--active"]}`
                : styles.DispensedMedicationsTable__idSquareContainer
            }
            onClick={() => handleRowSelection(id)}
          >
            <Square width="24px" height="24px" />
          </div>
        );
        return IDSquare;
      };

      let trayProds;
      if (selectedDayTray !== null) {
        trayProds = trayProductsSelectedDayFiltered;
      } else {
        trayProds = trayProductsFiltered;
      }

      setDataTrayTable(
        trayProds.map((product) => {
          return {
            key: `${product.packageId}_tray`,
            idSquare: createIDSquare(product.packageId),
            packageId_tray: product.packageId,
            medication_tray: product.productName,
            quantity_tray: product.qty,
            trays: product.trays,
          };
        })
      );
    } else {
      setDataTrayTable([]);
    }
  }, [
    trayProductsFiltered,
    trayProductsSelectedDayFiltered,
    trayProductSelectionLookup,
    selectedDayTray,
    handleRowSelection,
  ]);

  useEffect(() => {
    let drugSelected = false;
    for (const [key, value] of Object.entries(trayProductSelectionLookup)) {
      if (value?.selected) {
        drugSelected = true;
        break;
      }
    }
    setAtleastOneTrayDrugSelected(drugSelected);
    if (!drugSelected) {
      onShowCanisterPlacementPopup(false);
    }
  }, [trayProductSelectionLookup, onShowCanisterPlacementPopup]);

  useEffect(() => {
    let tempTableTitle = "";
    if (selectedDayTray || selectedDayTray === 0) {
      const selectedDate = new Date(selectedDateRange.startDate);
      selectedDate.setDate(selectedDate.getDate() + selectedDayTray);
    }

    tempTableTitle = (
      <div className={styles.DispensedMedicationsTable__tableTitle}>
        <h2 className={styles.DispensedMedicationsTable__tableTitleMain}>
          {t("Most Filled")}
        </h2>
        <p className={styles.DispensedMedicationsTable__limitMessage}>
          ({`Top ${LIMIT_NUM_PRODUCTS_HIGHEST_QTY} By Qty Filled`})
        </p>
      </div>
    );

    setTableTitle(tempTableTitle);
  }, [selectedDayTray, selectedDateRange, user, t]);

  const handleChange = (pagination, filters, sorter) => {
    // console.log("Various parameters", pagination, filters, sorter);
  };

  const trayColumnsInfo = [
    {
      title: (
        <div
          className={
            allTrayDrugsSelected
              ? `${styles.DispensedMedicationsTable__idSquareContainer} ${styles["DispensedMedicationsTable__idSquareContainer--active"]}`
              : styles.DispensedMedicationsTable__idSquareContainer
          }
          onClick={() => handleAllTrayDrugsSelected()}
        >
          <Square width="24px" height="24px" />
        </div>
      ),
      dataIndex: "idSquare",
      key: "idSquare",
      width: "26px",
    },
    {
      title: t("Drug ID"),
      dataIndex: "packageId_tray",
      key: "packageId_tray",
      sorter: (a, b) => {
        return a.packageId_tray.localeCompare(b.packageId_tray);
      },
      sortDirections: ["ascend", "descend"],
      width: "180px",
    },
    {
      title: t("Description"),
      dataIndex: "medication_tray",
      key: "medication_tray",
      sorter: (a, b) => {
        return a.medication_tray.localeCompare(b.medication_tray);
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      align: "right",
      title: t("QTY Filled"),
      dataIndex: "quantity_tray",
      key: "quantity_tray",
      sorter: (a, b) => a.quantity_tray - b.quantity_tray,
      sortDirections: ["ascend", "descend"],
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            console.log("row & cell info: ", record, rowIndex, ev);
          },
          style: { cursor: "pointer" },
        };
      },
      width: "175px",
    },
    {
      align: "right",
      title: t("Total Trays"),
      dataIndex: "trays",
      key: "trays",
      sorter: (a, b) => a.trays - b.trays,
      sortDirections: ["ascend", "descend"],
      width: "175px",
    },
  ];

  const data = dataTrayTable;
  const columnsInfo = trayColumnsInfo;

  const columns = columnsInfo.map((column) => {
    return {
      title: column.title,
      dataIndex: column.dataIndex,
      key: column.key,
      sortColumnKey: column.key,
      width: column.width ? column.width : null,
      sorter: column.sorter ? column.sorter : null,
      ellipsis: true,
      onCell: column.onCell ? column.onCell : null,
    };
  });

  const handleAllTrayDrugsSelected = () => {
    let tempRowStatus = { ...trayProductSelectionLookup };

    if (allTrayDrugsSelected) {
      Object.keys(trayProductSelectionLookup).forEach((key) => {
        tempRowStatus[key].selected = false;
      });
      setAllTrayDrugsSelected(false);
    } else {
      if (selectedDayTray === null) {
        Object.keys(trayProductSelectionLookup).forEach((key) => {
          tempRowStatus[key].selected = true;
        });
        setAllTrayDrugsSelected(true);
      } else {
        const validPackageIds = trayProductsSelectedDay.map(
          (product) => product.packageId
        );
        Object.keys(trayProductSelectionLookup).forEach((key) => {
          if (validPackageIds.includes(key)) {
            tempRowStatus[key].selected = true;
          } else {
            tempRowStatus[key].selected = false;
          }
        });
      }
      setAllTrayDrugsSelected(true);
    }
    onTrayProductSelectionLookup(tempRowStatus);
  };

  const handleCalculate = () => {
    onShowCanisterPlacementPopup(true);
  };

  if (showCanisterPlacementPopup) {
    return (
      <div
        className={
          styles.DispensedMedicationsTable__SelectTrayPillsForCanisterContainer
        }
      >
        <SelectTrayPillsForCanister
          user={user}
          trayProductSelectionLookup={trayProductSelectionLookup}
          onShowCanisterPlacementPopup={onShowCanisterPlacementPopup}
          trayProducts={trayProductsFiltered}
          trayProductsSelectedDay={trayProductsSelectedDayFiltered}
          selectedDayTray={selectedDayTray}
          startDateLastWeek={startDateLastWeek}
          stopDateLastWeek={stopDateLastWeek}
          selectedDateRange={selectedDateRange}
          applicableDates={applicableDates}
        />
      </div>
    );
  }
  return (
    <div
      className={`${styles.DispensedMedicationsTable__tableContainer} DispensedMedicationsTable__AntStyle`}
    >
      {tableTitle}
      <div className="DispensedMedicationsTable__tableTypeTrays">
        <Table
          columns={columns}
          dataSource={data}
          onChange={handleChange}
          scroll={{ y: `calc(70vh - 460px)` }}
          pagination={{ pageSize: 10 }}
        />
      </div>
      <div
        className={styles.DispensedMedicationsTable__calculationButtoncontainer}
      >
        <AntButton
          onClick={() => handleCalculate()}
          text={t("Calculate")}
          buttonstyle="ant-button-004"
          size="large"
          disabled={!atleastOneTrayDrugSelected}
        />
      </div>
    </div>
  );
};

export default DispensedMedicationsTableForTrays;

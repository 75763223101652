import ProductController from "../controllers/ProductController";

export default function validate(values) {
  const productController = new ProductController();

  let errors = {};

  if (!values.productId) {
    errors.productId = "missingProductId";
  }

  if (!values.packageId) {
    errors.packageId = "missingPackageId";
  }

  if (!values.primaryName) {
    errors.primaryName = "missingPrimaryName";
  }

  // if (!values.barcodeList) {
  //   errors.barcodeList = "missingBarcode";
  // }
  //
  // if (!values?.manufacturerName) {
  //   errors.manufacturerName = "missingManufacturerName";
  // }
  //
  // if (!values?.manufacturerAbbrName) {
  //   errors.manufacturerAbbrName = "missingManufacturerAbbrName";
  // }

  // if(values.ev54DrugName && productController.getEv54DrugNameError(values.product)){
  //     errors.ev54DrugName = productController.getEv54DrugNameError(values.product)
  // }

  // if(values.ev54DrugId && productController.getEv54DrugIdError(values.product)){
  //     errors.ev54DrugId = productController.getEv54DrugIdError(values.product)
  // }
  return errors;
}

import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
  Fragment,
} from "react";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import Node from "../../functions/Node";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import validate from "../../validation/StockLocationValidationRules";
import ConfigService from "../../services/ConfigService";
import StockLocationsController from "../../controllers/StockLocationsController";
import TextInput from "../../components/TextInput";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import Checkbox from "../../components/Checkbox";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import { useTranslation } from "react-i18next";
import TabBar from "../../components/TabBar";
import { isCanisterType } from "../../functions/utilities";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import DatePickerComponent from "../../components/DatePickerComponent/DatePickerComponent";
import styles from "./CreateStockLocations.module.css";

const CreateStockLocation = ({
                               user,
                               onClose,
                               site,
                               nodeList,
                               setNodeList,
                               selectedStockLocation,
                               currentAction,
                               ADD_STOCK_LOCATION,
                               EDIT_STOCK_LOCATION,
                               ADD_CHILD,
                               parent,
                               isSubmitting,
                               setIsSubmitting,
                               error,
                               setError,
                               errors,
                               setErrors,
                               name,
                               setName,
                               nickname,
                               setNickname,
                               type,
                               setType,
                               isControlled,
                               setIsControlled,
                               barcode,
                               setBarcode,
                               stockLocations,
                               setStockLocations,
                               stockLocationsList,
                               setStockLocationsList,
                               orderPriority,
                               setOrderPriority,
                               active,
                               setActive,
                               device,
                               setDevice,
                               tabs,
                               setTabs,
                               selectedTab,
                               setSelectedTab,
                               deviceId,
                               setDeviceId,
                               devicePosition,
                               setDevicePosition,
                             }) => {
  const { t } = useTranslation("createStockLocation");

  const _controller = useRef(null);

  const [canisterType, setCanisterType] = useState(false);
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [pouchSizesList, setPouchSizesList] = useState([]);

  // POUCH_PACKAGER Device Default Values
  const POUCH_PACKAGER_POUCH_SIZE = "70mm";
  const POUCH_PACKAGER_DELIVERY_TRANSITION_HOURS = "4";
  const POUCH_PACKAGER_POUCHES_PER_HOUR_BENCHMARK = "1000";

  const DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS = {
    pouchSize: POUCH_PACKAGER_POUCH_SIZE,
    deliveryTransitionHours: POUCH_PACKAGER_DELIVERY_TRANSITION_HOURS,
    pouchesPerHourBenchmark: POUCH_PACKAGER_POUCHES_PER_HOUR_BENCHMARK,
    batchesPerSundayBenchmark: "1",
    batchesPerMondayBenchmark: "2",
    batchesPerTuesdayBenchmark: "3",
    batchesPerWednesdayBenchmark: "4",
    batchesPerThursdayBenchmark: "5",
    batchesPerFridayBenchmark: "6",
    batchesPerSaturdayBenchmark: "7",
  };

  const DEFAULT_RETURN_SYSTEM_DEVICE_SETTINGS = {
    suctionCupLifeDays: 365,
    suctionCupStartDate: new Date(),
  };

  const DEFAULT_VIAL_FILLER_DEVICE_SETTINGS = {
    vialRemovalTime: "00:01:00.00",
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    barcodeFormat: {
      prefix: "",
      suffix: "",
      delimiter: "",
      rxNumberPosition: 0,
    },
  };

  useEffect(() => {
    if (currentAction === ADD_STOCK_LOCATION) {
      setDevice(null);
    }
  }, [currentAction]);

  useEffect(() => {
    _controller.current = new StockLocationsController(user, site);
    setType(null);
    setIsControlled(null);
    setSelectedTab(tabs[0]);
  }, []);

  useEffect(() => {
    if (
        !pouchSizesList?.length &&
        _controller?.current &&
        type?.type === "POUCH_PACKAGER"
    ) {
      async function getPouchSizesList() {
        await _controller.current
            .getStockLocationsConfigFile(user)
            .then((data) => {
              setPouchSizesList(data.stockLocations[2].pouchSizes);
            });
      }
      getPouchSizesList();
    }
  }, [_controller, type, device]);

  useEffect(() => {
    if (isCanisterType(type)) {
      setCanisterType(true);
    } else {
      setCanisterType(false);
    }
  }, [type]);

  useEffect(() => {
    if (selectedStockLocation) {
      setName(selectedStockLocation.obj.name);
      setNickname(selectedStockLocation.obj.nickname);
      setType(selectedStockLocation.obj.type);
      setBarcode(selectedStockLocation.obj.barcode);
      setIsControlled(selectedStockLocation.obj.isControlled);
      setOrderPriority(selectedStockLocation.obj.orderPriority);
      setActive(selectedStockLocation.obj.isEnabled);
      setDevice(selectedStockLocation.obj.device);
      setDevicePosition(selectedStockLocation.obj.devicePosition);
      setDeviceId(selectedStockLocation.obj.deviceId);
    }
  }, [selectedStockLocation]);

  useEffect(() => {
    if (type && type.canConnect && tabs.length === 1) {
      setTabs((prev) => {
        return [
          ...prev,
          { index: 1, text: t("tab1") },
          { index: 2, text: t("tab2") },
        ];
      });
    } else if (type && !type.canConnect && tabs.length > 1) {
      let temp = [...tabs];
      // temp.splice(1, 1);
      temp.splice(1);
      setTabs(temp);
    }
  }, [type]);

  useEffect(() => {
    ConfigService.stockLocations()
        .then((stockLocations) => {
          const updatedStockLocations = stockLocations.map((o) => ({
            ...o,
            displayName: t(o.description, { ns: "stockLocationTypes" })
          }));

          setStockLocationsList(updatedStockLocations);
        })
        .catch((error) => setError(error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      let validationErrors = validate({
        name: name,
        type: type,
      });

      setErrors(validationErrors);
    }
  };

  const saveData = useCallback(() => {
    async function doSaveData() {
      if (
          currentAction === ADD_STOCK_LOCATION ||
          currentAction === ADD_CHILD ||
          currentAction === EDIT_STOCK_LOCATION
      ) {
        let stockLocation = {
          isEnabled: active,
          isControlled: isControlled,
          site: site,
          parent: parent ? parent.obj : null,
          // children:
          //   currentAction === EDIT_STOCK_LOCATION
          //     ? selectedStockLocation.obj.children
          //     : [],
          productAssociations:
              currentAction === EDIT_STOCK_LOCATION
                  ? selectedStockLocation.obj.productAssociations
                  : [],
          name: name,
          nickname: nickname,
          barcode: barcode,
          type: type,
          device: device,
          orderPriority: orderPriority,
          deviceId: deviceId,
          devicePosition: devicePosition,
        };

        if (selectedStockLocation && selectedStockLocation.obj._id) {
          stockLocation._id = selectedStockLocation.obj._id;
        }

        const callback = (error, stockLocation) => {
          if (!error) {
            const currentNodeList = [...nodeList];

            if (currentAction === ADD_STOCK_LOCATION) {
              const newNode = new Node(stockLocation);
              newNode.obj.name = name;
              newNode.obj.nickname = nickname;
              newNode.obj.type = type;
              newNode.obj.barcode = barcode;
              newNode.obj.isControlled = isControlled;
              newNode.obj.orderPriority = orderPriority;
              newNode.obj.isEnabled = active;
              newNode.obj.device = device;
              newNode.obj.deviceId = deviceId;
              newNode.obj.devicePosition = devicePosition;

              currentNodeList.push(newNode);
              setNodeList(currentNodeList);
            } else if (currentAction === ADD_CHILD) {
              const updatedParent = parent;

              if (parent.isExpanded) {
                const child = new Node(stockLocation);
                child.parent = updatedParent;
                updatedParent.addChild(child);
              }

              if (updatedParent.obj.children) {
                updatedParent.obj.children.push(stockLocation.id);
              } else {
                updatedParent.obj.children = [stockLocation.id];
              }

              setNodeList(currentNodeList);

              // setStockLocations(tempArray);
            } else if (currentAction === EDIT_STOCK_LOCATION) {
              selectedStockLocation.obj = stockLocation;
              setNodeList(currentNodeList);
            }

            setIsSubmitting(false);
            setName("");
            setNickname("");
            onClose();
          } else {
            setIsSubmitting(false);
            setError(error);
          }
        };

        if (
            currentAction === ADD_STOCK_LOCATION ||
            currentAction === ADD_CHILD
        ) {
          if (type?.type === "POUCH_PACKAGER" && !device) {
            setDevice(initDevice("POUCH_PACKAGER"));
          } else if (type?.type === "RETURN_SYSTEM" && !device) {
            setDevice(initDevice("RETURN_SYSTEM"));
          } else if (type?.type === "VIAL_FILLER" && !device) {
            setDevice(initDevice("VIAL_FILLER"));
          } else {
            _controller.current.createStockLocation(stockLocation, callback);
            setTimeout(() => {
              setStockLocations([...stockLocations]);
            }, 300);
          }
        } else {
          _controller.current.updateStockLocation(stockLocation, callback);
        }
      }
    }
    doSaveData();
  }, [
    name,
    nickname,
    user,
    type,
    barcode,
    selectedStockLocation,
    site,
    isControlled,
    orderPriority,
    active,
    device,
    deviceId,
    devicePosition,
  ]);

  const initDevice = (type) => {
    switch (type) {
      case "POUCH_PACKAGER":
        return {
          settings: DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS,
        };
      case "RETURN_SYSTEM":
        return {
          settings: DEFAULT_RETURN_SYSTEM_DEVICE_SETTINGS,
        };
      case "VIAL_FILLER":
        return {
          settings: DEFAULT_VIAL_FILLER_DEVICE_SETTINGS,
          allowLotMixing: false,
          allowAutomaticDispensing: false,
        };
      default:
        return null;
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setError("");
      saveData();
    } else if (Object.keys(errors).length > 0) {
      setError(t("error3"));
      setIsSubmitting(false);
    }
  }, [saveData, errors, isSubmitting]);

  const handleOnClose = () => {
    onClose();
    setError("");
  };

  const handleSelectedTab = (index) => {
    setSelectedTab(tabs[index]);
  };

  const DesktopView = (
      <section className={styles.CreateStockLocations__view}>
        <section className={styles.CreateStockLocations__header}>
          <p className={styles.CreateStockLocations__title}>
            {currentAction === ADD_STOCK_LOCATION
                ? t("createTitle", {site: site.name})
                : currentAction === EDIT_STOCK_LOCATION
                    ? t("editTitle", {location: selectedStockLocation.obj.name})
                    : currentAction === ADD_CHILD
                        ? t("childTitle", {location: parent.obj.name})
                        : null}
          </p>
          <div className={styles.CreateStockLocations__closeIcon}>
            <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={handleOnClose}
            />
          </div>
        </section>
        <section className={styles.CreateStockLocations__body}>
          {error && (
              <>
                <ErrorBanner message={error} onClose={() => setError(null)} />
                <Spacer space={10} unit={"px"} />
              </>
          )}

          <TabBar
              tabs={tabs}
              onSelectedTab={handleSelectedTab}
              fontSize={"14px"}
          />
          {/* Add or Edit Stock Location, General Info tab*/}
          {selectedTab.index === 0 && (
              <div className={styles.CreateStockLocations__mainContainer}>
                <div className={styles.CreateStockLocations__column}>
                  <Checkbox
                      labelName={t("activeLabel")}
                      isOn={active}
                      onCheck={() => {
                        setActive(!active);
                      }}
                  />
                  <Spacer space={20} unit={"px"} />
                  <DropdownInput
                      value={type ? t(type.description, {ns: "stockLocationTypes"}) : ""}
                      labelName={t("typeLabel")}
                      validationText={t(errors.type, {ns: "validation"})}
                      isEnabled={true}
                      placeholder={""}
                      listElements={stockLocationsList}
                      onSelection={({ displayName, ...listItem}) => {
                        setType(listItem);
                        // setDevice(initDevice(listItem.type));
                      }}
                      maxWidth={"400px"}
                      display={"displayName"}
                  />
                  <Spacer space={20} unit={"px"} />
                  <TextInput
                      placeholder={""}
                      isEnabled={true}
                      validationText={t(errors.name, {ns: "validation"})}
                      labelName={t("nameLabel")}
                      value={name || ""}
                      onChange={(val) => setName(val)}
                      focus={true}
                      type={"text"}
                  />
                  <Spacer space={20} unit={"px"} />
                  <TextInput
                      placeholder={""}
                      isEnabled={true}
                      validationText={""}
                      labelName={t("nicknameLabel")}
                      value={nickname || ""}
                      onChange={(val) => setNickname(val)}
                      type={"text"}
                  />
                  <Spacer space={20} unit={"px"} />
                  <DropdownInput
                      value={orderPriority ? t(orderPriority, {ns: "siteOrderPriority"}) : t("Normal", {ns: "siteOrderPriority"})}
                      labelName={t("orderPriorityLabel")}
                      validationText={t(errors.orderPriority, {ns: "validation"})}
                      isEnabled={true}
                      placeholder={""}
                      listElements={[
                        { type: "High", description: t("High", {ns: "siteOrderPriority"}) },
                        { type: "Normal", description: t("Normal", {ns: "siteOrderPriority"}) },
                        { type: "Low", description: t("Low", {ns: "siteOrderPriority"}) },
                      ]}
                      onSelection={(listItem) => {
                        setOrderPriority(listItem.type);
                      }}
                      maxWidth={"400px"}
                      display={"description"}
                  />
                </div>
                <div className={styles.CreateStockLocations__column}>
                  <Spacer space={40} unit={"px"} />
                  {canisterType && (
                      <>
                        <TextInput
                            placeholder={""}
                            isEnabled={true}
                            validationText={""}
                            labelName={t("motorBaseLabel")}
                            value={devicePosition || ""}
                            onChange={(val) => setDevicePosition(val)}
                            focus={false}
                            type={"text"}
                        />
                        <Spacer space={20} unit={"px"} />
                      </>
                  )}
                  <TextInput
                      placeholder={""}
                      isEnabled={true}
                      validationText={""}
                      labelName={t("barcodeLabel")}
                      value={barcode || ""}
                      onChange={(val) => setBarcode(val)}
                      focus={false}
                      type={"text"}
                  />
                  <Spacer space={60} unit={"px"} />
                  <Checkbox
                      labelName={t("controlledLabel")}
                      onCheck={(isOn) => {
                        setIsControlled(isOn);
                      }}
                      isOn={isControlled}
                  />
                </div>
              </div>
          )}

          {/*DEVICE INFO*/}
          {selectedTab.index === 1 && type?.type === "VIAL_FILLER" && (
              <div>
                <br />
                <div className={styles.CreateStockLocations__row}>
                  <h1>{t("removeVialsPrefix")}</h1>
                  <input
                      type={"time"}
                      value={
                        device && device.settings
                            ? device.settings.vialRemovalTime
                            : DEFAULT_VIAL_FILLER_DEVICE_SETTINGS.vialRemovalTime
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            settings.vialRemovalTime = val;
                            settings.tz =
                                Intl.DateTimeFormat().resolvedOptions().timeZone;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings = {
                            vialRemovalTime: val,
                            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
                          };
                          setDevice(d);
                        }
                      }}
                  />
                  <h1>{t("removeVialsSuffix")}</h1>
                </div>
                <br />
                <Checkbox
                    labelName={t("lotMixingLabel")}
                    onCheck={(isOn) => {
                      if (device) {
                        let temp = { ...device };
                        temp.allowLotMixing = isOn;
                        setDevice(temp);
                      } else {
                        const d = {};
                        d.allowLotMixing = isOn;
                        setDevice(d);
                      }
                    }}
                    isOn={device && device.allowLotMixing}
                />
                <br />
                <Checkbox
                    labelName={t("autoDispenseLabel")}
                    onCheck={(isOn) => {
                      if (device) {
                        let temp = { ...device };
                        temp.allowAutomaticDispensing = isOn;
                        setDevice(temp);
                      } else {
                        const d = {};
                        d.allowAutomaticDispensing = isOn;
                        setDevice(d);
                      }
                    }}
                    isOn={device && device.allowAutomaticDispensing}
                />
              </div>
          )}
          {/* 1 */}
          {selectedTab.index === 1 && type?.type === "POUCH_PACKAGER" && (
              <div className={styles.CreateStockLocations__mainContainer}>
                <div className={styles.CreateStockLocations__column}>
                  <TextInput
                      isEnabled={true}
                      labelName={t("proudMachineLabel")}
                      value={deviceId || ""}
                      type={"text"}
                      onChange={(val) => {
                        setDeviceId(val);
                      }}
                  />

                  <DropdownInput
                      value={
                        device && device.settings
                            ? device.settings.pouchSize
                            : POUCH_PACKAGER_POUCH_SIZE
                      }
                      labelName={t("pouchSizeLabel")}
                      validationText={t(errors.pouchSize, {ns: "validation"})}
                      isEnabled={true}
                      placeholder={""}
                      listElements={pouchSizesList}
                      onSelection={(listItem) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                            settings.pouchSize = listItem.description;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings = DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                          d.settings.pouchSize = listItem.description;
                          setDevice(d);
                        }
                      }}
                      maxWidth={"400px"}
                  />

                  <TextInput
                      isEnabled={true}
                      labelName={t("deliveredLabel")}
                      value={
                        device && device.settings
                            ? device.settings.deliveryTransitionHours
                            : POUCH_PACKAGER_DELIVERY_TRANSITION_HOURS
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                            settings.deliveryTransitionHours = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings = DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                          d.settings.deliveryTransitionHours = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>

                {/* 2 */}
                <div className={styles.CreateStockLocations__column}>
                  <TextInput
                      isEnabled={true}
                      labelName={t("pouchBenchmarkHour")}
                      value={
                        device && device.settings
                            ? device.settings.pouchesPerHourBenchmark
                            : POUCH_PACKAGER_POUCHES_PER_HOUR_BENCHMARK
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                            settings.pouchesPerHourBenchmark = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings = DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                          d.settings.pouchesPerHourBenchmark = val;
                          setDevice(d);
                        }
                      }}
                  />

                  <TextInput
                      isEnabled={true}
                      labelName={t("batchBenchmarkSunday")}
                      value={
                        device && device.settings
                            ? device.settings.batchesPerSundayBenchmark
                            : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSundayBenchmark
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSundayBenchmark;
                            settings.batchesPerSundayBenchmark = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings =
                              DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSundayBenchmark;
                          d.settings.batchesPerSundayBenchmark = val;
                          setDevice(d);
                        }
                      }}
                  />

                  <TextInput
                      isEnabled={true}
                      labelName={t("batchBenchmarkMonday")}
                      value={
                        device && device.settings
                            ? device.settings.batchesPerMondayBenchmark
                            : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerMondayBenchmark
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerMondayBenchmark;
                            settings.batchesPerMondayBenchmark = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings =
                              DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerMondayBenchmark;
                          d.settings.batchesPerMondayBenchmark = val;
                          setDevice(d);
                        }
                      }}
                  />

                  <TextInput
                      isEnabled={true}
                      labelName={t("batchBenchmarkTuesday")}
                      value={
                        device && device.settings
                            ? device.settings.batchesPerTuesdayBenchmark
                            : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerTuesdayBenchmark
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerTuesdayBenchmark;
                            settings.batchesPerTuesdayBenchmark = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings =
                              DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerTuesdayBenchmark;
                          d.settings.batchesPerTuesdayBenchmark = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>

                {/* 3 */}
                <div className={styles.CreateStockLocations__column}>
                  <TextInput
                      isEnabled={true}
                      labelName={t("batchBenchmarkWednesday")}
                      value={
                        device && device.settings
                            ? device.settings.batchesPerWednesdayBenchmark
                            : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerWednesdayBenchmark
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerWednesdayBenchmark;
                            settings.batchesPerWednesdayBenchmark = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings =
                              DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerWednesdayBenchmark;
                          d.settings.batchesPerWednesdayBenchmark = val;
                          setDevice(d);
                        }
                      }}
                  />

                  <TextInput
                      isEnabled={true}
                      labelName={t("batchBenchmarkThursday")}
                      value={
                        device && device.settings
                            ? device.settings.batchesPerThursdayBenchmark
                            : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerThursdayBenchmark
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerThursdayBenchmark;
                            settings.batchesPerThursdayBenchmark = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings =
                              DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerThursdayBenchmark;
                          d.settings.batchesPerThursdayBenchmark = val;
                          setDevice(d);
                        }
                      }}
                  />

                  <TextInput
                      isEnabled={true}
                      labelName={t("batchBenchmarkFriday")}
                      value={
                        device && device.settings
                            ? device.settings.batchesPerFridayBenchmark
                            : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerFridayBenchmark
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerFridayBenchmark;
                            settings.batchesPerFridayBenchmark = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings =
                              DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerFridayBenchmark;
                          d.settings.batchesPerFridayBenchmark = val;
                          setDevice(d);
                        }
                      }}
                  />

                  <TextInput
                      isEnabled={true}
                      labelName={t("batchBenchmarkSaturday")}
                      value={
                        device && device.settings
                            ? device.settings.batchesPerSaturdayBenchmark
                            : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSaturdayBenchmark
                      }
                      type={"number"}
                      onChange={(val) => {
                        if (device) {
                          setDevice((prev) => {
                            const settings = device?.settings
                                ? device.settings
                                : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSaturdayBenchmark;
                            settings.batchesPerSaturdayBenchmark = val;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings =
                              DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSaturdayBenchmark;
                          d.settings.batchesPerSaturdayBenchmark = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>
              </div>
          )}
          {selectedTab.index === 1 && type?.type === "RETURN_SYSTEM" && (
              <div>
                <br />
                <div className={styles.CreateStockLocations__row}>
                  <div className={styles.CreateStockLocations__column}>
                    <TextInput
                        isEnabled={true}
                        labelName={t("suctionCupLabel")}
                        value={
                          device && device.settings
                              ? device.settings.suctionCupLifeDays
                              : DEFAULT_RETURN_SYSTEM_DEVICE_SETTINGS.suctionCupLifeDays
                        }
                        type={"number"}
                        onChange={(val) => {
                          const num = parseInt(val);
                          setDevice((prev) => {
                            const settings = device?.settings ? device.settings : {};
                            settings.suctionCupLifeDays = num;
                            if (!settings?.suctionCupStartDate) {
                              settings.suctionCupStartDate = new Date();
                            }
                            return { ...prev, settings };
                          });
                        }}
                    />
                  </div>
                  <div className={styles.CreateStockLocations__column}>
                    <DatePickerComponent
                        label={t("startLifeLabel")}
                        startDate={
                          device && device.settings
                              ? new Date(device.settings.suctionCupStartDate)
                              : new Date()
                        }
                        onChange={(date) => {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            settings.suctionCupStartDate = new Date(date);
                            return { ...prev, settings };
                          });
                        }}
                    />
                  </div>
                </div>
              </div>
          )}
          {selectedTab.index === 2 && type?.type === "VIAL_FILLER" && (
              <div>
                <div
                    className={`${styles.CreateStockLocations__row} ${styles.CreateStockLocations__vialLabelInputContainer}`}
                >
                  <h1>{t("barcodePrefixLabel")}</h1>
                  <input
                      className={styles.CreateStockLocations__vialLabelInputs}
                      type={"text"}
                      value={
                        device && device.settings && device.settings.barcodeFormat
                            ? device.settings.barcodeFormat.prefix
                            : ""
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            const barcodeFormat = settings.barcodeFormat
                                ? settings.barcodeFormat
                                : {};
                            barcodeFormat.prefix = val;
                            settings.barcodeFormat = barcodeFormat;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings.barcodeFormat.prefix = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>
                <div
                    className={`${styles.CreateStockLocations__row} ${styles.CreateStockLocations__vialLabelInputContainer}`}
                >
                  <h1>{t("barcodeSuffixLabel")}</h1>
                  <input
                      className={styles.CreateStockLocations__vialLabelInputs}
                      type={"text"}
                      value={
                        device && device.settings && device.settings.barcodeFormat
                            ? device.settings.barcodeFormat.suffix
                            : ""
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            const barcodeFormat = settings.barcodeFormat
                                ? settings.barcodeFormat
                                : {};
                            barcodeFormat.suffix = val;
                            settings.barcodeFormat = barcodeFormat;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings.barcodeFormat.suffix = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>
                <div
                    className={`${styles.CreateStockLocations__row} ${styles.CreateStockLocations__vialLabelInputContainer}`}
                >
                  <h1>{t("fieldDelimiterLabel")}</h1>
                  <input
                      className={styles.CreateStockLocations__vialLabelInputs}
                      type={"text"}
                      value={
                        device && device.settings && device.settings.barcodeFormat
                            ? device.settings.barcodeFormat.delimiter
                            : ""
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            const barcodeFormat = settings.barcodeFormat
                                ? settings.barcodeFormat
                                : {};
                            barcodeFormat.delimiter = val;
                            settings.barcodeFormat = barcodeFormat;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings.barcodeFormat.delimiter = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>

                <div
                    className={`${styles.CreateStockLocations__row} ${styles.CreateStockLocations__vialLabelInputContainer}`}
                >
                  <h1>{t("rxPositionLabel")}</h1>
                  <input
                      className={styles.CreateStockLocations__vialLabelInputs}
                      type={"number"}
                      value={
                        device && device.settings && device.settings.barcodeFormat
                            ? device.settings.barcodeFormat.rxNumberPosition
                            : ""
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            const barcodeFormat = settings.barcodeFormat
                                ? settings.barcodeFormat
                                : {};
                            barcodeFormat.rxNumberPosition = val;
                            settings.barcodeFormat = barcodeFormat;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings.barcodeFormat.rxNumberPosition = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>
              </div>
          )}
        </section>
        <section className={styles.CreateStockLocations__footer}>
          <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={handleOnClose}
          />
          <Spacer space={50} unit={"px"} />
          <Button
              labelName={
                currentAction === ADD_STOCK_LOCATION || currentAction === ADD_CHILD
                    ? t("addButtonLabel")
                    : currentAction === EDIT_STOCK_LOCATION
                        ? t("updateButtonLabel")
                        : t("updateButtonLabel")
              }
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
          />
        </section>
      </section>
  );

  const addNewStockLocationTitle = () => {
    return (
        <h2 className={styles.CreateStockLocationMobile__title}>
          {t("createTitlePrefix")}
          <span className={styles.CreateStockLocationMobile__lineBreak}>
        {t("createTitleSuffix", {site: site.name})}
        </span>
        </h2>
    );
  };

  const editNewStockLocationTitle = (stockLocation) => {
    return (
        <h2 className={styles.CreateStockLocationMobile__title}>
          {t("editTitlePrefix")}
          <span className={styles.CreateStockLocationMobile__lineBreak}>
          {stockLocation.obj.name}
        </span>
        </h2>
    );
  };

  const addChildStockLocationTitle = (parent) => {
    return (
        <h2 className={styles.CreateStockLocationMobile__title}>
          {t("childTitlePrefix")}
          <span className={styles.CreateStockLocationMobile__lineBreak}>
          {t("childTitleSuffix", {location: parent.obj.name})}
        </span>
        </h2>
    );
  };

  const MobileView = (
      <Fragment>
        <section className={styles.CreateStockLocationMobile__header}>
          <div
              className={styles.CreateStockLocationMobile__backArrow}
              onClick={handleOnClose}
          >
            <BackArrowMobile
                className={styles.CreateStockLocationMobile__backArrowIcon}
            />
            <p className={styles.CreateStockLocationMobile__goBackTitle}>{t("backButtonLabel")}</p>
          </div>
          {currentAction === ADD_STOCK_LOCATION
              ? addNewStockLocationTitle()
              : currentAction === EDIT_STOCK_LOCATION
                  ? editNewStockLocationTitle(selectedStockLocation)
                  : currentAction === ADD_CHILD
                      ? addChildStockLocationTitle(parent)
                      : null}
        </section>
        <section className={styles.CreateStockLocationMobile__body}>
          {error && (
              <>
                <ErrorBanner message={error} onClose={() => setError(null)} />
                <Spacer space={10} unit={"px"} />
              </>
          )}

          <TabBar
              tabs={tabs}
              onSelectedTab={handleSelectedTab}
              fontSize={"14px"}
          />
          {selectedTab.index === 0 && (
              <div className={styles.CreateStockLocationMobile__mainContainer}>
                <div className={styles.CreateStockLocationMobile__checkboxes}>
                  <div
                      className={styles.CreateStockLocationMobile__checkboxContainer}
                  >
                    <Checkbox
                        labelName={t("activeLabel")}
                        isOn={active}
                        onCheck={() => {
                          setActive(!active);
                        }}
                    />
                  </div>
                  <div
                      className={styles.CreateStockLocationMobile__checkboxContainer}
                  >
                    <Checkbox
                        labelName={t("controlledLabel")}
                        onCheck={(isOn) => {
                          setIsControlled(isOn);
                        }}
                        isOn={isControlled}
                    />
                  </div>
                </div>
                <Spacer space={10} unit={"px"} />
                <DropdownInput
                    value={type ? t(type.description, {ns: "stockLocationTypes"}) : ""}
                    labelName={t("typeLabel")}
                    validationText={t(errors.type, {ns: "validation"})}
                    isEnabled={true}
                    placeholder={""}
                    listElements={stockLocationsList}
                    onSelection={(listItem) => {
                      const { displayName, ...originalItem } = listItem;
                      setType(originalItem);
                      // setDevice(initDevice(listItem.type));
                    }}
                    maxWidth={"400px"}
                    display={"displayName"}
                />
                <TextInput
                    placeholder={""}
                    isEnabled={true}
                    validationText={t(errors.name, {ns: "validation"})}
                    labelName={t("nameLabel")}
                    value={name || ""}
                    onChange={(val) => setName(val)}
                    focus={true}
                    type={"text"}
                />
                <TextInput
                    placeholder={""}
                    isEnabled={true}
                    validationText={""}
                    labelName={t("nicknameLabel")}
                    value={nickname || ""}
                    onChange={(val) => setNickname(val)}
                    type={"text"}
                />
                <DropdownInput
                    value={orderPriority ? t(orderPriority, {ns: "siteOrderPriority"}) : t("Normal", {ns: "siteOrderPriority"})}
                    labelName={t("orderPriorityLabel")}
                    validationText={t(errors.orderPriority, {ns: "validation"})}
                    isEnabled={true}
                    placeholder={""}
                    listElements={[
                      { type: "High", description: t("High", {ns: "siteOrderPriority"}) },
                      { type: "Normal", description: t("Normal", {ns: "siteOrderPriority"}) },
                      { type: "Low", description: t("Low", {ns: "siteOrderPriority"}) },
                    ]}
                    onSelection={(listItem) => {
                      setOrderPriority(listItem);
                    }}
                    maxWidth={"400px"}
                    display={"description"}
                />
                {canisterType && (
                    <>
                      <TextInput
                          placeholder={""}
                          isEnabled={true}
                          validationText={""}
                          labelName={t("motorBaseLabel")}
                          value={devicePosition || ""}
                          onChange={(val) => setDevicePosition(val)}
                          focus={false}
                          type={"text"}
                      />
                    </>
                )}
                <TextInput
                    placeholder={""}
                    isEnabled={true}
                    validationText={""}
                    labelName={t("barcodeLabel")}
                    value={barcode || ""}
                    onChange={(val) => setBarcode(val)}
                    focus={false}
                    type={"text"}
                />
                {/* <Spacer space={30} unit={"px"} /> */}
              </div>
          )}
          {/*DEVICE INFO*/}
          {selectedTab.index === 1 && type?.type === "VIAL_FILLER" && (
              <div
                  className={
                    styles.CreateStockLocationMobile__deviceSettingsContainer
                  }
              >
                <div className={styles.CreateStockLocations__row}>
                  <h1>
                    {t("removeVialsMobile")}
                    <span>
                  <input
                      type={"time"}
                      value={
                        device && device.settings
                            ? device.settings.vialRemovalTime
                            : DEFAULT_VIAL_FILLER_DEVICE_SETTINGS.vialRemovalTime
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings
                                ? device.settings
                                : {};
                            settings.vialRemovalTime = val;
                            settings.tz =
                                Intl.DateTimeFormat().resolvedOptions().timeZone;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings = {
                            vialRemovalTime: val,
                            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
                          };
                          setDevice(d);
                        }
                      }}
                  />
                </span>
                  </h1>
                </div>
                <br />
                <Checkbox
                    labelName={t("lotMixingLabel")}
                    onCheck={(isOn) => {
                      if (device) {
                        let temp = { ...device };
                        temp.allowLotMixing = isOn;
                        setDevice(temp);
                      } else {
                        const d = {};
                        d.allowLotMixing = isOn;
                        setDevice(d);
                      }
                    }}
                    isOn={device && device.allowLotMixing}
                />
                <br />
                <Checkbox
                    labelName={t("autoDispenseLabel")}
                    onCheck={(isOn) => {
                      if (device) {
                        let temp = { ...device };
                        temp.allowAutomaticDispensing = isOn;
                        setDevice(temp);
                      } else {
                        const d = {};
                        d.allowAutomaticDispensing = isOn;
                        setDevice(d);
                      }
                    }}
                    isOn={device && device.allowAutomaticDispensing}
                />
              </div>
          )}
          {selectedTab.index === 1 && type?.type === "POUCH_PACKAGER" && (
              <div className={styles.CreateStockLocationMobile__mainContainer}>
                <TextInput
                    isEnabled={true}
                    labelName={t("proudMachineLabel")}
                    value={deviceId || ""}
                    type={"text"}
                    onChange={(val) => {
                      setDeviceId(val);
                    }}
                />

                <TextInput
                    isEnabled={true}
                    labelName={t("deliveredLabel")}
                    value={
                      device && device.settings
                          ? device.settings.deliveryTransitionHours
                          : POUCH_PACKAGER_DELIVERY_TRANSITION_HOURS
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                          settings.deliveryTransitionHours = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings = DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                        d.settings.deliveryTransitionHours = val;
                        setDevice(d);
                      }
                    }}
                />

                <DropdownInput
                    value={
                      device && device.settings
                          ? device.settings.pouchSize
                          : POUCH_PACKAGER_POUCH_SIZE
                    }
                    labelName={t("pouchSizeLabel")}
                    validationText={t(errors.pouchSize, {ns: "validation"})}
                    isEnabled={true}
                    placeholder={""}
                    listElements={pouchSizesList}
                    onSelection={(listItem) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                          settings.pouchSize = listItem.description;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings = DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                        d.settings.pouchSize = listItem.description;
                        setDevice(d);
                      }
                    }}
                    maxWidth={"400px"}
                />

                <TextInput
                    isEnabled={true}
                    labelName={t("pouchBenchmarkHour")}
                    value={
                      device && device.settings
                          ? device.settings.pouchesPerHourBenchmark
                          : POUCH_PACKAGER_POUCHES_PER_HOUR_BENCHMARK
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                          settings.pouchesPerHourBenchmark = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings = DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS;
                        d.settings.pouchesPerHourBenchmark = val;
                        setDevice(d);
                      }
                    }}
                />
                <TextInput
                    isEnabled={true}
                    labelName={t("batchBenchmarkSunday")}
                    value={
                      device && device.settings
                          ? device.settings.batchesPerSundayBenchmark
                          : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSundayBenchmark
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSundayBenchmark;
                          settings.batchesPerSundayBenchmark = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings =
                            DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSundayBenchmark;
                        d.settings.batchesPerSundayBenchmark = val;
                        setDevice(d);
                      }
                    }}
                />

                <TextInput
                    isEnabled={true}
                    labelName={t("batchBenchmarkMonday")}
                    value={
                      device && device.settings
                          ? device.settings.batchesPerMondayBenchmark
                          : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerMondayBenchmark
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerMondayBenchmark;
                          settings.batchesPerMondayBenchmark = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings =
                            DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerMondayBenchmark;
                        d.settings.batchesPerMondayBenchmark = val;
                        setDevice(d);
                      }
                    }}
                />

                <TextInput
                    isEnabled={true}
                    labelName={t("batchBenchmarkTuesday")}
                    value={
                      device && device.settings
                          ? device.settings.batchesPerTuesdayBenchmark
                          : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerTuesdayBenchmark
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerTuesdayBenchmark;
                          settings.batchesPerTuesdayBenchmark = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings =
                            DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerTuesdayBenchmark;
                        d.settings.batchesPerTuesdayBenchmark = val;
                        setDevice(d);
                      }
                    }}
                />

                <TextInput
                    isEnabled={true}
                    labelName={t("batchBenchmarkWednesday")}
                    value={
                      device && device.settings
                          ? device.settings.batchesPerWednesdayBenchmark
                          : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerWednesdayBenchmark
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerWednesdayBenchmark;
                          settings.batchesPerWednesdayBenchmark = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings =
                            DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerWednesdayBenchmark;
                        d.settings.batchesPerWednesdayBenchmark = val;
                        setDevice(d);
                      }
                    }}
                />

                <TextInput
                    isEnabled={true}
                    labelName={t("batchBenchmarkThursday")}
                    value={
                      device && device.settings
                          ? device.settings.batchesPerThursdayBenchmark
                          : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerThursdayBenchmark
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerThursdayBenchmark;
                          settings.batchesPerThursdayBenchmark = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings =
                            DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerThursdayBenchmark;
                        d.settings.batchesPerThursdayBenchmark = val;
                        setDevice(d);
                      }
                    }}
                />

                <TextInput
                    isEnabled={true}
                    labelName={t("batchBenchmarkFriday")}
                    value={
                      device && device.settings
                          ? device.settings.batchesPerFridayBenchmark
                          : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerFridayBenchmark
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerFridayBenchmark;
                          settings.batchesPerFridayBenchmark = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings =
                            DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerFridayBenchmark;
                        d.settings.batchesPerFridayBenchmark = val;
                        setDevice(d);
                      }
                    }}
                />

                <TextInput
                    isEnabled={true}
                    labelName={t("batchBenchmarkSaturday")}
                    value={
                      device && device.settings
                          ? device.settings.batchesPerSaturdayBenchmark
                          : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSaturdayBenchmark
                    }
                    type={"number"}
                    onChange={(val) => {
                      if (device) {
                        setDevice((prev) => {
                          const settings = device?.settings
                              ? device.settings
                              : DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSaturdayBenchmark;
                          settings.batchesPerSaturdayBenchmark = val;
                          return { ...prev, settings };
                        });
                      } else {
                        const d = {};
                        d.settings =
                            DEFAULT_POUCH_PACKAGER_DEVICE_SETTINGS.batchesPerSaturdayBenchmark;
                        d.settings.batchesPerSaturdayBenchmark = val;
                        setDevice(d);
                      }
                    }}
                />
              </div>
          )}
          {selectedTab.index === 1 && type?.type === "RETURN_SYSTEM" && (
              <div className={styles.CreateStockLocationMobile__returnSystemGroup}>
                <TextInput
                    isEnabled={true}
                    labelName={t("suctionCupLabel")}
                    value={
                      device && device.settings
                          ? device.settings.suctionCupLifeDays
                          : DEFAULT_RETURN_SYSTEM_DEVICE_SETTINGS.suctionCupLifeDays
                    }
                    type={"number"}
                    onChange={(val) => {
                      const num = parseInt(val);
                      setDevice((prev) => {
                        const settings = device?.settings ? device.settings : {};
                        settings.suctionCupLifeDays = num;
                        if (!settings?.suctionCupStartDate) {
                          settings.suctionCupStartDate = new Date();
                        }
                        return { ...prev, settings };
                      });
                    }}
                />
                <DatePickerComponent
                    label={t("startLifeLabel")}
                    startDate={
                      device && device.settings
                          ? new Date(device.settings.suctionCupStartDate)
                          : new Date()
                    }
                    onChange={(date) => {
                      setDevice((prev) => {
                        const settings = device.settings ? device.settings : {};
                        settings.suctionCupStartDate = new Date(date);
                        return { ...prev, settings };
                      });
                    }}
                />
              </div>
          )}
          {selectedTab.index === 2 && type?.type === "VIAL_FILLER" && (
              <div>
                <div
                    className={`${styles.CreateStockLocations__row} ${styles.CreateStockLocationsMobile__vialLabelInputContainer}`}
                >
                  <h1>{t("barcodePrefixLabel")}</h1>
                  <input
                      className={styles.CreateStockLocationsMobile__vialLabelInputs}
                      type={"text"}
                      value={
                        device && device.settings && device.settings.barcodeFormat
                            ? device.settings.barcodeFormat.prefix
                            : ""
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            const barcodeFormat = settings.barcodeFormat
                                ? settings.barcodeFormat
                                : {};
                            barcodeFormat.prefix = val;
                            settings.barcodeFormat = barcodeFormat;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings.barcodeFormat.prefix = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>
                <div
                    className={`${styles.CreateStockLocations__row} ${styles.CreateStockLocationsMobile__vialLabelInputContainer}`}
                >
                  <h1>{t("barcodeSuffixLabel")}</h1>
                  <input
                      className={styles.CreateStockLocationsMobile__vialLabelInputs}
                      type={"text"}
                      value={
                        device && device.settings && device.settings.barcodeFormat
                            ? device.settings.barcodeFormat.suffix
                            : ""
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            const barcodeFormat = settings.barcodeFormat
                                ? settings.barcodeFormat
                                : {};
                            barcodeFormat.suffix = val;
                            settings.barcodeFormat = barcodeFormat;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings.barcodeFormat.suffix = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>
                <div
                    className={`${styles.CreateStockLocations__row} ${styles.CreateStockLocationsMobile__vialLabelInputContainer}`}
                >
                  <h1>{t("fieldDelimiterLabel")}</h1>
                  <input
                      className={styles.CreateStockLocationsMobile__vialLabelInputs}
                      type={"text"}
                      value={
                        device && device.settings && device.settings.barcodeFormat
                            ? device.settings.barcodeFormat.delimiter
                            : ""
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            const barcodeFormat = settings.barcodeFormat
                                ? settings.barcodeFormat
                                : {};
                            barcodeFormat.delimiter = val;
                            settings.barcodeFormat = barcodeFormat;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings.barcodeFormat.delimiter = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>

                <div
                    className={`${styles.CreateStockLocations__row} ${styles.CreateStockLocationsMobile__vialLabelInputContainer}`}
                >
                  <h1>{t("rxPositionLabel")}</h1>
                  <input
                      className={styles.CreateStockLocationsMobile__vialLabelInputs}
                      type={"number"}
                      value={
                        device && device.settings && device.settings.barcodeFormat
                            ? device.settings.barcodeFormat.rxNumberPosition
                            : ""
                      }
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        if (device) {
                          setDevice((prev) => {
                            const settings = device.settings ? device.settings : {};
                            const barcodeFormat = settings.barcodeFormat
                                ? settings.barcodeFormat
                                : {};
                            barcodeFormat.rxNumberPosition = val;
                            settings.barcodeFormat = barcodeFormat;
                            return { ...prev, settings };
                          });
                        } else {
                          const d = {};
                          d.settings.barcodeFormat.rxNumberPosition = val;
                          setDevice(d);
                        }
                      }}
                  />
                </div>
              </div>
          )}
        </section>
        <section className={styles.CreateStockLocationMobile__footer}>
          <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={handleOnClose}
          />
          <Spacer space={20} unit={"px"} />
          <Button
              labelName={
                currentAction === ADD_STOCK_LOCATION || currentAction === ADD_CHILD
                    ? t("addButtonLabel")
                    : currentAction === EDIT_STOCK_LOCATION
                        ? t("updateButtonLabel")
                        : t("updateButtonLabel")
              }
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
          />
        </section>
      </Fragment>
  );

  return smScreen ? MobileView : DesktopView;
};

export default CreateStockLocation;
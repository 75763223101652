import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
} from "victory";

import { useTranslation } from "react-i18next";
import { ReactComponent as Square } from "../../../../assets/square.svg";
import styles from "./PouchesPerHourGraph.module.css";

const PouchesPerHourGraph = ({
  user,
  fetchingData,
  graphData,
  benchmarkValue,
  graphTitle,
  palette,
  timeRange,
  onTimeRange,
  primarySeriesLabel,
  secondarySeriesLabel,
}) => {
  // For Testing
  // const graphData = [
  //   { x: "12 AM", y: 700 },
  //   { x: "1 AM", y: 744 },
  //   { x: "2 AM", y: 695 },
  //   { x: "3 AM", y: 704 },
  //   { x: "4 AM", y: 1251 },
  //   { x: "5 AM", y: 2000 },
  //   { x: "6 AM", y: 1000 },
  //   { x: "7 AM", y: 900 },
  //   { x: "8 AM", y: 940 },
  //   { x: "9 AM", y: 3021 },
  //   { x: "10 AM", y: 1074 },
  //   { x: "11 AM", y: 3191 },
  //   { x: "12 PM", y: 2621 },
  //   { x: "1 PM", y: 107 },
  //   { x: "2 PM", y: 1586 },
  //   { x: "3 PM", y: 2781 },
  //   { x: "4 PM", y: 884 },
  //   { x: "5 PM", y: 605 },
  //   { x: "6 PM", y: 403 },
  //   { x: "7 PM", y: 201 },
  //   { x: "8 PM", y: 107 },
  //   { x: "9 PM", y: 66 },
  //   { x: "10 PM", y: 34 },
  //   { x: "11 PM", y: 21 },
  // ];

  // const benchmarkValue = 1900;

  const { t } = useTranslation(["trupakDashboard", "graphsGeneral"]);
  const style = getStyle(palette);
  const dataSetTwo = getBenchmarkDataSet(benchmarkValue);
  const tickValues = getTickValues();

  const Legend = (
    <div className={styles.PouchesPerHourGraph__legendContainer}>
      <div className={styles.PouchesPerHourGraph__legendGroup}>
        <div className={styles.PouchesPerHourGraph__mainDataLine}></div>
        <p>{primarySeriesLabel}</p>
      </div>
      <div className={styles.PouchesPerHourGraph__legendGroup}>
        <div className={styles.PouchesPerHourGraph__benchmarkLine}></div>
        <p>{secondarySeriesLabel}</p>
      </div>
    </div>
  );

  let mainData = [];

  const ticks = 5;

  // when ticks = 5, returns [0, 1, 2, 3, 4, 5]
  // or, using the npm package lodash, the same as const tickValues = _.range(6);
  const tickValuesForYAxis = Array.from({ length: ticks + 1 }, (_, i) => i);

  function getMaxValueInDataSet(dataSet) {
    let maxValue = -1;
    dataSet.forEach((num) => {
      if (num > maxValue) {
        maxValue = num;
      }
    });
    return Math.ceil(maxValue / ticks) * ticks;
  }

  const maxYValueInMainData = getMaxValueInDataSet(
    graphData.map((dataObj) => dataObj.y)
  );

  const maxYValue =
    benchmarkValue >= maxYValueInMainData
      ? benchmarkValue
      : maxYValueInMainData;

  const yRange = [0, maxYValue];

  const domain = { y: [0, ticks] };

  const tickFormatBar = (range) => (t) =>
    ((t * (range[1] - range[0])) / ticks).toFixed(1);

  const normalize = (range, props) => (datum) =>
    datum[props] / ((range[1] - range[0]) / ticks);

  graphData.forEach((dataObj) => {
    mainData.push({
      x: dataObj.x,
      y: dataObj.y,
    });
  });

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  let xValueNoGraphDataFound = 176;
  let yValueNoGraphDataFound = 133;

  if (user?.language?.type === "es") {
    xValueNoGraphDataFound = 138;
  } else if (user?.language?.type === "fr") {
    xValueNoGraphDataFound = 140;
  }

  const Chart = (
    <section className={styles.PouchesPerHourGraph__container}>
      <h2>{graphTitle}</h2>
      <VictoryChart
        domain={domain}
        domainPadding={{ x: 0, y: 0 }}
        theme={VictoryTheme.material}
        width={450}
        height={268}
      >
        {!fetchingData && !graphData.length && (
          <VictoryLabel
            x={xValueNoGraphDataFound}
            y={yValueNoGraphDataFound}
            style={{
              fontFamily: "inherit",
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: "normal",
              fill: "	#909090",
            }}
            text={fetchingData ? "" : t("graphsGeneral:noData")}
          />
        )}

        {/* shared independent axis (x-axis) */}
        <VictoryAxis
          scale="linear"
          standalone={false}
          style={style.axisYears}
          tickValues={tickValues}
          tickFormat={(x, index) => {
            if (index % 3 === 0) {
              return tickValues[index];
            } else {
              return "";
            }
          }}
        />
        {/* y-axis */}
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          offsetX={50}
          orientation="left"
          standalone={false}
          tickValues={tickValuesForYAxis}
          tickFormat={tickFormatBar(yRange)}
          style={{
            axis: { stroke: "#909090", strokeWidth: 1 },
            ticks: { stroke: "#909090", strokeWidth: 1 },
            tickLabels: {
              fill:
                !fetchingData && graphData.length ? "#909090" : "transparent",
              fontSize: 11,
              width: 40,
            },
            grid: {
              stroke: "#CFD8DC",
              strokeDasharray: "0, 0",
            },
          }}
        />
        {/* dataset one */}
        <VictoryLine
          data={mainData}
          y={normalize(yRange, "y")}
          scale={{ x: "linear", y: "linear" }}
          standalone={false}
          style={style.lineOne}
          labelComponent={<VictoryLabel dy={-6} />}
        />

        {/* dataset two */}
        {!fetchingData && graphData.length && (
          <VictoryLine
            data={dataSetTwo}
            y={normalize(yRange, "y")}
            scale={{ x: "linear", y: "linear" }}
            standalone={false}
            style={style.lineTwo}
          />
        )}
      </VictoryChart>
      {Legend}
    </section>
  );

  const TimeRangeSelection = (
    <div
      className={styles.PouchesPerHourGraph__timeRangeContainer}
      onClick={handleStopPropagation}
    >
      <div
        className={styles.PouchesPerHourGraph__timeRangeOption}
        onClick={() => onTimeRange("month")}
      >
        <div
          className={
            timeRange === "month"
              ? `${styles.PouchesPerHourGraph__squareContainer} ${styles["PouchesPerHourGraph__squareContainer--active"]}`
              : styles.PouchesPerHourGraph__squareContainer
          }
        >
          <Square />
        </div>
        <p>{t("trupakDashboard:lastMonthLabel")}</p>
      </div>
      <div
        className={styles.PouchesPerHourGraph__timeRangeOption}
        onClick={() => onTimeRange("week")}
      >
        <div
          className={
            timeRange === "week"
              ? `${styles.PouchesPerHourGraph__squareContainer} ${styles["PouchesPerHourGraph__squareContainer--active"]}`
              : styles.PouchesPerHourGraph__squareContainer
          }
        >
          <Square />
        </div>
        <p>{t("trupakDashboard:lastWeekLabel")}</p>
      </div>
      <div
        className={styles.PouchesPerHourGraph__timeRangeOption}
        onClick={() => onTimeRange("day")}
      >
        <div
          className={
            timeRange === "day"
              ? `${styles.PouchesPerHourGraph__squareContainer} ${styles["PouchesPerHourGraph__squareContainer--active"]}`
              : styles.PouchesPerHourGraph__squareContainer
          }
        >
          <Square />
        </div>
        <p>{t("trupakDashboard:lastDayLabel")}</p>
      </div>
      <div
        className={styles.PouchesPerHourGraph__timeRangeOption}
        onClick={() => onTimeRange("12 hours")}
      >
        <div
          className={
            timeRange === "12 hours"
              ? `${styles.PouchesPerHourGraph__squareContainer} ${styles["PouchesPerHourGraph__squareContainer--active"]}`
              : styles.PouchesPerHourGraph__squareContainer
          }
        >
          <Square />
        </div>
        <p>{t("trupakDashboard:last12HoursLabel")}</p>
      </div>
    </div>
  );

  return (
    <div>
      {Chart}
      {TimeRangeSelection}
    </div>
  );
};

function getBenchmarkDataSet(benchmarkValue) {
  return [
    {
      x: "12 AM",
      y: benchmarkValue,
    },
    {
      x: "1 AM",
      y: benchmarkValue,
    },
    {
      x: "2 AM",
      y: benchmarkValue,
    },
    {
      x: "3 AM",
      y: benchmarkValue,
    },
    {
      x: "4 AM",
      y: benchmarkValue,
    },
    {
      x: "5 AM",
      y: benchmarkValue,
    },
    {
      x: "6 AM",
      y: benchmarkValue,
    },
    {
      x: "7 AM",
      y: benchmarkValue,
    },
    {
      x: "8 AM",
      y: benchmarkValue,
    },
    {
      x: "9 AM",
      y: benchmarkValue,
    },
    {
      x: "10 AM",
      y: benchmarkValue,
    },
    {
      x: "11 AM",
      y: benchmarkValue,
    },
    {
      x: "12 PM",
      y: benchmarkValue,
    },
    {
      x: "1 PM",
      y: benchmarkValue,
    },
    {
      x: "2 PM",
      y: benchmarkValue,
    },
    {
      x: "3 PM",
      y: benchmarkValue,
    },
    {
      x: "4 PM",
      y: benchmarkValue,
    },
    {
      x: "5 PM",
      y: benchmarkValue,
    },
    {
      x: "6 PM",
      y: benchmarkValue,
    },
    {
      x: "7 PM",
      y: benchmarkValue,
    },
    {
      x: "8 PM",
      y: benchmarkValue,
    },
    {
      x: "9 PM",
      y: benchmarkValue,
    },
    {
      x: "10 PM",
      y: benchmarkValue,
    },
    {
      x: "11 PM",
      y: benchmarkValue,
    },
  ];
}

function getMaxYValueInMainData(dataSet) {
  let maxValue = -1;
  dataSet.forEach((set) => {
    if (set.y > maxValue) {
      maxValue = set.y;
    }
  });
  return maxValue;
}

function getTickValues() {
  return [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
  ];
}

function getStyle(palette) {
  const COLOR_1 = palette[0];
  const COLOR_2 = palette[1];

  return {
    parent: {
      fontFamily: "inherit",
      background: "transparent",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      width: "450px",
      height: "278px",
    },
    title: {
      fontFamily: "inherit",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: "normal",
    },
    labelNumber: {
      textAnchor: "middle",
      fill: "#ffffff",
      fontFamily: "inherit",
      fontSize: "14px",
    },

    // x axis
    axisYears: {
      axis: { stroke: "black", strokeWidth: 1 },
      ticks: {
        size: ({ tick }) => {
          if (
            [
              "12 AM",
              "3 AM",
              "6 AM",
              "9 AM",
              "12 PM",
              "3 PM",
              "6 PM",
              "9 PM",
            ].includes(tick)
          ) {
            return 6;
          } else if (
            [
              "1 AM",
              "2 AM",
              "4 AM",
              "5 AM",
              "7 AM",
              "8 AM",
              "10 AM",
              "11 AM",
              "1 PM",
              "2 PM",
              "4 PM",
              "5 PM",
              "7 PM",
              "8 PM",
              "10 PM",
              "11 PM",
            ].includes(tick)
          ) {
            return 3;
          } else {
            return 0;
          }
        },
        stroke: "#CFD8DC",
        strokeWidth: 2,
      },
      grid: {
        stroke: "transparent",
      },
      tickLabels: { fill: "#455A64", fontSize: 11 },
    },

    // mainData line
    axisOne: {
      axis: { stroke: "transparent", strokeWidth: 0 },
      ticks: {
        stroke: "transparent",
      },
      grid: {
        stroke: "#CFD8DC",
        strokeDasharray: "0, 0",
      },
      tickLabels: {
        fill: "#455A64",
      },
    },
    labelOne: {
      fill: COLOR_1,
      fontFamily: "#909090",
      fontSize: 12,
      fontStyle: "italic",
    },
    lineOne: {
      data: { stroke: COLOR_1, strokeWidth: 3 },
    },
    axisOneCustomLabel: {
      fill: COLOR_1,
      fontFamily: "inherit",
      fontWeight: 300,
      fontSize: 21,
    },

    victoryScatterOne: {
      data: {
        fill: COLOR_1,
        stroke: "black",
        strokeOpacity: 0.5,
        strokeWidth: 1,
      },
    },

    // benchmark Data line
    lineTwo: {
      data: { stroke: COLOR_2, strokeWidth: 2 },
    },
    victoryScatterTwo: {
      data: {
        fill: COLOR_2,
        stroke: "black",
        strokeOpacity: 0.5,
        strokeWidth: 2,
      },
    },
  };
}

export default PouchesPerHourGraph;

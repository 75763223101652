export const TruPakHeaders = [
  "lastName",
  "firstName",
  "patientId",
  "packageId",
  "rxNum",
  "qty",
  "date",
  "time",
  "facility",
  "location",
  "room",
  "bed",
  "instructions",
  "physician",
  "pharmacist",
  "delete",
];

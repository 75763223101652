import React, { useRef, useState, useEffect } from "react";
import { Tooltip } from "antd";
import AntSelect from "../../../components/Ant/AntSelect/AntSelect";
import FacilityService from "../../../services/FacilityService";
import { ReactComponent as CreateEmptyRowIcon } from "../../../assets/add-circle-outline.svg";
import { ReactComponent as CreateAdjacentFilledRowIcon } from "../../../assets/copy.svg";
import { ReactComponent as TrashIcon } from "../../../assets/trash-icon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/search-icon-from-ant.svg";
import styles from "./TableContent.module.css";

const TableContent = ({
  user,
  orderData,
  errorData,
  headers,
  onFieldValueChange,
  onFieldClick,
  onKeyDown,
  activeField,
  onDeleteRow,
  onCreateAdjacentEmptyRow,
  onCreateAdjacentFilledRow,
  columnWidths,
  inputTypes,
  multiFacilitySelectionViolation,
  onProductSelectionObject,
}) => {
  const [cursor, setCursor] = useState(null);
  const [error, setError] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current;
    if (input && input.type !== "number")
      input.setSelectionRange(cursor, cursor);
  }, [inputRef, cursor, errorData]);

  useEffect(() => {
    FacilityService.list(user, true)
      .then((facilities) => {
        facilities.filter(
          (facility) =>
            facility.packagingMethod.type === "POUCH" &&
            facility?.facilityIds &&
            Array.isArray(facility.facilityIds) &&
            facility.facilityIds.length
        );

        let tempSelectOptions = [];

        facilities.some((facility) => {
          facility.facilityIds.forEach((id) => {
            if (!tempSelectOptions.includes(id)) {
              tempSelectOptions.push({
                value: id,
                label: `${facility.name} (${id})`,
              });
            }
          });
        });

        tempSelectOptions.sort((a, b) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        setSelectOptions(tempSelectOptions);
      })
      .catch((error) => {
        setError(error);
      });
  }, [user]);

  return (
    <tbody className="ResizableTable__TableContent">
      {orderData.map((row, rowIndex) => {
        return (
          <tr key={Math.random()}>
            {Object.keys(row).map((columnKey, columnIndex) => {
              return (
                <td
                  key={`${rowIndex.toString}, ${columnIndex.toString()}`}
                  onClick={(e) => {
                    onFieldClick(rowIndex, columnIndex, e);
                  }}
                  // onDoubleClick={(e) => {
                  //   if (columnIndex === 3) {
                  //     onProductSelectionObject(rowIndex, columnIndex);
                  //   }
                  // }}
                  style={
                    columnIndex !== 8 &&
                    columnIndex !== columnWidths.length - 1 &&
                    activeField.row === rowIndex &&
                    activeField.column === columnIndex
                      ? { background: "#F7F7F7", position: "relative" }
                      : null
                  }
                >
                  {columnIndex !== columnWidths.length - 1 &&
                    columnIndex !== 8 && (
                      <div className={styles.TableContent__inputContainer}>
                        <input
                          ref={
                            activeField.row === rowIndex &&
                            activeField.column === columnIndex
                              ? inputRef
                              : null
                          }
                          style={{
                            minWidth:
                              columnIndex === 3
                                ? `${columnWidths[columnIndex] - 40}px`
                                : `${columnWidths[columnIndex] - 8}px`,
                            width:
                              columnIndex === 3
                                ? `${columnWidths[columnIndex] - 40}px`
                                : `${columnWidths[columnIndex] - 8}px`,
                            backgroundColor:
                              errorData?.length &&
                              columnIndex <= headers.length - 2 &&
                              errorData.length === orderData.length &&
                              errorData[rowIndex][headers[columnIndex]]
                                ? "#fff2f0"
                                : "inherit",
                          }}
                          type={inputTypes[columnIndex]}
                          required
                          value={orderData[rowIndex][columnKey]}
                          onChange={(e) => {
                            setCursor(e.target.selectionStart);
                            onFieldValueChange(e, rowIndex, columnKey);
                          }}
                          onKeyDown={onKeyDown}
                          autoFocus={
                            activeField.row === rowIndex &&
                            activeField.column === columnIndex
                              ? true
                              : false
                          }
                        />
                        {columnIndex === 3 && (
                          <div
                            className={styles.TableContent__searchIconContainer}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onProductSelectionObject(rowIndex, columnIndex);
                            }}
                          >
                            <SearchIcon />
                          </div>
                        )}
                      </div>
                    )}
                  {columnIndex === 8 && (
                    <div
                      className="ResizableTable-TableContent__selectContainer"
                      autoFocus={
                        activeField.row === rowIndex &&
                        activeField.column === columnIndex
                          ? true
                          : false
                      }
                    >
                      <AntSelect
                        value={orderData[rowIndex][columnKey]}
                        selectStyle={
                          errorData?.length &&
                          columnIndex <= headers.length - 2 &&
                          errorData.length === orderData.length &&
                          errorData[rowIndex][headers[columnIndex]]
                            ? "ant-selectGroup-000-error"
                            : multiFacilitySelectionViolation
                            ? "ant-selectGroup-000-error"
                            : "ant-selectGroup-000"
                        }
                        minWidth={`${columnWidths[columnIndex] - 10}px`}
                        width={`${columnWidths[columnIndex] - 10}px`}
                        placeholder={"Select"}
                        options={selectOptions}
                        onChange={(value) =>
                          onFieldValueChange(value, rowIndex, columnKey)
                        }
                      />
                    </div>
                  )}
                  <div className={styles.TableContent__actionsIconsContainer}>
                    {columnIndex === columnWidths.length - 1 && (
                      <div
                        className={`${styles.TableContent__actionIconContainer} ${styles["TableContent__actionIconContainer--large"]}`}
                        onClick={() => onCreateAdjacentEmptyRow(rowIndex)}
                      >
                        <Tooltip title="Add empty adjacent row">
                          <CreateEmptyRowIcon />
                        </Tooltip>
                      </div>
                    )}

                    {columnIndex === columnWidths.length - 1 && (
                      <div
                        className={`${styles.TableContent__actionIconContainer} ${styles["TableContent__actionIconContainer--large"]} ${styles["TableContent__actionIconContainer--pad-right"]}`}
                        onClick={() => onCreateAdjacentFilledRow(rowIndex)}
                      >
                        <Tooltip title="Copy this row and paste adjacently">
                          <CreateAdjacentFilledRowIcon />
                        </Tooltip>
                      </div>
                    )}

                    {columnIndex === columnWidths.length - 1 &&
                      orderData.length > 1 && (
                        <div
                          className={`${styles.TableContent__actionIconContainer} ${styles["TableContent__actionIconContainer--small"]}`}
                          onClick={() => onDeleteRow(rowIndex)}
                        >
                          <Tooltip title="Delete this row">
                            <TrashIcon
                              className={styles.TableContent__deleteRowIcon}
                            />
                          </Tooltip>
                        </div>
                      )}
                  </div>
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableContent;

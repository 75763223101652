import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
} from "victory";

import { useTranslation } from "react-i18next";
import styles from "./BarGraph.module.css";

const BarGraph = ({
  user,
  fetchingData = false,
  stylesName,
  graphData = [],
  chartWidth = "450",
  chartHeight = "288",
  graphTitle = "",
  palette = ["#318ce7"],
  onBarClick = () => {},
  showBarValue = false,
}) => {
  // For Testing
  // const graphData = [
  //   { x: "Below min", y: 5 },
  //   { x: "Over max", y: 14 },
  //   { x: "Expired", y: 23 },
  //   { x: "Soon to expire", y: 2 },
  // ];

  const { t } = useTranslation("graphsGeneral");

  let barData = [];

  const ticks = 5;

  // when ticks = 5, returns [0, 1, 2, 3, 4, 5]
  // or, using the npm package lodash, the same as const tickValues = _.range(6);
  const tickValues = Array.from({ length: ticks + 1 }, (_, i) => i);

  function getMaxValueInDataSet(dataSet) {
    let maxValue = -1;
    dataSet.forEach((num) => {
      if (num > maxValue) {
        maxValue = num;
      }
    });
    return Math.ceil(maxValue / ticks) * ticks;
  }

  const maxYValueForBarAxis = getMaxValueInDataSet(
    graphData.map((dataObj) => dataObj.y)
  );

  const yRange = [0, maxYValueForBarAxis];

  const domain = { y: [0, ticks] };

  const tickFormatBar = (range) => (t) =>
    ((t * (range[1] - range[0])) / ticks).toFixed(1);

  const normalize = (range, props) => (datum) =>
    datum[props] / ((range[1] - range[0]) / ticks);

  graphData.forEach((dataObj, index) => {
    barData.push({
      name: dataObj.x,
      amount: dataObj.y,
      label: showBarValue && dataObj.y > 0 ? dataObj.y : null,
    });
  });

  let xValueNoGraphDataFound;
  let yValueNoGraphDataFound;

  if (stylesName === "BarGraph1") {
    xValueNoGraphDataFound = 176;
    yValueNoGraphDataFound = 144;
  } else {
    xValueNoGraphDataFound = 304;
    yValueNoGraphDataFound = 150;
  }

  if (user?.language?.type === "es" && stylesName === "BarGraph1") {
    xValueNoGraphDataFound = 138;
  } else if (user?.language?.type === "fr" && stylesName === "BarGraph1") {
    xValueNoGraphDataFound = 140;
  } else if (user?.language?.type === "es") {
    xValueNoGraphDataFound = 138;
  } else if (user?.language?.type === "fr") {
    xValueNoGraphDataFound = 304;
  }

  return (
    <div className={styles[`${stylesName}__container`]}>
      {graphTitle && <h2>{graphTitle}</h2>}
      <VictoryChart
        style={{
          parent: {
            border: stylesName === "BarGraph1" ? "none" : "1px solid #ccc",
            marginTop: stylesName === "BarGraph1" ? 0 : "10px",
            marginBottom: "12px",
            paddingTop: stylesName === "BarGraph1" ? 0 : "8px",
          },
        }}
        domain={domain}
        domainPadding={{ x: 60, y: 0 }}
        theme={VictoryTheme.material}
        width={chartWidth}
        height={chartHeight}
      >
        {!barData.length && (
          <VictoryLabel
            x={xValueNoGraphDataFound}
            y={yValueNoGraphDataFound}
            style={{
              fontFamily: "inherit",
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: "normal",
              fill: "	#909090",
            }}
            text={fetchingData ? "" : t("noData")}
          />
        )}
        <VictoryAxis
          crossAxis
          tickFormat={(t) => (t.length > 14 ? `${t.substring(0, 14)}...` : t)}
          style={{
            axis: { stroke: "#909090", strokeWidth: 1 },
            ticks: { stroke: "#909090", strokeWidth: 1 },
            tickLabels: {
              fill: barData.length ? "#909090" : "transparent",
              fontSize: 11,
              textLength: "3em",
            },
            grid: {
              stroke: "transparent",
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          tickValues={tickValues}
          tickFormat={tickFormatBar(yRange)}
          style={{
            axis: { stroke: "#909090", strokeWidth: 1 },
            ticks: { stroke: "#909090", strokeWidth: 1 },
            tickLabels: {
              fill: barData.length ? "#909090" : "transparent",
              fontSize: 11,
              width: 40,
            },
            grid: {
              stroke: "#CFD8DC",
              strokeDasharray: "0, 0",
            },
          }}
        />
        <VictoryBar
          data={barData}
          y={normalize(yRange, "amount")}
          barRatio={1}
          style={{
            data: {
              fill: (index) => {
                return palette.length > 1 ? palette[index.index] : palette[0];
              },
            },
            labels: {
              fill: "#000000",
              fontWeight: "500",
              fontSize: "13px",
            },
          }}
          labelComponent={<VictoryLabel dy={-6} />}
          x="name"
          barWidth={barData.length > 1 ? null : 120}
          events={[
            {
              target: "data",
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: "data",
                      mutation: (props) => {
                        onBarClick(props.index);
                      },
                    },
                  ];
                },
              },
            },
          ]}
        />
      </VictoryChart>
    </div>
  );
};

export default BarGraph;
